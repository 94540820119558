// A/B test confidence module
// with multiple states
// --------------------------
$self: ".abtest-confidence";

@mixin style($background, $text) {
  & {
    background-color: $background;
    color: $text;

    .sign-svg {
      &-stroke {
        stroke: $text;
      }

      &-fill {
        fill: $text;
      }
    }
  }
}

#{$self} {
  align-items: center;
  align-self: center;
  background-color: #f8f8f8;
  border-radius: 6px;
  color: #999;
  display: inline-flex;
  font-family: $gotham-bold;
  font-size: 14px;
  padding: 8px 14px 8px 10px;
  vertical-align: middle;

  &.is-warning {
    @include style(#fff6bb, #8a790d);
  }

  &.is-success {
    @include style(#f3f9f3, #429f53);
  }

  &-icon {
    margin-right: 10px;

    .sign-svg {
      &-stroke {
        stroke: #999;
      }

      &-fill {
        fill: #999;
      }
    }
  }

  &-tip {
    cursor: pointer;
    display: inline-flex;
    margin-left: 5px;
    vertical-align: middle;

    &:focus {
      outline: none;
    }
  }
}
