.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-flex-start {
  align-items: flex-start;
}

.align-items-flex-end {
  align-items: flex-end;
}

.align-items-stretch {
  align-items: stretch;
}

.align-content-center {
  align-content: center;
}

.align-content-flex-start {
  align-content: flex-start;
}

.align-content-flex-end {
  align-content: flex-end;
}

.align-content-stretch {
  align-content: stretch;
}

.align-content-space-between {
  align-content: space-between;
}

.align-content-space-around {
  align-content: space-around;
}

.align-self-center {
  align-self: center;
}

.align-self-baseline {
  align-self: baseline;
}

.align-self-flex-start {
  align-self: flex-start;
}

.align-self-flex-end {
  align-self: flex-end;
}

.align-self-stretch {
  align-self: stretch;
}

.justify-center {
  justify-content: center;
}

.justify-flex-start {
  justify-content: flex-start;
}

.justify-flex-end {
  justify-content: flex-end;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-space-around {
  justify-content: space-around;
}

.justify-space-evenly {
  justify-content: space-evenly;
}

.justify-stretch {
  justify-content: stretch;
}

.flex-direction-row {
  flex-direction: row;
}

.flex-direction-row-reverse {
  flex-direction: row-reverse;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-direction-column-reverse {
  flex-direction: column-reverse;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-0 {
  flex-grow: 0;
}
