@import "stylesheets/application_v2/config";

$self: ".Tile-form";

#{$self} {
  &-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-left-part {
      width: 58%;
    }

    &-right-part {
      display: flex;
      width: 40%;
    }
  }

  &-group {
    &-content {
      margin-top: 15px;
      padding-left: 30px;

      &-item {
        display: flex;
        align-items: center;
        margin-top: 10px;

        &:first-child {
          margin-top: 0;
        }

        &.is-small {
          margin-top: 10px;
        }

        &-title {
          font-size: 16px;
          font-family: $gotham-bold;
          line-height: 1;
          margin-bottom: 15px;
        }

        &-content {
          padding-left: 20px;
          margin-bottom: 30px;

          &.is-pln {
            padding-left: 0;
          }

          &.is-last {
            margin-bottom: 0;
          }
        }
      }
    }

    &-select {
      display: inline-block;
      vertical-align: middle;
      margin-left: 5px;
      margin-right: 5px;
      width: 130px;
    }

    &-label {
      display: inline-flex;
      align-items: center;
      font-family: $gotham-book;
      font-size: 14px;
      color: #333;
    }
  }
}
