@import "stylesheets/application_v2/config";

.Campaign-new {
  .divider {
    position: absolute;
  }

  p {
    font-family: $gotham-book;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.6;
    color: #333;

    &.is-medium {
      font-family: $gotham-medium;
    }
  }

  &-header {
    text-align: center;
    margin-bottom: 50px;

    &-title {
      font-family: $gotham-bold;
      font-size: 46px;
      font-weight: normal;
      color: #333;
      margin-bottom: 20px;
      text-transform: uppercase;
      padding-top: 30px;
    }

    &-sub-title {
      font-family: $gotham-book;
      font-weight: normal;
      font-size: 24px;
      line-height: 1.2;
      color: #333;
      margin-bottom: 40px;
    }
  }

  &-features {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  &-features-holder {
    background: #efefef;
    border-top: 1px solid #e3e3e3;
    padding-top: 40px;
    padding-bottom: 115px;
    margin-bottom: -80px;
  }

  &-more-info {
    .icon-testing {
      margin-top: 10px;
    }
  }

  &-more-info-img {
    margin-bottom: 20px;
  }

  &-more-info-title {
    font-size: 22px;
    line-height: 1;
    font-family: $gotham-bold;
    font-weight: normal;
    color: #333;
    margin-bottom: 10px;
  }

  &-features-item {
    width: 285px;

    .description {
      margin: 0;

      &-img,
      &-text {
        line-height: 26px;
      }
    }
  }

  &-event {
    &-form {
      margin: 0;
    }

    &-link {
      text-decoration: none;
      display: block;

      &:hover {
        text-decoration: none;
      }
    }

    &-head {
      color: #333;
      font-family: $gotham-bold;
      margin-bottom: 15px;
    }

    &-select-wrap {
      position: relative;
    }

    &-select-error {
      margin-top: 5px;
      color: #ff4e4e;
      font-size: 12px;
    }
  }
}

@media all and (max-width: 1215px) {
  .Campaign-new-features-item {
    flex-basis: 50%;
  }
}
