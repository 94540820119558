$one-third: 33.33%;
$two-third: 66.66%;
$one-sixth: 16.66%;
$five-sixth: 83.33%;

.row {
  @include clearfix;
}

.col1of2,
.col1of3,
.col2of3,
.col1of4,
.col2of4,
.col3of4,
.col1of5,
.col2of5,
.col3of5,
.col4of5,
.col1of6,
.col2of6,
.col3of6,
.col4of6,
.col5of6 {
  float: left;
  box-sizing: border-box;
}

.col1of1 {
  width: 100%;
}

.col1of2,
.col3of6 {
  width: 50%;
}

.col1of3,
.col2of6 {
  width: $one-third;
}

.col2of3,
.col4of6 {
  width: $two-third;
}

.col1of4 {
  width: 25%;
}

.col3of4 {
  width: 75%;
}

.col1of5 {
  width: 20%;
}

.col2of5 {
  width: 40%;
}

.col3of5 {
  width: 60%;
}

.col4of5 {
  width: 80%;
}

.col1of6 {
  width: $one-sixth;
}

.col5of6 {
  width: $five-sixth;
}

.right {
  float: right;
}

.left {
  float: left;
}

.center {
  clear: both;
  float: none !important;
  margin-left: auto;
  margin-right: auto;
}

.by-left {
  text-align: left;
}

.by-right {
  text-align: right;
}

.by-center {
  text-align: center;
}

.inline {
  display: inline-block;
  vertical-align: middle;
}

.hidden {
  display: none;
}

.shown {
  display: block;
}
