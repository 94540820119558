@import "stylesheets/application_v2/config";

.Gdpr-policy {
  &-button {
    margin-top: 16px;
    width: 142px;
    height: 32px;
    font-size: 16px;
    font-family: $aktiv-grotesk, $gotham-bold;
    color: #323232;
    border-radius: 4px;
    border: none;
    background-image: linear-gradient(to bottom, #fdfdfd, #f2f1f1);

    &:hover {
      background: linear-gradient(rgb(255 255 255), rgb(249 249 249));
    }

    &.Gdpr-policy-button-mobile {
      margin-top: 4px;
      width: 100px;
      height: 24px;
      font-size: 12px;
      border: solid 1px #ccc;
      font-family: $aktiv-grotesk, $gotham-medium;
    }
  }

  &-desktop {
    display: none;
    position: fixed;
    bottom: 0;
    padding: 16px 0;
    text-align: center;
    width: 100%;
    background-color: rgb(4 26 56 / 85%);
    z-index: $highest-z-index - 2;

    &-container {
      margin: auto;
      max-width: 1140px;

      @include mq-max-width(1220px) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    p.Gdpr-policy-desktop-content {
      font-size: 14px;
      line-height: 22px;
      font-family: $aktiv-grotesk, $gotham-light;
      color: #fff;
    }

    p.Gdpr-policy-desktop-content-main {
      font-family: $aktiv-grotesk, $gotham-medium;
    }

    &-content br {
      display: block;

      @include mq-max-width(1100px) {
        display: none;
      }
    }

    &-link {
      font-size: 14px;
      line-height: 22px;
      font-family: $aktiv-grotesk, $gotham-medium;
      color: #989fad;
      text-decoration: none;
      transition: all 0.25s, font-size 0.1s;

      &:hover {
        color: #cc3f00;
      }
    }
  }

  &-mobile {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: $highest-z-index - 2;
    background-color: rgb(0 0 0 / 25%);
    text-align: center;
    margin: 5px;

    &-container {
      padding: 12px;
      background-color: #fff;
      z-index: $highest-z-index - 1;
      box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
    }

    p.Gdpr-policy-mobile-content {
      font-size: 12px;
      line-height: 18px;
      font-family: $aktiv-grotesk, $gotham-light;
      color: #000;
    }

    &-link {
      font-size: 12px;
      line-height: 18px;
      font-family: $aktiv-grotesk, $gotham-medium;
      color: #0070d9;
      text-decoration: none;
      transition: all 0.25s, font-size 0.1s;

      &:hover {
        color: #cc3f00;
      }
    }
  }

  &.show {
    .Gdpr-policy-desktop {
      display: block;

      @include mq-max-width(544px) {
        display: none;
      }
    }

    .Gdpr-policy-mobile {
      @include mq-max-width(544px) {
        display: block;
      }
    }
  }
}

@media print {
  .Gdpr-policy {
    display: none !important;
  }
}
