.popup {
  position: fixed;
  top: 10%;
  left: 50%;
  z-index: 1050;
  overflow: auto;
  width: 960px;
  margin: 0 0 0 -480px;
  background-color: #fff;
  border: 1px solid #999;
  border-radius: 6px;

  &.fade {
    transition: opacity 0.3s linear, top 0.3s ease-out;
    backface-visibility: hidden;
    top: -25%;
  }

  &.fade.in {
    top: 10%;
  }
}

.popup-close {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 28px;
  height: 28px;
  line-height: 27px;
  border-radius: 50%;
  border: 2px solid #e5e5e5;
  text-align: center;
  color: #999;
  cursor: pointer;
  font-size: 27px;
  font-family: $gotham-medium;
  font-weight: normal;
  z-index: 1060;
  box-sizing: border-box;
  transition: all 0.25s;
  backface-visibility: hidden;

  .icon-svg-cross {
    transition: all 0.25s;
    backface-visibility: hidden;
  }

  &:hover {
    border-color: #ccc;
    color: #666;
    text-decoration: none;

    .icon-svg-cross {
      fill: #666;
    }
  }

  &:active {
    border-color: #e5e5e5;
    background: #e5e5e5;
    color: #898989;
    text-decoration: none;

    .icon-svg-cross {
      color: #898989;
    }
  }
}

:fullscreen,
:root .popup-close {
  padding-left: 1px;
}
