// Mixins
@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }

  @keyframes #{$name} {
    @content;
  }
}

@mixin placeholder-style {
  &::placeholder {
    @content;
  }

  &.placeholder {
    @content;
  }
}

@mixin retina {
  @media all and (resolution >= 2dppx), all and (resolution >= 144dpi) {
    @content;
  }
}

@mixin mq-min-width($min) {
  @media all and (min-width: $min) {
    @content;
  }
}

@mixin mq-max-width($max) {
  @media all and (max-width: $max) {
    @content;
  }
}

@mixin mq-max-width-height($max-w, $max-h) {
  @media all and (max-width: $max-w) and (max-height: $max-h) {
    @content;
  }
}

@mixin mq-min-height($min) {
  @media all and (min-height: $min) {
    @content;
  }
}

@mixin mq-max-height($max) {
  @media all and (max-height: $max) {
    @content;
  }
}

@mixin print {
  @media print {
    @content;
  }
}

@mixin vertical-gradient($from, $to) {
  background: $from;
  background: linear-gradient(to bottom, $from, $to);
}

@mixin horizontal-gradient($from, $to) {
  background: $from;
  background: linear-gradient(to left, $from, $to);
}

@mixin clearfix {
  & {
    *zoom: 1;
  }

  &::before,
  &::after {
    display: table;
    content: "";
  }

  &::after {
    clear: both;
  }
}

@mixin font-face($name, $src) {
  @font-face {
    font-family: $name;
    font-display: auto;
    src:
      local($name),
      url("#{$src}.woff2") format("woff2"),
      url("#{$src}.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }
}

// Some helpers

@mixin size($w, $h: $w) {
  width: $w;
  height: $h;
}

@mixin ctr($pos: xy, $transforms...) {
  position: absolute;

  @if $pos == xy {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) $transforms;
  } @else if $pos == y {
    top: 50%;
    transform: translateY(-50%) $transforms;
  } @else if $pos == x {
    left: 50%;
    transform: translateX(-50%) $transforms;
  } @else if $pos == none {
    position: static;
    transform: none;
    left: auto;
    top: auto;
  }
}

@mixin p-ctr($args...) {
  content: "";
  display: block;

  @include ctr($args...);
}

// XY axis helpers
@mixin xy($prop1, $prop2, $args) {
  $idx: if(length($args) > 1, 2, 1);

  #{$prop1}: nth($args, 1);
  #{$prop2}: nth($args, $idx);
}

@mixin margin-x($args...) {
  @include xy(margin-right, margin-left, $args);
}

@mixin margin-y($args...) {
  @include xy(margin-top, margin-bottom, $args);
}

@mixin padding-x($args...) {
  @include xy(padding-right, padding-left, $args);
}

@mixin padding-y($args...) {
  @include xy(padding-top, padding-bottom, $args);
}

@mixin add-radius($dir, $r: 4px) {
  @if $dir == top {
    border-top-left-radius: $r;
    border-top-right-radius: $r;
  } @else if $dir == bottom {
    border-bottom-left-radius: $r;
    border-bottom-right-radius: $r;
  } @else if $dir == left {
    border-top-left-radius: $r;
    border-bottom-left-radius: $r;
  } @else if $dir == right {
    border-top-right-radius: $r;
    border-bottom-right-radius: $r;
  } @else {
    border-radius: $r;
  }
}

@mixin mask($gap: 0) {
  left: $gap;
  right: $gap;
  top: $gap;
  bottom: $gap;
}

/* Gap helpers */

@mixin vGap($gap, $reversed: false) {
  $pos: if($reversed, "first", "last");

  & > * {
    margin-bottom: $gap;

    &:#{$pos}-child {
      margin-bottom: 0;
    }
  }

  #{selector-append(&, table)} {
    tr td {
      padding-bottom: $gap;
    }

    tbody tr:#{$pos}-of-type td {
      padding-bottom: 0;
    }
  }
}

@mixin hGap($gap, $align: baseline) {
  margin-left: -$gap;

  & > * {
    display: inline-block;
    margin-left: $gap;
    vertical-align: $align;
  }
}

@function opposite-dir($dir) {
  @if $dir == "top" {
    @return "bottom";
  } @else if $dir == "right" {
    @return "left";
  }

  @if $dir == "bottom" {
    @return "top";
  } @else if $dir == "left" {
    @return "right";
  }
}

@mixin add-logical-state-styles($config) {
  background-color: nth($config, 1);
  color: nth($config, 2);
}

@mixin add-all-available-logical-state-styles($additional-states: ()) {
  $all-states: map-merge($base-logical-states, $additional-states);

  @each $state-name, $config in $all-states {
    &.is-#{$state-name} {
      @include add-logical-state-styles($config);
    }
  }
}

@mixin pseudo {
  content: "";
  display: block;
  position: absolute;
}

@mixin negative-pseudo {
  z-index: 1;

  &::before,
  &::after {
    z-index: -1;
  }
}

// helpers

.height-full {
  height: 100%;
}
