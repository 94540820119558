@import "bootstrap.popover";
@import "stylesheets/screen";
@import "stylesheets/global";
@import "stylesheets/modules/tile";
@import "stylesheets/modules/ivars";
@import "jquery.tooltip";
@import "chosen";
@import "stylesheets/plugins/jquery.chosen.overridden";
@import "tribute";
@import "diff";
@import "stylesheets/application_v2/app";
@import "stylesheets/internal/grids";

tr.admin-row {
  background-color: #fde7e7;
}

.diff li.ins a.diff-asset-link {
  color: #080;
}

.diff li.del a.diff-asset-link {
  color: #b00;
}

.diff li.rename a.diff-asset-link.ins {
  background: #dfd;
  color: #080;
}

.diff li.rename a.diff-asset-link.del {
  background: #fee;
  color: #b00;
}

.range-picker {
  .ac-daterangepicker {
    div:first-child {
      font-size: 10px;
    }
  }
}
