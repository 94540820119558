@import "stylesheets/application_v2/config";
@import "stylesheets/application_v2/layouts/tile_form";

$self: ".Site-settings";

.App-holder {
  .SitesSpa & {
    &::after {
      display: none;
    }

    & {
      margin-bottom: 0;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr auto;
      grid-template-areas:
        "header"
        "main"
        "footer";
    }

    .header {
      grid-area: header;
    }

    .App-container-main {
      grid-area: main;
    }

    .footer {
      grid-area: footer;
    }
  }

  .Dashboard-representatives {
    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-top: 1px solid #c4c4c4;
      padding: 32px 0;
      font-family: "gotham-htf-pro", arial, sans-serif;
    }

    &-title {
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 22px;
    }

    &-users {
      &-wrapper {
        display: flex;
      }
    }

    &-user {
      &-wrapper {
        background: #fff;
        box-shadow: 0 0 4px rgb(0 0 0 / 10%);
        border-radius: 23px;
        padding: 3px 14px 3px 3px;
        display: flex;
        align-items: center;

        &:first-child {
          margin-right: 10px;
        }

        &:hover {
          text-decoration: none;
          box-shadow: 0 0 2px rgb(0 0 0 / 30%);
        }
      }

      &-title {
        font-weight: 700;
        font-size: 14px;
        color: #2c71d2;
      }

      &-icon {
        &-wrapper {
          height: 26px;
          width: 26px;
          margin-right: 8px;
        }

        &-placeholder {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          border: 2px solid #2c71d2;
          box-sizing: border-box;
          background-image: url("~application_v2/representative-placeholder.png");
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }

        &-avatar {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }
      }
    }
  }
}

#{$self} {
  h4 {
    line-height: 24px;
  }

  &-tiles {
    &-header {
      p {
        font-size: 14px;
        line-height: 1.4;
        font-family: $gotham-book;
        margin-bottom: 10px;
      }
    }

    &-title {
      h2 {
        font-family: $gotham-bold;
        font-size: 28px;
        line-height: 1;
        margin-bottom: 20px;
        color: #333;

        span {
          font-family: $gotham-book;
        }
      }

      &.is-two-sections {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &-right {
        display: flex;
        align-items: center;
      }
    }

    &-form {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    &-main-part {
      background: #fff;
    }

    &-table {
      &-zerocase {
        font-size: 14px;
        font-family: $gotham-medium;
      }
    }
  }
}
