// Site Fraud Settings
$self: ".Fraud";

#{$self} {
  &-warning {
    color: $warning;
  }

  &-link {
    font-family: $gotham-bold;
    color: $link-color;

    &:hover,
    &:focus {
      color: $firm;
      text-decoration: none;
    }
  }

  // Layout
  &-layout {
    $sidebar: 240px;
    $extra: 27%;

    display: flex;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(#000, 0.1);

    @include clearfix;

    @include mq-max-width(1320px) {
      left: auto;
      padding-left: 0 !important;
      padding-right: 0 !important;
      width: auto !important;
    }

    &-header {
      margin-bottom: 20px;
    }

    p.is-large {
      font-size: 16px;
      line-height: 24px;
    }

    &-container {
      float: left;
      width: 100%;

      &::before {
        box-shadow: inset 1px 0 0 rgba(#000, 0.1);
        bottom: 0;
        content: "";
        left: $sidebar;
        position: absolute;
        top: 0;
        width: 1px;
        z-index: 0;
      }

      &::after {
        background-color: #fbf6e4;
        box-shadow: inset 0 0 0 1px #efe7c6;
        bottom: 0;
        content: "";
        position: absolute;
        right: 0;
        top: -1px;
        width: $extra;
        z-index: 0;
      }
    }

    &-content {
      margin: 0 $extra 0 $sidebar;
      padding: 21px 30px 30px;
      box-sizing: border-box;
    }

    &-sidebar {
      float: left;
      margin-left: -100%;
      padding: 27px 20px 20px;
      width: $sidebar;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;

      &-save-btn {
        align-self: flex-end;
        position: sticky;
        bottom: 0;
        background: white;
        box-shadow: 0 -20px 30px 0 white;
        width: 100%;
        padding: 27px 20px;
        z-index: 1;

        &-wrap {
          flex-grow: 1;
          display: flex;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          box-sizing: border-box;
          width: 240px;
        }

        button {
          &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 20px;
            right: 20px;
            height: 1px;
            background: rgb(0 0 0 / 10%);
          }
        }
      }
    }

    &-extra {
      position: absolute;
      right: 0;
      margin-left: -$extra;
      padding: 30px;
      width: $extra;
      z-index: 2;
      box-sizing: border-box;
    }
  }

  // Sidebar navigation
  &-nav {
    z-index: 1;

    h1 {
      color: $base;
      font-family: $gotham-bold;
      font-size: 16px;
      letter-spacing: 0.03em;
      line-height: 1.231;
      text-transform: uppercase;
    }

    ul {
      list-style-type: none;
      margin: 0 0 -2px;
    }

    li {
      padding: 2px 0;

      &.Fraud-nav-delimiter {
        padding: 0;
      }
    }

    &-delimiter {
      background-color: rgba(#000, 0.1);
      height: 1px;
      margin: 20px 0;

      &.is-long {
        margin-left: -20px;
        margin-right: -20px;
      }

      &.is-small {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }

    &-tip {
      color: rgba($base, 0.5);
      font-size: 12px;
      line-height: 1.3;

      p {
        margin-bottom: 10px;
      }

      a {
        color: rgba($base, 0.75);
        font-family: $gotham-medium;
        font-size: 11px;
        text-transform: uppercase;

        &:hover,
        &:focus {
          color: $firm;
          text-decoration: none;
        }
      }
    }

    &-profiles {
      @mixin custom-color($outer, $inner) {
        &.is-active {
          i {
            background-color: $inner;
            border-color: $outer;
          }
        }

        &::after {
          border-color: rgba($inner, 0);
          border-left-color: $inner;
        }

        &::before {
          border-color: rgba($outer, 0);
          border-left-color: $outer;
        }

        i {
          border-left-color: $outer;

          &::before,
          &::after {
            background-color: $outer;
          }
        }
      }

      li {
        padding: 5px 0;
        position: relative;

        // Triangle
        &::after,
        &::before {
          border: solid transparent;
          height: 0;
          left: 100%;
          margin-left: 13px;
          pointer-events: none;
          position: absolute;
          top: 50%;
          width: 0;
          transform: scale(0.5, 1);
        }

        &::after {
          border-width: 14px;
          margin-top: -14px;
        }

        &::before {
          border-width: 15px;
          margin-top: -15px;
        }

        // Background decor
        i {
          border: 1px solid transparent;
          border-right: none;
          border-left: 4px solid;
          bottom: 0;
          left: -20px;
          position: absolute;
          right: -20px;
          top: 0;

          &::before,
          &::after {
            content: "";
            height: 1px;
            left: -4px;
            position: absolute;
            width: 4px;
          }

          &::before {
            top: -1px;
          }

          &::after {
            bottom: -1px;
          }
        }

        &.is-active {
          &::after,
          &::before {
            content: "";
          }
        }

        // Colors
        &.is-high {
          z-index: 5;

          @include custom-color(#ce0000, #ffe2e2);
        }

        &.is-elevated {
          z-index: 4;

          @include custom-color(#f2d800, #fffee2);
        }

        &.is-moderate {
          z-index: 3;

          @include custom-color(#2097f2, #e2f4ff);
        }

        &.is-low {
          z-index: 2;

          @include custom-color(#03d70c, #e2ffe3);
        }

        &.is-custom {
          @include custom-color(#cfcfcf, #f8f8f8);
        }
      }

      &-item {
        position: relative;
        z-index: 2;
      }
    }
  }

  // Profile configuration
  &-config {
    &-sub {
      &-headline {
        font-family: $gotham-bold;
        color: #666;
        font-size: 16px;
        line-height: 1;
        margin-bottom: 10px;
      }
    }

    &-title {
      font-family: $gotham-bold;
      margin-bottom: 20px;
      font-size: 26px;
      line-height: 1;

      &.is-advocate {
        color: #ff8043;
      }

      &.is-friend {
        color: #a26aec;
      }
    }

    &-headline {
      color: $base;
      font-family: $gotham-bold;
      font-size: 32px;
      margin-bottom: 15px;
    }

    &-description {
      font-size: 16px;
      line-height: 1.5;
      margin-bottom: 25px;

      p {
        font-size: 14px;
        line-height: 20px;
        font-family: $gotham-book;
        color: #666;
      }

      small {
        color: rgba($base, 0.5);
        font-size: 14px;
      }
    }

    &-delimiter {
      background-color: rgba(#000, 0.1);
      clear: both;
      height: 1px;
      margin: 20px 0 30px;
    }

    &-option {
      font-size: 14px;
      padding: 5px 0;
      position: relative;
      display: flex;
      justify-content: space-between;

      &[data-trigger="self_referral_by_ip"],
      &[data-trigger="block_fr_by_ip"] {
        padding-left: 37px;

        .Fraud-config-option-key {
          &::before,
          &::after {
            content: "";
            position: absolute;
            background: #e5e5e5;
          }

          &::before {
            width: 27px;
            height: 1px;
            left: -35px;
            top: 50%;
            margin-top: -0.5px;
          }

          &::after {
            width: 1px;
            height: 30px;
            left: -36px;
            top: -11px;
          }
        }
      }

      &.is-active {
        .Fraud-config-option-value {
          z-index: 5;
        }

        &::before {
          background-color: #fbf6e4;
          border-color: #efe7c6;
        }
      }

      &.is-multi-line {
        flex-wrap: wrap;
      }

      &::before {
        bottom: 0;
        border: 1px solid transparent;
        border-right: none;
        content: "";
        display: block;
        left: -15px;
        position: absolute;
        right: -31px;
        top: 0;
        z-index: 1;
      }

      &-group {
        margin-top: 40px;
      }

      &-key {
        margin-right: 2%;
        min-height: 29px;
        padding-top: 8px;
        position: relative;
        z-index: 1;
      }

      &-value {
        position: relative;
        min-width: 165px;
        order: 2;
      }

      &-subset {
        margin-left: 40px;
        position: relative;
        width: 70%;
        flex-basis: 100%;
        z-index: 2;

        &.is-mln {
          a {
            margin-left: -40px;
          }
        }

        &.is-connected {
          #{$self} {
            &-config-option-subset {
              &-value {
                margin-bottom: 0;

                &::before {
                  bottom: 0;
                }
              }
            }
          }
        }

        &.is-orphan {
          #{$self} {
            &-config-option-subset {
              &-value {
                padding: 10px 0;
              }
            }
          }
        }

        &-key {
          padding: 7px 0;
          position: relative;

          &::before {
            background-color: rgba(#000, 0.1);
            bottom: 0;
            content: "";
            left: -40px;
            position: absolute;
            top: 0;
            width: 1px;
          }
        }

        &-value {
          margin-bottom: 13px;
          position: relative;

          &::before {
            background-color: rgba(#000, 0.1);
            bottom: 50%;
            content: "";
            left: -40px;
            position: absolute;
            top: 0;
            width: 1px;
          }

          &::after {
            background-color: rgba(#000, 0.1);
            bottom: 50%;
            content: "";
            height: 1px;
            left: -39px;
            margin-top: 1px;
            position: absolute;
            width: 26px;
          }

          input {
            margin-bottom: 0;
          }
        }

        small {
          display: block;
          height: 0;
          margin-bottom: 25px;
          margin-top: -15px;
        }

        a {
          font-family: $gotham-bold;
          color: $link-color;

          &:hover,
          &:focus {
            color: $firm;
            text-decoration: none;
          }
        }
      }
    }
  }

  // Platform rules
  &-rules {
    font-size: 14px;

    h1 {
      color: $base;
      font-family: $gotham-bold;
      font-size: 32px;
      line-height: 1;
    }

    dl {
      margin: 20px 0 0;
    }

    dt {
      font-family: $gotham-medium;
      font-size: 16px;
      margin-top: 45px;
      margin-bottom: 8px;

      &:first-child {
        margin-top: 0;
      }
    }

    dd {
      line-height: 1.5;
      margin: 0 0 13px 20px;

      &.is-darkened {
        color: #757b7e;
      }
    }

    em {
      color: $firm;
      font-style: normal;
    }
  }

  // Fraud setting description area
  &-extra {
    font-size: 14px;
    line-height: 1.5;

    .sub-title {
      display: block;
      font-size: 16px;
    }

    p {
      margin-bottom: 20px;

      &.is-tip {
        position: relative;
        top: -7px;
      }
    }

    a {
      font-family: $gotham-medium;

      &:hover,
      &:focus {
        color: $firm;
        text-decoration: none;
      }
    }

    &-image {
      display: block;
      margin-bottom: 20px;

      &.is-blocked-by-ip {
        height: 204px;
        width: 239px;
      }
    }

    &-delimiter {
      background-color: rgba(#000, 0.1);
      height: 1px;
      margin: 20px -10px;

      &.is-long {
        margin: 30px -30px;
      }
    }
  }
}
