.modal {
  .close {
    opacity: 1;
  }
}

.label {
  &.label-striped {
    font-family: $gotham-bold;
    font-size: 12px;
    color: #333;
    padding: 4px 7px;
    background: repeating-linear-gradient(
      -55deg,
      #ffde00,
      rgb(206 183 34 / 46%) 5px,
      #ffde00 5px,
      #ffef80 11px
    );

    &.is-medium {
      font-family: $gotham-medium;
      font-size: 16px;
      line-height: 1;
    }
  }
}

.badge,
.label {
  &.is-middle {
    vertical-align: middle;
  }
}

.badge {
  &-link {
    a {
      text-shadow: none;
      color: #fff;
    }
  }
}

.btn {
  font-family: $gotham-medium;

  &.active {
    background-color: #f9f0cd;
    color: #52440c;
  }

  &.is-align-top {
    vertical-align: top;
  }
}

.btn-group {
  .btn {
    font-size: 14px;

    @include mq-max-width(1200px) {
      font-size: 12px;
    }

    &-small {
      font-size: 12px;
    }

    &-mini {
      font-size: 10px;
    }
  }
}

.dropdown-wrapper {
  position: relative;
}

.accordion {
  &.is-wrapped {
    width: 200px;

    .accordion-heading {
      .accordion-toggle {
        text-align: center;
        padding: 4px 10px;
      }
    }
  }
}

.dropdown {
  &.dropup {
    .dropdown-submenu {
      .dropdown-menu {
        top: auto;
        bottom: 0;

        &.is-bottom {
          top: 0;
          bottom: auto;
        }
      }
    }
  }

  &-toggle {
    &:hover {
      .caret,
      .App-caret-arrow {
        opacity: 1;
      }
    }

    .caret,
    .App-caret-arrow {
      opacity: 0.66;

      &.is-inverse {
        border-top-color: #fff;
        border-width: 6px 5px 0;
      }
    }
  }

  .base-btn {
    color: #333;

    &.is-success {
      color: #fff;
    }
  }

  &-menu {
    box-shadow: 0 5px 25px rgb(0 0 0 / 30%);
    border: 0;
    padding: 0;
    border-radius: 4px;
    font-size: 14px;
    overflow: hidden;

    &.is-left {
      right: 0;
      left: auto;
    }

    &-holder {
      position: relative;
    }

    & > li {
      form {
        margin-bottom: 0;
      }

      .dropdown-link {
        width: 100%;
        display: block;
        text-align: left;
        clear: both;
        font-weight: normal;
        line-height: 20px;
        white-space: nowrap;
        background: none;
        border: none;
        outline: none;
      }
    }

    &.is-ovv {
      overflow: visible;
    }

    &::before {
      border-bottom-color: rgb(0 0 0 / 10%);
    }

    &-lock {
      display: none;
      position: absolute;
      right: 0;
      left: 0;
      bottom: 0;
      top: 0;
      cursor: not-allowed;
      background: #fff;
      opacity: 0;
    }

    li,
    .dropdown-item {
      position: relative;
      border-bottom: 0;

      &.is-disabled {
        .dropdown-menu-lock {
          display: block;
        }

        a,
        .dropdown-link {
          color: lighten(#333, 50%);
        }
      }

      a,
      .dropdown-link {
        box-sizing: border-box;
        padding: 10px 18px;
        font-family: $gotham-medium;
        font-size: 14px;
        line-height: 1.25;
        word-wrap: break-word;
        cursor: pointer;
        color: #333;

        &.remove {
          &:hover {
            background: none;
            color: inherit;
          }
        }

        .dropdown-warn-tip {
          display: none;
        }

        &.is-disabled {
          cursor: not-allowed;
          color: #bbb;

          &:hover {
            background: transparent;
            color: #bbb;
          }

          span {
            cursor: not-allowed;
          }

          .dropdown-warn-tip {
            display: inline-block;
          }
        }

        &:hover {
          // need to override bootstrap `background-image` without using `background` property
          background-image: linear-gradient(to bottom, #fbf6e4, #fbf6e4);
          background-color: #fbf6e4;
          color: #333;
        }
      }

      &.dropdown-header {
        background-color: #fbfbfb;
        color: #999;
        cursor: default;
        font-family: $gotham-bold;
        font-size: 10px;
        letter-spacing: 0.02em;
        padding: 7px 18px;
        text-transform: uppercase;
      }

      &.divider {
        margin: 0;
      }

      &.disabled {
        & > a,
        & .dropdown-link {
          color: #999;
          text-decoration: none;
          background-color: transparent;
          background-image: none;
          cursor: default;
          pointer-events: none;
        }
      }

      &.is-pointable {
        & > a,
        & .dropdown-link {
          pointer-events: all;
        }
      }

      &.active {
        a,
        .dropdown-link {
          background-color: #fbf6e4;
          color: #333;
        }
      }

      &:last-child {
        border: 0;
      }
    }

    & > li,
    & > .dropdown-item {
      & > a:focus {
        background: #fff;
        color: #333;

        &:hover {
          background-color: #fbf6e4;
          color: #333;
        }
      }
    }
  }

  /* ul.dropdown-menu
   *   li.dropdown-submenu
   *     ul.dropdown-menu
   */

  &-submenu {
    position: relative;

    .dropdown-menu {
      display: none;
      top: 0;
      left: auto;
      right: 100%;
      position: absolute;
      margin-top: 0;
      border-radius: 6px 6px 0;
    }

    /* Caret to the 'right' direction
     */

    > a::after {
      display: block;
      content: " ";
      float: left;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      border-width: 5px 5px 5px 0;
      border-right-color: #ccc;
      margin-top: 5px;
      margin-left: -10px;
    }

    &:not(.disabled) {
      &:hover {
        background-color: #fbf6e4;
        color: #333;

        .dropdown-menu {
          display: block;
          overflow: hidden;
        }
      }
    }
  }
}

/* Alert (fly-out)
 */

.alert {
  &.is-popup {
    align-items: center;
    display: none;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(#000, 0.7);
    border: none;
    padding: 0;
    margin: 0;
    border-radius: 0;
    text-shadow: none;
    z-index: 99998;

    &.is-shown {
      display: flex;
    }

    .alert-title,
    .alert-footer {
      display: block;
    }

    .alert-title {
      font-size: 24px;
      line-height: 1;
      font-family: $gotham-bold;
      color: #ce0000;
      padding: 20px;
      text-transform: uppercase;
    }

    .alert-list {
      margin-left: 15px;
      list-style-type: none;

      li {
        color: #333;
        position: relative;

        &::before {
          content: "–";
          position: absolute;
          margin-left: -15px;
        }
      }
    }

    .alert-content {
      font-size: 16px;
      padding: 0 20px 20px;
      max-height: 340px;
      background: #fff;
      overflow: auto;

      strong {
        display: block;
        font-family: $gotham-bold;
        color: #000;
      }

      p {
        display: block;
        font-family: $gotham-book;
        color: #333;
      }
    }

    .alert-footer {
      border-radius: 0 0 10px 10px;
      padding: 20px;
      background: #ffd8d4;
      display: flex;

      &.is-rtl {
        justify-content: flex-start;
        flex-direction: row-reverse;

        .base-btn {
          margin-left: 5px;
        }
      }

      &.is-three-sectional {
        .first {
          margin-right: auto;
        }
      }

      .base-btn {
        min-width: 80px;

        + .base-btn {
          margin-left: 5px;
        }
      }
    }

    .alert-wrapper {
      box-sizing: border-box;
      position: relative;
      background: #fff;
      max-width: 700px;
      border: 5px solid #ff4e4e;
      border-radius: 15px;
      width: 100%;

      a:not(.base-btn) {
        cursor: pointer;
        color: #0070d9;

        &:hover {
          text-decoration: none;
          color: #ff4e00;
        }
      }

      &-buttons {
        display: flex;
        width: 100%;
      }
    }

    .alert-close {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 26px;
      height: 26px;
      line-height: 27px;
      border-radius: 50%;
      border: 1px solid #e5e5e5;
      text-align: center;
      font-size: 25px;
      font-family: $gotham-medium;
      color: #999;
      cursor: pointer;
      transition: all 0.25s;
      backface-visibility: hidden;

      &::before {
        content: "×";
      }

      &:hover {
        border-color: #ccc;
        color: #666;
        text-decoration: none;
      }
    }
  }

  &.is-flash {
    border-radius: 0;
    bottom: 0;
    display: none;
    left: 0;
    margin: 0;
    position: fixed;
    width: 100%;
    z-index: 99999;

    &.is-shown {
      display: block;
    }

    ol {
      margin: 0 auto 10px;
      padding-left: 35px;
      width: 1060px;
    }
  }

  &.is-flash &-title {
    color: inherit;
    margin: 0 auto 10px;
    line-height: 1.231;
    padding-top: 10px;
    width: 1280px;
    font-family: $gotham-bold;
    font-size: 24px;
  }

  &.is-flash &-close {
    cursor: pointer;
    font-size: 36px;
    height: 36px;
    line-height: 1;
    margin-top: -16px;
    position: absolute;
    right: 70px;
    text-align: center;
    top: 50%;
    width: 36px;

    &::before {
      content: "×";
    }
  }

  &.is-success {
    .alert-title {
      color: #000;
    }

    .alert-content {
      p {
        font-size: 16px;
        color: #333;
      }
    }

    .alert-wrapper {
      border: none;
    }

    .alert-footer {
      background: #f3faf3;
    }
  }

  &.is-confirmation {
    .alert-title {
      color: #000;
    }

    .alert-wrapper {
      border-color: #ffe994;
    }

    .alert-footer {
      background: #fffad2;
    }

    .alert-content {
      p {
        white-space: pre-line;
        font-size: 16px;
      }
    }
  }
}

.popover-content {
  a {
    color: #0070d9;

    &:hover {
      text-decoration: none;
      color: #ff4f00;
    }
  }
}

.nav-tabs {
  border-bottom: none;

  > li.is-small {
    a {
      font-size: 14px;
    }
  }

  &.is-dark {
    > .active {
      a,
      a:hover,
      a:focus {
        background-color: transparent;
      }
    }
  }

  &.is-sticky {
    position: fixed;
    top: 0;
    z-index: 99;

    &::before {
      background-color: rgba(#fff, 0.95);
      content: "";
      height: 45px;
      left: 50%;
      max-width: $container-width + $container-padding * 2;
      position: fixed;
      top: 0;
      transform: translateX(-50%);
      width: calc(100% - 40px);
      z-index: -1;
    }
  }

  > li {
    margin-bottom: 3px;

    &.pull-right {
      a {
        margin-right: 0;
      }
    }

    a {
      border-radius: 0;
      border: 3px solid transparent;
      color: $link-color;
      font-family: $gotham-medium;
      font-size: 16px;
      margin-right: 22px;
      padding-left: 0;
      padding-right: 0;

      &:hover,
      &:focus {
        background-color: transparent;
        border-color: transparent;
        box-shadow: 0 3px #eee;
        color: $firm;
      }
    }
  }

  > .active {
    a,
    a:hover,
    a:focus {
      background-color: transparent;
      border: 3px solid transparent;
      box-shadow: 0 3px $firm;
      color: #333;
    }
  }
}

.tooltip-inner {
  &.no-max-width {
    max-width: none;
  }

  pre {
    font-size: 90%;
    color: inherit;
  }
}

.tab-content {
  &.is-noscroll {
    overflow: visible;
  }
}

.tab-pane {
  &.is-relative {
    position: relative;
  }
}

//Pagination
.pagination {
  ul {
    display: inline-flex;

    > li {
      display: inline-flex;
    }
  }
}

// Progress bar
.progress {
  .bar {
    text-shadow: 0 0 1px black;
  }
}

.btn:hover,
.btn:focus {
  background-position: 0 -30px;
}

@media all and (width <= 910px) {
  .alert {
    &.is-popup {
      .alert-wrapper {
        margin: 0 auto;
        width: 100%;
        left: auto;
      }
    }
  }
}

[class^="icon-"],
[class*=" icon-"] {
  background-image: url("~application_v2/glyphicons-halflings.png");
}

.dropdown-submenu:focus > a > [class*=" icon-"] {
  background-image: url("~application_v2/glyphicons-halflings-white.png");
}

@media print {
  a[href]::after {
    content: none !important;
  }
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  box-shadow: none;
}
