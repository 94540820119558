.base-btn {
  display: inline-block;
  border-radius: 4px;
  cursor: pointer;
  padding: 15px;
  font-size: 16px;
  line-height: 1;
  font-family: $gotham-bold;
  font-weight: normal;
  text-align: center;
  color: #333;
  outline: 0;
  border: 1px solid #ccc;
  vertical-align: middle;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;

  @include vertical-gradient(#fdfdfd, #f1f1f1);

  &:hover {
    border-color: #bbb;
    color: #333;
    text-decoration: none;

    @include vertical-gradient(#fff, #f9f9f9);

    .icon-docs {
      background:
        url("~application_v2/icon-docs-orange-24x20.png") 0 0
        no-repeat;

      @include retina {
        background-image: url("~application_v2/icon-docs-orange-24x20@2x.png");
        background-size: 24px 20px;
      }
    }
  }

  &:active {
    box-shadow: inset 0 8px 4px -8px rgb(0 0 0 / 20%);
    color: #333;
    background: #ebebeb;
    border-color: #ccc;
    text-decoration: none;

    .icon-rocket {
      opacity: 1;
    }
  }

  &:focus {
    color: #333;
    text-decoration: none;
  }

  &:visited {
    color: #333;
    text-decoration: none;
  }

  &.is-bsb {
    box-sizing: border-box;
  }

  // sizes
  &.is-nano {
    box-sizing: content-box;
    font-size: 14px;
    padding: 3px 8px;
    min-height: 24px;
    min-width: 14px;

    + .base-btn.is-nano {
      margin-left: 6px;
    }

    .icon {
      margin-left: 10px;

      &:first-child {
        margin-left: 0;
        margin-right: 7px;
      }
    }

    &.is-one {
      .icon {
        margin-right: 0;
      }
    }
  }

  &.is-smaller {
    font-family: $gotham-medium;
    font-size: 12px;
    line-height: 1.5;
    padding: 4px 8px 5px;
  }

  &.is-small {
    padding: 4px 16px 5px;
    font-size: 14px;
    line-height: 1.5;
  }

  &.is-big {
    font-size: 16px;
    padding: 11px 16px;
    line-height: 1;
  }

  &.is-xl {
    font-size: 18px;
    line-height: 1;
    padding: 14px 24px;
  }

  // modificators
  &.is-hidden {
    display: none;
  }

  &.is-light {
    font-family: $gotham-medium;
  }

  &.is-uppercase {
    text-transform: uppercase;
  }

  &.is-inline {
    min-height: auto;
  }

  &.is-block {
    display: block;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  &.is-toggle {
    &.is-active,
    &:active {
      background: #f9f0cd;
    }
  }

  &.is-flex {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &.is-block + &.is-block {
    margin-top: 5px;
  }

  &.is-success {
    @include vertical-gradient(#2fa42f, #408c40);

    border-color: #388238;
    color: #fff;

    &:hover,
    &:visited,
    &:focus {
      color: #fff;
    }

    &.is-hollow {
      background: none;
      border-color: #388238;
      color: #388238;

      &:hover {
        color: #fff;
      }
    }

    &:hover {
      @include vertical-gradient(#3cbc3c, #3e9c3e);

      border-color: #2b782b;
    }

    &:active {
      @include vertical-gradient(#3c913c, #3c913c);

      border-color: #388238;
    }
  }

  &.is-error,
  &.is-danger {
    @include vertical-gradient(#f11d1d, #be0101);

    border-color: #b80000;
    color: #fff;

    &:hover {
      @include vertical-gradient(#f64040, #d60e0e);

      border-color: #9f0000;
    }

    &:active {
      background: #c30304;
      border-color: #b80000;
    }
  }

  &.is-primary,
  &.is-warning {
    @include vertical-gradient(#fd6420, #e03601);

    border-color: #c02b00;
    color: #fff;

    &:hover {
      @include vertical-gradient(#fd6c2d, #eb3a02);

      border-color: #cd3002;
    }

    &:active {
      background: #ea3f08;
      border-color: #c02b00;
    }
  }

  &.is-hollow {
    background: none;
    color: #333;
  }

  &.is-transparent-action {
    background: transparent;
    border-color: #b2d4f4;
    color: #0070d9;

    &:hover {
      color: darken(#0070d9, 15%);
      border-color: darken(#b2d4f4, 15%);
    }

    &.is-dark {
      border-color: #e0e0e0;

      &:hover {
        border-color: #0070d9;
      }
    }

    &.is-error {
      border-color: #b80000;
      color: #b80000;

      &:hover {
        background: transparent;
        border-color: darken(#b80000, 10%);
        color: darken(#b80000, 10%);
      }
    }
  }

  &.is-action {
    background: transparent;
    border-color: #b2d4f4;
    color: #0070d9;

    &:hover {
      color: darken(#0070d9, 15%);
      border-color: darken(#b2d4f4, 15%);
    }
  }

  &.is-sheer {
    background: transparent;
    border: 1px solid rgb(255 255 255 / 30%);
    color: #fff;

    &:hover {
      border: 1px solid rgb(255 255 255 / 60%);
    }
  }

  &.is-transparent {
    color: #0070d9;
    border: none;
    background: none;

    &:hover {
      color: #ff4f00;
    }

    &:focus {
      background: none;
    }

    &:active {
      box-shadow: none;
    }
  }

  &.is-disable,
  &.is-disabled,
  &:disabled {
    pointer-events: none;
    cursor: default;
    background: #f8f8f8;
    border-color: #e6e6e6;
    color: #c4c4c4;

    &.is-transparent {
      background: none;
      border: none;
    }

    &:active {
      box-shadow: none;
    }

    &:hover {
      background: #f8f8f8;
      border-color: #e6e6e6;
      color: #c4c4c4;
    }

    > svg {
      opacity: 0.3;
    }
  }

  &.is-arrow {
    padding: 11px 16px;
    border-color: #bbd6bb;

    @include vertical-gradient(transparent, transparent);

    &:hover {
      border-color: #bbb;

      @include vertical-gradient(#fff, #f9f9f9);

      &.is-left {
        .ico-arrow {
          background-position: 0 -46px;
        }
      }

      &.is-right {
        .ico-arrow {
          background-position: 0 -138px;
        }
      }
    }

    &:active {
      box-shadow: inset 0 8px 4px -8px rgb(0 0 0 / 20%);
      background: #ebebeb;
      border-color: #ccc;

      &.is-left {
        .ico-arrow {
          background-position: 0 -46px;
        }
      }

      &.is-right {
        .ico-arrow {
          background-position: 0 -138px;
        }
      }
    }
  }

  &.is-more {
    background: none;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    font-size: 0;
    height: 30px;
    min-width: 30px;
    position: relative;
    text-align: center;
    vertical-align: middle;
    padding: 0;
    transition: 0.3s;

    &:hover {
      border-color: $link-color;

      > i,
      > i::before,
      > i::after {
        background-color: $link-color;
      }
    }

    > i,
    > i::before,
    > i::after {
      background-color: #858585;
      border-radius: 50%;
      display: block;
      height: 4px;
      position: absolute;
      top: 0;
      width: 4px;
      transition: 0.3s;
    }

    > i {
      left: 50%;
      margin: -2px 0 0 -2px;
      top: 50%;
    }

    > i::before,
    > i::after {
      content: "";
      left: -7px;
    }

    > i::after {
      left: 7px;
    }
  }

  &.is-double-toggle {
    background: transparent;
    border: none;
    font-family: $gotham-medium;
    font-size: 0;
    padding: 17px;
    line-height: 1.2;
    position: relative;
    z-index: 10;
    border-radius: 0;
    box-shadow: none;
    box-sizing: border-box;

    &:hover,
    &:focus {
      background-color: #f9f0cd;
    }

    span {
      font-size: 14px;
      margin-left: 5px;
      margin-right: 5px;
      display: inline-block;
      vertical-align: middle;
    }

    .icon {
      display: inline-block;
      vertical-align: middle;
    }
  }

  &.is-cut {
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 175px;
    }

    &.is-cut-smaller {
      span {
        max-width: 140px;
      }
    }
  }

  &.is-icon {
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    .sign-svg {
      margin: 0;
    }
  }

  .icon {
    vertical-align: middle;
    margin: 0 auto;
  }

  &.is-pressed {
    background: #f9f0cd;
    border-color: #c8bd8e;
    color: #52440c;
  }

  &-icon-wrapper {
    margin-right: 10px;
  }

  &.is-nowrap {
    white-space: nowrap;
  }

  &.is-muted {
    color: rgb(153 153 153);

    &:hover {
      color: darken(rgb(153 153 153), 20%);
    }
  }
}

input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.is-block {
    width: 100%;
  }
}
