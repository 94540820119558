@import "stylesheets/application_v2/config";
@import "stylesheets/themes/shared/utils";

.chosen-container {
  &-wrap {
    &.is-middle {
      .chosen-container-single {
        .chosen-drop {
          min-width: 240px;
        }
      }
    }
  }

  .chosen-drop {
    box-shadow: 0 5px 25px rgb(0 0 0 / 30%);
    background: #fff;
    overflow: hidden;
    border: 0;
    border-radius: 4px;
    min-width: 160px;
  }

  .chosen-results {
    margin: 0;
    padding: 0;
    display: none;

    li {
      padding: 12px 18px;
      font-size: 14px;
      border-bottom: 1px solid #e4e4e4;

      &.highlighted {
        background: #fbf6e4;
        color: #333;
      }

      &.group-result {
        background-color: #eee;
        border: none;
        font-family: $gotham-medium;
        font-size: 10px;
        padding-bottom: 8px;
        padding-top: 8px;
        text-transform: uppercase;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  &-single {
    &.is-not-interactive {
      .chosen-single {
        div {
          display: none;
        }
      }
    }

    &.is-medium {
      .chosen-single {
        height: 32px;
        padding: 7px 12px;

        div b {
          background-position: 0 8px;
        }
      }
    }

    .chosen-single {
      box-shadow: none;
      background: white;
      background: linear-gradient(to bottom, white, #e6e6e6);
      height: 32px;
      padding: 7px 12px 6px;
      font-family: $gotham-medium;
      font-size: 14px;
      line-height: 18px;
      color: #333;
      text-shadow: 0 1px 1px rgb(255 255 255 / 75%);
      background-color: #f5f5f5;
      border: 1px solid #ccc;
      border-radius: 4px;

      div {
        right: 4px;

        b {
          background: url("~chosen/chosen-sprite.png") no-repeat 0 9px;
        }
      }

      abbr {
        background-image: url("~chosen/chosen-sprite.png");
        right: 28px;
        top: 12px;
      }
    }

    .chosen-search {
      padding: 8px 10px;
      border-bottom: 1px solid #e4e4e4;

      input[type="text"] {
        padding: 9px 30px 8px 13px;
        border: 1px solid #ccc;
        background: white url("~chosen/chosen-sprite.png") no-repeat 100% -23px;
        font-size: 14px;

        &:focus {
          border-color: #2e7ccc;
        }
      }
    }

    .chosen-default {
      font-family: $gotham-book;
      color: #999;
    }

    .chosen-drop {
      margin-top: 0;
      border-radius: 4px;
      background-clip: padding-box;
    }
  }

  &-multi {
    &.chosen-container-active {
      .chosen-choices {
        $color: rgb(82 168 236);

        border-color: rgba($color, 0.8);
        box-shadow: inset 0 1px 1px rgba(#000, 0.075), 0 0 8px rgba($color, 0.6);
      }
    }

    .chosen-choices {
      background: #fff;
      box-shadow: inset 0 1px 1px rgba(#000, 0.075);
      border-color: #ccc;
      font-size: 14px;
      padding: 0 5px;
      transition: 0.3s;

      li.search-field {
        input[type="text"] {
          font-family: $gotham-book;
          height: 28px;
        }
      }

      li.search-choice {
        margin: 3px 5px 3px 0;
        padding: 6px 20px 5px 5px;

        .search-choice-close {
          margin-top: -6px;
          top: 50%;
          right: 3px;
          width: 12px;
          height: 12px;
          background: url("~chosen/chosen-sprite.png") -42px 2px no-repeat;
          font-size: 0;

          &:hover {
            background-position: -42px -9px;
          }
        }
      }
    }
  }

  &-active {
    .chosen-results {
      display: block;
    }

    .chosen-single {
      box-shadow: none;
    }

    &.chosen-with-drop .chosen-single {
      box-shadow: inset 0 4px 4px -4px rgb(0 0 0 / 30%);
      background: white;
      background: linear-gradient(to bottom, #ebebeb, #d4d4d4);
      outline: 0;
      border-color: #ccc;
      border-radius: 3px;

      div b {
        background-position: -18px 9px;
      }
    }
  }
}

@media all and (min-resolution: 2dppx), all and (min-resolution: 144dpi) {
  .chosen-rtl .chosen-search input[type="text"],
  .chosen-container-single .chosen-single abbr,
  .chosen-container-single .chosen-single div b,
  .chosen-container-single .chosen-search input[type="text"],
  .chosen-container-multi .chosen-choices .search-choice .search-choice-close,
  .chosen-container .chosen-results-scroll-down span,
  .chosen-container .chosen-results-scroll-up span {
    background-image: url("~chosen/chosen-sprite@2x.png") !important;
    background-size: 53px 80px !important;
  }
}
