.carousel-fade {
  margin-bottom: 0;

  .carousel-inner {
    height: 100%;

    .item {
      height: 100%;
      opacity: 0;
      transition-property: opacity;
    }

    .active {
      opacity: 1;
    }

    .active.left,
    .active.right {
      left: 0;
      opacity: 0;
      z-index: 1;
    }

    .next.left,
    .prev.right {
      opacity: 1;
    }
  }

  .carousel-control {
    z-index: 2;
  }

  &-title {
    font-family: $gotham-bold;
    font-weight: normal;
    font-size: 26px;
    line-height: 1;
    color: #333;
    margin-bottom: 17px;

    .icon-ticket-small {
      margin-top: 5px;
    }

    .icon-lights-small {
      margin-top: -1px;
    }

    .ico-star {
      margin-top: -3px;
    }

    .ico-speed {
      margin-top: -3px;
    }

    .ico-cart {
      margin-top: -3px;
    }
  }

  &-body {
    margin-bottom: 30px;
    padding: 30px 40px;
  }

  &-wrap {
    @include clearfix;
  }

  &-left-part {
    float: left;
    width: 60%;
  }

  &-right-part {
    float: right;
    width: 35%;
  }

  &-text {
    p {
      font-size: 16px;
      font-family: $gotham-book;
      font-weight: normal;
      color: #333;
      line-height: 1.3;
    }
  }

  &-img {
    height: 315px;
  }

  &-footer {
    position: relative;
    background: #f3faf3;
    padding: 20px 40px;
  }

  &-control-wrapper {
    @include clearfix;

    & {
      position: absolute;
      bottom: 14px;
      right: 30px;
      z-index: 1040;
    }

    .base-btn {
      float: left;

      &.is-left {
        margin-right: 10px;
      }
    }
  }
}
