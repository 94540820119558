$self: ".campaign-teaser";

@keyframes deleting {
  0% {
    opacity: 0.25;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 0.25;
  }
}

#{$self} {
  font-size: 14px;
  color: #888;
  line-height: 18px;
  margin-bottom: 30px;
  border-collapse: separate;

  &.is-for-user {
    #{$self}-actions {
      width: 70px;
      text-align: right;
    }
  }

  .icon-question {
    span {
      display: none;
    }
  }

  .is-center {
    text-align: center;
  }

  &-ip {
    margin-top: 5px;

    &-mode {
      &.is-success {
        color: $positive;
      }

      &.is-warning {
        color: $warning;
      }
    }
  }

  &-changes {
    width: 250px;

    &-text {
      word-break: break-word;
    }

    span {
      display: block;
      margin-top: 5px;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  tr {
    &.is-deleting {
      pointer-events: none;
      animation: deleting 1.25s linear infinite;
    }

    &:first-child {
      > th {
        border-bottom: 1px solid #ebebeb;
      }
    }

    &:hover {
      > td {
        background-color: #f9f9f9;
      }

      .timeline {
        &.is-end-not-set {
          .timeline-scale {
            &::after {
              background-color: #f9f9f9;
            }
          }
        }

        &.is-start-not-set {
          .timeline-scale {
            &::before {
              background-color: #f9f9f9;
            }
          }
        }
      }
    }

    > th {
      font-family: $gotham-medium;
      color: #999;
      text-align: left;
      padding: 12px 8px;

      &.campaign-teaser {
        &-count {
          padding-left: 24px;
        }

        &-changes {
          padding-left: 40px;

          #{$self}-changes-text {
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
    }

    > td {
      vertical-align: top;
      text-align: left;
      padding: 8px;
      border-bottom: 1px solid #ebebeb;
      transition: 0.25s;

      &.campaign-teaser {
        &-type {
          width: 10px;
        }

        &-info {
          width: 530px;
        }

        &-abtests {
          width: 80px;

          .campaign-teaser-abtests-link {
            display: flex;

            &:hover,
            &:focus {
              color: $link-hover;
              text-decoration: none;
            }
          }

          .campaign-teaser-abtests-count {
            margin-left: 5px;
            line-height: 21px;
          }

          .campaign-type {
            padding: 0 4px;
            width: 35px;
            font-size: 13px;
            height: 21px;
            border-radius: 4px;
          }
        }

        &-changes {
          padding-left: 40px;
        }

        &-count {
          width: 90px;
          line-height: 23px;

          .campaign-teaser-count-text {
            display: inline-block;
            vertical-align: middle;
          }

          .campaign-teaser-count-warning {
            margin-top: -1px;
            display: inline-block;
            vertical-align: middle;
            text-align: left;
          }
        }

        &-timeline {
          padding: 0 20px;
          width: 260px;

          .timeline {
            margin: 24px 0 26px;
            position: relative;
          }
        }

        &-actions {
          vertical-align: middle;
          text-align: right;
          padding-right: 10px;

          .dropdown-menu {
            text-align: left;
          }
        }
      }
    }
  }

  &-label {
    border: 2px solid;
    display: inline-block;
    font-family: $gotham-medium;
    font-size: 10px;
    border-radius: 50%;
    width: 23px;
    height: 23px;
    text-align: center;
    line-height: 23px;

    &.is-sa {
      color: #e9922b;
    }

    &.is-pp {
      color: #b113d3;
    }
  }

  &-name {
    font-family: $gotham-medium;
    font-size: 16px;
    color: $link-color;
    max-width: 530px;
    margin-right: 10px;
    word-break: break-word;

    &:focus {
      color: $link-color;
      text-decoration: none;
    }

    &:hover {
      color: $link-hover;
      text-decoration: none;
    }
  }

  &-info {
    font-size: 14px;
    color: #888;
    margin: 5px 0;
  }

  &-status {
    display: inline-block;
    font-family: $gotham-medium;
    font-size: 12px;
    color: #888;
    margin-right: 10px;

    .icon {
      margin-right: 3px;
      position: relative;
    }
  }

  &-count {
    .icon {
      margin-left: 1px;
    }
  }

  &-warning {
    font-family: $gotham-medium;
    color: #ce0000;
  }
}
