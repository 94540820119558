// A/B test preview modal
// ---------------------
$self: ".abtest-preview";

#{$self} {
  &-body {
    padding: 0 10px 10px;
  }

  &-variants {
    display: inline-block;
    margin-left: 20px;
    vertical-align: middle;

    .base-btn-group .base-btn + .base-btn {
      margin-left: 0;
    }

    &-prefix {
      display: inline-block;
      font-family: $gotham-bold;
      font-size: 14px;
      margin-right: 10px;
      margin-top: 1px;
      vertical-align: middle;
    }

    .base-btn {
      padding-bottom: 8px;
      padding-top: 9px;
      min-width: 15px;
      text-transform: uppercase;
    }
  }

  &-platform {
    display: inline-block;
    margin-left: 20px;
    vertical-align: middle;
  }

  &-variant {
    $size: 24px;
    $gap: 10px;

    float: left;
    margin-top: -2px;
    width: $size + $gap;

    &-char {
      background-color: #2dc1ff;
      border-radius: 50%;
      color: #fff;
      display: inline-block;
      font-family: $gotham-bold;
      font-size: 12px;
      height: $size;
      line-height: $size;
      text-align: center;
      vertical-align: middle;
      width: $size;
    }

    &-value {
      display: block;
      font-size: 16px;
      margin-bottom: 15px;
      word-wrap: break-word;

      i {
        background-color: #efecf0;
        border-radius: 3px;
        color: #93819c;
        display: inline-block;
        font-style: normal;
        padding: 0 3px;
        vertical-align: baseline;
      }
    }
  }

  &-subnav {
    font-size: 14px;

    a {
      color: $link-color;
      display: inline-block;
      font-family: $gotham-medium;
      margin-left: 30px;
      vertical-align: middle;
      transition: 0.2s;

      &:first-child {
        margin-left: 0;
      }

      &:hover {
        color: $firm;
        text-decoration: none;

        &.is-open {
          svg {
            path {
              stroke: $firm;

              &:first-child {
                fill: $firm;
              }
            }
          }
        }

        &.is-edit {
          svg {
            path {
              &:first-child {
                stroke: $firm;
              }
            }
          }
        }
      }

      &.is-edit {
        svg {
          margin-top: -1px;

          path {
            fill: none;
            stroke: #fff;
            stroke-width: 2px;
            transition: 0.2s;

            &:first-child {
              stroke: $link-color;
            }
          }
        }
      }

      &.is-open {
        svg {
          margin-top: -2px;

          path {
            fill: none;
            stroke: $link-color;
            stroke-width: 2px;
            transition: 0.2s;

            &:first-child {
              fill: $link-color;
              stroke: none;
            }
          }
        }
      }

      * {
        display: inline-block;
        vertical-align: middle;
      }

      svg {
        margin-right: 6px;
      }
    }
  }

  &-iframe {
    clear: both;
    min-width: 1200px;

    &.is-loading {
      display: flex;
      min-height: 150px;
    }

    iframe {
      border: none;
      display: block;
      width: 100%;
    }

    .Previews {
      width: 100%;

      &-view-setup-preset-wrapper {
        margin: 0;
      }
    }
  }

  &-bar {
    align-items: flex-end;
    display: flex;
    font-size: 16px;
    justify-content: space-between;
    margin-top: 20px;
    padding: 7px 0;
  }

  &-zerocase {
    align-items: center;
    background-color: #eee;
    display: flex;
    font-size: 18px;
    justify-content: center;
    text-align: center;
    width: 100%;
  }

  // Bootstrap Modal overrides
  // -------------------------
  &-modal {
    &.is-loading {
      .modal {
        display: flex;
      }
    }

    .modal {
      min-height: 150px;
      min-width: 1200px;

      &-header {
        h3 {
          font-family: $gotham-bold;
          font-size: 30px;
          margin: 5px 0;
        }

        p {
          font-family: $gotham-bold;
          font-size: 14px;
          margin-bottom: 15px;
        }
      }

      &-body {
        max-height: none;
      }

      .chosen-container-single .chosen-single {
        height: 41px;
        padding: 11px 12px;

        div {
          padding-top: 3px;
        }
      }

      .form-chosen-select {
        &.is-simple {
          & + .chosen-container .group-option {
            border-left: 1px solid #e4e4e4;
            margin-left: 10px !important;
          }
        }
      }

      .chosen-drop {
        .group-result {
          color: #999;
        }
      }

      .chosen-container {
        .chosen-results li {
          padding: 12px 10px;
          border-bottom: none;
        }
      }
    }
  }
}
