$self: ".base-form";

#{$self} {
  $label-mb: 5px;

  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 10%);
  border-radius: 10px;

  &-title {
    font-family: $gotham-bold;
    font-size: 28px;
    line-height: 1;
    margin-bottom: 20px;
    color: #333;
  }

  &-sub-title {
    font-size: 16px;
    font-family: $gotham-bold;
    line-height: 1;
    margin-bottom: 20px;
    color: #333;
  }

  &-description {
    font-family: $gotham-book;
    display: block;
    color: #999;
    font-size: 12px;
    line-height: 1;
    margin-top: 10px;
  }

  &-head {
    padding: 10px 30px;
  }

  &.is-nav-above,
  &.is-nav-above &-body {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &-body {
    position: relative;
    background: #fff;
    padding: 25px 30px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    @include mq-max-width($width-3of7) {
      padding-left: 20px;
      padding-right: 20px;
    }

    &.is-with-bottom-radius {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    &.is-ptn {
      padding-top: 0;
    }

    &.is-contrast {
      background-color: #fbfbfb;
    }
  }

  &-row {
    text-align: left;
    margin-top: 18px;

    &.is-mtm {
      margin-top: 20px;
    }

    &.is-first {
      margin-top: 0;
    }
  }

  &-group {
    position: relative;
    text-align: left;

    &-title {
      font-family: $gotham-bold;
      font-size: 24px;
      line-height: 1;
      margin-bottom: 18px;
      color: #333;

      @include mq-max-width($width-3of7) {
        font-size: 20px;
      }
    }

    &-description {
      font-family: $gotham-book;
      font-size: 16px;
      line-height: 1;
      color: #333;
      margin-bottom: 10px;
    }

    &-user-role {
      position: absolute;
      z-index: 2;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &-locked {
      position: relative;
      z-index: 2;
      display: flex;
      align-items: center;
      padding: 8px 0;
      border-top: 1px solid #e6e6e6;
      border-bottom: 1px solid #e6e6e6;

      &-title {
        font-family: $gotham-book;
        font-size: 14px;
        line-height: 18px;
      }

      .sign-svg {
        margin: 0;
      }
    }
  }

  &-label {
    display: inline-block;
    font-family: $gotham-medium;
    font-size: 16px;
    line-height: 1;
    margin-bottom: $label-mb;

    &.is-bold {
      font-family: $gotham-bold;
    }

    &.is-small {
      font-size: 14px;
    }

    &.is-big {
      font-size: 18px;
    }

    &-container {
      display: block;
      margin-bottom: 25px;
    }

    &-wrapper {
      display: flex;
      align-items: center;
    }

    &-tooltip {
      display: flex;
    }
  }

  &-divider {
    height: 1px;
    background: #e5e5e5;
    margin: 30px -30px;

    & + .base-form-notice {
      margin-top: -30px;
    }

    &.is-shorten {
      margin: 20px -10px;
    }
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: #f3faf3;
    padding: 30px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

    @include mq-max-width($width-3of7) {
      padding-left: 20px;
      padding-right: 20px;
      flex-wrap: wrap-reverse;

      .base-btn {
        width: 100%;
      }
    }

    .is-first {
      margin-right: auto;
    }

    &.is-full-width {
      width: 100%;
    }

    &.is-disabled {
      background: #fafafa;
    }

    &.is-align-left {
      justify-content: flex-start;
    }

    &.is-no-radius {
      border-radius: 0;
    }

    &.is-nmbs {
      margin-left: -30px;
      margin-right: -30px;
      margin-bottom: -30px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  &-notice {
    display: none;
    padding: 10px 30px;
    margin: 0 -30px 20px;
    align-items: center;

    &.is-top {
      margin-top: -25px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    &.is-shown {
      display: flex;
    }

    &.is-error {
      background: #ffd8d3;

      p {
        color: #ce0000;
      }

      .icon-cross-styled {
        background: url("~application_v2/icon-cross-error.svg") 0 0 no-repeat;
      }
    }

    &.is-success {
      background: #dff0d8;

      p {
        color: #429f53;
      }

      .icon-cross-styled {
        background: url("~application_v2/icon-cross-success.svg") 0 0 no-repeat;
      }
    }

    &.is-hidden {
      display: none;
    }

    &-right-part {
      margin-left: 20px;

      p {
        font-family: $gotham-medium;
        font-size: 14px;
        line-height: 1;
        margin-bottom: 0;
      }
    }
  }

  .base-input + .base-form-group-description {
    margin-top: 10px;
  }

  &-inline-message {
    color: #000;
    font-size: 13px;
    line-height: 1;
    list-style-type: disc;
    margin-top: 5px;

    &.is-error {
      color: #ce0000;
    }
  }

  &-inline-link {
    display: inline-block;
    font-family: $gotham-medium;
    color: #0070d9;
    font-size: 14px;
    line-height: 1;
    cursor: pointer;

    &:hover {
      color: #ff4f00;
      text-decoration: none;
    }
  }

  &-tip {
    color: #999;
    font-family: $gotham-light;
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;

    &.is-medium {
      font-size: 14px;
      line-height: 1;
    }
  }
}

.select-wrapper {
  position: relative;
  min-height: 32px;
  border: 1px solid rgb(204 204 204);
  background: linear-gradient(white, rgb(230 230 230)) rgb(245 245 245);
  border-radius: 4px;

  input {
    border: none;
    background: transparent;
    font-size: 14px;
    line-height: 1;
  }

  .select-arrow {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
    transition: 0.3s;
  }

  &:focus-within {
    border: 1px solid rgb(204 204 204);
    box-shadow: rgb(0 0 0 / 30%) 0 4px 4px -4px inset;

    .select-arrow {
      transform: translateY(-50%) rotate(270deg);
    }
  }
}

.select-items-wrapper,
.select-empty-case {
  position: absolute;
  top: calc(100% + 2px);
  max-height: 300px;
  overflow-y: auto;
  box-sizing: border-box;
  background-color: rgb(255 255 255);
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 10%) 0 0 0 1px, rgb(0 0 0 / 10%) 0 4px 11px;
  width: 100%;
  z-index: 1;

  .select-item {
    font-size: 14px;
    color: inherit;
    cursor: default;
    display: block;
    padding: 8px 12px;
    width: 100%;
    user-select: none;
    -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
    box-sizing: border-box;

    &:hover {
      background: rgb(251 246 228);
      color: rgb(51 51 51);
    }
  }
}

.select-empty-case {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(153 153 153);
  padding: 8px 12px;
}
