@import "stylesheets/themes/shared/utils";
@import "stylesheets/application_v2/fonts_config";

$base: #333;
$blue-primary: #244aa1;
$blue-secondary: #426e8e;
$firm: #ff4e00;
$link-color: #0070d9;
$link-hover: #ff4e00;
$positive: #29b334;
$warning: #da1a24;
$container-width: 1260px;
$container-padding: 30px;
$width-3of7: 540px;
$width-3of4: 945px;
$width-3of5: 756px;
$highest-z-index: 1000000;

//1: background, 2: color
$base-logical-states: (
  warn: (
    #f6f444,
    #665507
  ),
  success: (
    #dff0d8,
    #468847
  ),
  info: (
    #f6f444,
    #665507
  ),
  danger: (
    #f2dede,
    #b94a48
  )
);
