.base-textarea {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0;
  box-sizing: border-box;
  color: #333;
  font-family: $gotham-book;
  font-size: 16px;
  min-height: 40px;
  padding: 12px;
  resize: vertical;
  width: 100%;

  &.validation-error {
    border-color: #ff4e4e;
  }

  &.is-small {
    font-size: 14px;
  }

  &.is-error {
    border-color: #ff4e4e;
  }

  &.is-success {
    border-color: #2b782b;
  }

  &[disabled] {
    background: #f8f8f8;
    border-color: #e6e6e6;
    color: #ccc;
  }

  &:empty::before {
    content: attr(data-placeholder);
    color: #ccc;
  }
}
