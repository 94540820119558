// A text area field with code preview. Used in many places where
// we need to preview interpolation variables along with regular text.

$self: ".code-area";

@mixin hide($position: absolute) {
  & {
    opacity: 0;
    pointer-events: none;

    // Fix for Grammarly plugin
    position: $position !important;
    z-index: -1 !important;
  }
}

@mixin show($position: static) {
  & {
    opacity: 1;
    pointer-events: all;

    // Fix for Grammarly plugin
    position: $position !important;
    z-index: auto !important;
  }
}

#{$self} {
  position: relative;
  word-break: break-word;

  &:hover {
    > textarea {
      @include show();
    }

    > div {
      @include hide();
    }
  }

  &.is-hover {
    > textarea {
      @include show();
    }

    > div {
      @include hide();
    }

    grammarly-ghost,
    grammarly-btn {
      // stylelint-disable-line
      opacity: 1 !important;
      pointer-events: all;
    }
  }

  > textarea {
    margin: 0;
    padding: 9px 48px 9px 12px;

    @include hide();
  }

  > div {
    box-sizing: border-box;
    line-height: 20px;
    padding: 9px 48px 9px 12px;

    @include show();
  }

  mark {
    background: #efecf0;
    border-bottom: 1px solid #fff;
    border-top: 1px solid #fff;
    border-radius: 4px;
    cursor: default;
    color: #6e517d;
    display: inline-flex;
    margin: -1px 1px;
    padding: 0 4px;
    transition: 0.3s;
    word-break: break-all;

    &.is-invalid {
      background-color: #ffd8d3;
      color: #d00000;
      cursor: default;
    }
  }

  // Grammarly overrides
  grammarly-ghost {
    // stylelint-disable-line
    &,
    & > div,
    & > div > span,
    [data-gramm][data-gramm_editor] .gr-alert.gr_spell,
    [data-gramm][data-gramm_editor] .gr-alert.gr_gramm {
      background: none !important;
      pointer-events: none;
    }

    [data-gramm][data-gramm_editor] .gr-alert.gr_spell,
    [data-gramm][data-gramm_editor] .gr-alert.gr_gramm {
      color: #f82d35 !important;

      &.gr_replaced {
        color: transparent !important;
      }
    }
  }

  grammarly-ghost,
  grammarly-btn {
    // stylelint-disable-line
    opacity: 0 !important;
    pointer-events: none;
  }
}
