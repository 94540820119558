// Campaign filter that's used for example in Campaign Placements and A/B testing suite
$self: ".base-filter";

#{$self} {
  background-color: #fafafa;
  box-sizing: border-box;
  display: table;
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;

  &-cell {
    display: table-cell;
    position: relative;
    vertical-align: middle;

    &.is-query {
      width: 100%;
    }
  }

  &-result {
    $height: 40px;
    $border: 1px;

    background-color: #fff;
    border: $border solid rgba(#000, 0.2);
    border-radius: 4px;
    box-shadow: inset 0 -15px 40px rgba(#000, 0.05);
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    font-family: $gotham-medium;
    font-size: 14px;
    height: $height;
    line-height: $height;
    margin-left: 10px;
    max-width: 210px;
    overflow: hidden;
    padding: 0 32px 0 13px;
    position: relative;
    text-overflow: ellipsis;
    transition: 0.2s;
    white-space: nowrap;

    &.is-button {
      padding: 0 13px;
    }

    &:hover {
      border-color: rgba(#000, 0.25);
      box-shadow: inset 0 -15px 40px rgba(#000, 0.02);
    }

    // Arrows up/down (for select box)
    > i {
      background:
        url("~application_v2/campaignfilter/dropdown-range.svg") 0 0
        no-repeat;
      height: 19px;
      margin-top: -9px;
      position: absolute;
      right: 12px;
      top: 50%;
      width: 12px;
    }
  }

  &-list {
    list-style-type: none;
    margin: 0 0 15px;

    > li {
      box-sizing: border-box;
      display: table;
      font-size: 0;
      padding: 0 0 10px;
      position: relative;
      width: 100%;

      &.is-more {
        margin-bottom: 25px;
        padding: 5px 0 0;
      }

      &.is-zerocase {
        color: rgba(#000, 0.5);
        font-size: 16px;
        padding: 40px 0;
        text-align: center;
      }

      &.is-group {
        margin-top: 25px;
        padding-top: 10px;
      }

      ul {
        margin: 0;
        padding: 15px 0 5px;

        & > li {
          display: table;
          padding: 0 0 10px;
          width: 100%;
        }
      }
    }

    .tooltip {
      position: fixed;
    }
  }

  &-head {
    display: table;
    font-size: 0;
    width: 100%;

    &.is-th {
      border-bottom: 1px solid #e5e5e5;
      margin-bottom: 15px;
      padding: 10px 0 5px;
    }

    #{$self} {
      &-campaign {
        font-size: 14px;

        &-check {
          vertical-align: baseline;
          width: 53px;
        }

        &-data {
          color: #999;
        }

        &-offers {
          font-size: 14px;
          vertical-align: baseline;
        }

        &-views {
          vertical-align: baseline;
        }
      }
    }
  }

  &-campaign {
    &-label {
      display: table-cell;
      vertical-align: top;
      padding-right: 10px;
      min-width: 48px;

      .campaign-type {
        font: 10px $gotham-bold;
        position: relative;
        top: -2px;

        .sign-svg:not(.sign-svg-star) {
          margin-left: 2px;
        }
      }

      & + #{$self}-campaign-data {
        position: relative;
        top: -1px;
      }
    }

    &-views {
      color: #999;
      display: table-cell;
      font-size: 14px;
      padding: 1px 10px 0;
      vertical-align: top;
      min-width: 280px;
      max-width: 280px;
    }

    &-data {
      display: table-cell;
      font-size: 14px;
      vertical-align: baseline;

      &.is-disabled {
        color: #999;

        a {
          color: #999;

          &:hover {
            color: $link-color;
          }
        }
      }

      a {
        display: inline-block;
        vertical-align: middle;
        color: $link-color;
        font-size: 16px;
        font-family: $gotham-medium;
        text-decoration: none;
        word-break: break-all;

        &:hover {
          color: $firm;
        }
      }
    }

    &-status {
      display: table-cell;
      vertical-align: top;
      width: 22px;

      &.is-live > i {
        background-color: #3c913c;
        border-color: #3c913c;
      }

      &.is-disabled > i {
        background-color: #df4343;
        border: 1px solid #df4343;
        border-radius: 0;
        top: 6px;
      }

      &.is-scheduled {
        > i {
          background-color: #eaa717;
          border: none;
          border-radius: 0;
          height: 12px;
          margin-left: 6px;
          margin-top: 4px;
          position: relative;
          width: 4px;

          &::before {
            background-color: #eaa717;
            border: none;
            border-radius: 0;
            bottom: 0;
            content: "";
            height: 12px;
            left: -6px;
            margin: 0;
            position: absolute;
            top: 0;
            width: 4px;
          }
        }
      }

      > i {
        $size: 8px;

        background-color: #fff;
        border: 2px solid #eaa717;
        border-radius: 50%;
        display: inline-block;
        height: $size;
        margin: 5px 7px 0 0;
        vertical-align: top;
        width: $size;
      }
    }

    &-id {
      color: #999;

      a {
        font-size: 14px;
        vertical-align: baseline;
      }
    }

    &-offers {
      color: #999;
      display: table-cell;
      font-size: 16px;
      text-align: right;
      vertical-align: top;
      width: 20%;
    }

    &-check {
      display: table-cell;
      vertical-align: top;
      width: 23px;
    }
  }

  // Campaigns spoiler
  // -----------------
  &-spoiler {
    color: #333;
    margin-bottom: 0 !important;
    padding: 5px 0;
    position: relative;
    transition: 0.2s;

    &.is-readonly &-link {
      cursor: default;

      &:hover {
        color: inherit;
      }

      &::after {
        display: none;
      }
    }

    &-link {
      background-color: #fff;
      cursor: pointer;
      padding-right: 10px;
      position: relative;
      z-index: 1;

      &:hover {
        color: $link-color;

        &::after {
          border-color: $link-color;
        }
      }

      &::after {
        $size: 20px;

        background:
          url("~application_v2/campaignfilter/dropdown.svg") no-repeat
          2px 3px;
        border: 2px solid rgba($link-color, 0.2);
        border-radius: 50%;
        content: "";
        display: inline-block;
        height: $size;
        line-height: $size;
        margin-left: 10px;
        transform: rotate(180deg);
        transition: 0.2s;
        vertical-align: middle;
        width: $size;
      }
    }

    &-delimiter {
      border-bottom: 1px dashed #e5e5e5;
      left: 0;
      position: absolute;
      width: 100%;
      top: 50%;
      z-index: 0;
    }

    &.is-open &-link::after {
      transform: rotate(0deg);
    }
  }

  &-zerocase {
    color: #999;
    font-size: 14px;
    margin-top: 5px;
  }
}
