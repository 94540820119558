// Definition list which mimics table layout
// -----------------------------------------
$self: ".dlist";

#{$self} {
  &-row {
    align-items: baseline;
    display: flex;
    font-size: 14px;
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }
  }

  &-title {
    align-items: baseline;
    display: flex;
    font-family: $gotham-bold;
    width: 150px;

    &::after {
      border-bottom: 1px dotted rgba(#000, 0.2);
      content: "";
      flex-grow: 1;
      height: 1px;
      margin: 0 8px;
    }
  }
}
