.base-input,
input.base-input {
  $main-h: 40px;

  border: 1px solid #ccc;
  border-radius: 0;
  color: $base;
  font-family: $gotham-book;
  font-size: 14px;
  height: $main-h;
  margin: 0;
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
  box-sizing: border-box;

  &.is-medium {
    height: 32px;
  }

  &.is-textarea {
    height: $main-h * 2;
    padding-top: 6px;
    padding-bottom: 6px;

    &.is-autosize {
      min-height: $main-h * 2;
    }
  }

  // Color
  .field_with_errors &,
  &.is-error,
  &.validation-error {
    border-color: #ff4e4e;
  }

  &.is-success {
    border-color: #2b782b;
  }

  &[disabled] {
    background-color: rgba(#000, 0.02);
    border-color: rgba(#000, 0.08);
    box-shadow: none;
    color: rgba(#000, 0.4);
  }
}

.base-label {
  cursor: default;
  font-family: $gotham-bold;
  font-size: 16px;
  margin-bottom: 6px;
  text-align: left;
}

.base-form-row {
  margin-top: 17px;

  &:first-child {
    margin-top: 0;
  }
}

.form-validation {
  color: #ff4e4e;
  padding-top: 3px;
  text-align: left;

  &::first-letter {
    text-transform: uppercase;
  }
}

.base-input-btn {
  height: 40px;

  &.is-medium {
    height: 32px;
  }
}
