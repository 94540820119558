.New-site-details {
  .container {
    width: 100%;
  }

  &-wrapper {
    margin: 30px auto 0;
  }

  &-headline {
    position: relative;
    text-align: center;

    h1 {
      margin-bottom: 20px;
      font-family: $gotham-bold;
      color: #333;
      text-transform: uppercase;
    }

    p {
      color: #333;
      font-family: $gotham-book;
      font-size: 18px;
      line-height: 1.4;
    }
  }

  // front side
  &-steps {
    box-sizing: border-box;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
    display: block;
    margin: 45px 0;
    padding: 40px;
    background: #fff;
    border-radius: 8px;

    @include clearfix;

    &-item {
      &-topline {
        margin: 0 0 20px;

        @include clearfix;

        // icon and number container
        &-box {
          float: left;
          position: relative;
          width: 60px;
          height: 50px;

          &-icon {
            display: inline-block;
            width: 60px;
            height: 60px;

            &.is-icon-site {
              background:
                url("~application_v2/layouts/sites_new/steps-icons-site.png")
                0 0 no-repeat;
              background-size: 100%;
              height: 50px;
            }

            &.is-icon-campaign {
              background:
                url("~application_v2/layouts/sites_new/steps-icons-build.png")
                0 0 no-repeat;
              background-size: 100%;
            }

            &.is-icon-metrics {
              background:
                url("~application_v2/layouts/sites_new/steps-icons-metrics.png")
                0 0 no-repeat;
              background-size: 100%;
            }
          }

          &-number {
            position: absolute;
            bottom: -12px;
            left: -12px;
            width: 20px;
            height: 20px;
            background: #ff4f00;
            border: 4px solid #fff;
            border-radius: 50%;
            color: #fff;
            font-family: $gotham-bold;
            font-size: 15px;
            text-align: center;
          }
        }

        // arrow right container
        &-arrow {
          box-sizing: border-box;
          width: 100%;
          margin-top: 27px;
          padding-left: 70px;
          padding-right: 10px;

          &-line {
            float: left;
            width: 100%;
            height: 2px;
            margin-right: -24px;
            background: #e5f0fb;
          }

          &-point {
            float: left;
            margin-top: -9px;
            width: 24px;
            height: 20px;
            background: url("~application_v2/layouts/sites_new/steps-arrow.png");
          }
        }
      }

      // END topline

      // item description
      &-description {
        width: 80%;
        color: #333;

        p {
          font-family: $gotham-book;
          font-size: 16px;
          line-height: 23px;
        }
      }
    }

    // END item
  }

  // END steps

  &-action-buttons {
    width: 270px;
    margin: 0 auto 70px;
  }

  // END front side

  // form "ADD YOUR SITE"
  &-form-add-site {
    box-sizing: border-box;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
    width: 560px;
    margin: 50px auto 70px;
    padding: 25px 30px 0;
    background: #fff;
    border-radius: 8px;
    overflow: hidden;

    &-control-group {
      margin-bottom: 20px;
    }

    &-controls {
      padding-left: 125px;

      .base-input {
        width: 100%;
      }

      select {
        margin: 5px 0 0;
        height: 38px;
        line-height: 38px;
      }
    }

    &-label {
      float: left;
      width: 125px;
      padding-top: 13px;
      font-family: $gotham-medium;
      font-size: 16px;
      color: #333;
    }

    &-button-holder {
      display: inline-block;
      width: 100%;
      margin: 0 -30px -25px;
      padding: 20px 30px;
      overflow: hidden;
      background: #fafafa;
      text-align: center;

      &.is-active {
        background: #f3faf3;
      }

      &-actions-buttons {
        display: inline-block;
        width: 254px;
      }
    }
  }
}

@media all and (min-resolution: 2dppx), all and (min-resolution: 144dpi) {
  .New-site-details-steps-item-topline-box-icon {
    &.is-icon-site {
      background-image: url("~application_v2/layouts/sites_new/steps-icons-site@2x.png");
    }

    &.is-icon-campaign {
      background-image: url("~application_v2/layouts/sites_new/steps-icons-build@2x.png");
    }

    &.is-icon-metrics {
      background-image: url("~application_v2/layouts/sites_new/steps-icons-metrics@2x.png");
    }
  }
}
