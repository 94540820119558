.timeline {
  position: relative;
  padding: 6px 0 0;
  height: 7px;
  border-right: 2px solid #888;

  a.timeline-label {
    position: absolute;
    color: #999;
    font-family: $gotham-book;
    font-size: 12px;
    text-decoration: none;
  }

  &-progress {
    position: absolute;
    height: 2px;

    span {
      opacity: 0;
    }
  }

  &-scale {
    position: absolute;
    width: 100%;
    background: #888;
    height: 2px;

    &::before {
      content: "";
      position: absolute;
      top: -4px;
      left: 0;
      width: 10px;
      height: 10px;
      background: #888;
      border-radius: 50%;
    }

    &::after {
      content: "";
      position: absolute;
      top: -6px;
      right: -1px;
      width: 0;
      height: 0;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-left: 10px solid #888;
    }
  }

  // States
  // ------
  &.is-finished {
    border-right-color: #c3c3c3;

    .timeline {
      &-scale {
        background: #c3c3c3;

        &::before {
          background-color: #c3c3c3;
        }

        &::after {
          border-left-color: #c3c3c3;
        }
      }

      &-progress {
        .icon {
          &.icon-finished {
            display: block;
            width: 19px;
            height: 19px;
            border-radius: 50%;
            margin: 0 auto;
            margin-top: -9px;
            background:
              #fff
              url("~application_v2/icon-circle-completed-19x19.png") 0 0
              no-repeat;
          }
        }
      }
    }
  }

  &.is-in-progress {
    .timeline {
      &-progress {
        background: #26be31;

        &::before {
          content: "";
          position: absolute;
          top: -4px;
          left: 0;
          width: 10px;
          height: 10px;
          background: #26be31;
          border-radius: 50%;
        }

        &::after {
          content: "";
          position: absolute;
          top: -6px;
          right: -5px;
          width: 0;
          height: 0;
          border-top: 7px solid transparent;
          border-bottom: 7px solid transparent;
          border-left: 10px solid #26be31;
        }
      }

      &-scale::before {
        content: "";
        position: absolute;
        top: -4px;
        left: 0;
        width: 10px;
        height: 10px;
        background: #26be31;
        border-radius: 50%;
      }
    }
  }

  &.is-approved {
    .timeline {
      &-scale {
        background: #26be31;

        &::before {
          background: #26be31;
        }

        &::after {
          content: "";
          position: absolute;
          top: 0;
          right: -5px;
          width: 19px;
          height: 19px;
          border: 0;
          margin: 0 auto;
          margin-top: -9px;
          background:
            #fff url("~application_v2/icon-circle-approved-19x19.png")
            0 0 no-repeat;
        }
      }
    }
  }

  &.is-voided {
    .timeline {
      &-scale {
        background: #ce0000;

        &::before {
          background: #ce0000;
        }

        &::after {
          content: "";
          position: absolute;
          top: 0;
          right: -5px;
          width: 22px;
          height: 19px;
          border: 0;
          margin: 0 auto;
          margin-top: -9px;
          background: #fff url("~application_v2/icon-caution.png") 0 0 no-repeat;
        }
      }
    }
  }

  &.is-pending {
    .timeline {
      &-scale {
        background: #55a1ff;

        &::before {
          background: #55a1ff;
        }

        /* Self Made CSS pending icon
         */

        &::after {
          content: "...";
          position: absolute;
          top: 0;
          right: -5px;
          width: 19px;
          height: 19px;
          border-radius: 50%;
          margin-top: -9px;
          background: #fff;
          border: 1px solid #55a1ff;
          color: #55a1ff;
          text-align: center;
          line-height: 12px;
          font-family: $gotham-book;
          font-size: 14px;
        }
      }
    }
  }

  &.is-end-not-set {
    border-right: 0;

    .timeline {
      &-scale {
        &::after {
          background:
            #fff url("~application_v2/icon-hand-pointer-12x16.png")
            top right no-repeat;
          border: 0;
          width: 12px;
          height: 16px;
          top: -8px;
          padding: 0 3px 0 0;
        }
      }
    }

    &:not([class*="is-finished"]) {
      .timeline {
        &-progress {
          background-color: transparent;
          width: 50% !important;

          &::after {
            background-color: #fff;
            border: 1px solid #ddd;
            border-radius: 50%;
            content: "?";
            height: 16px;
            text-align: center;
            top: -8px;
            width: 16px;

            .campaign-teaser .is-scheduled & {
              display: none;
            }
          }
        }
      }
    }
  }

  &.is-start-not-set {
    .timeline-scale {
      &::before {
        background:
          #fff url("~application_v2/icon-hand-pointer-12x16.png") top
          left no-repeat;
        border: 0;
        width: 12px;
        height: 16px;
        top: -8px;
        padding: 0 3px 0 0;
      }
    }
  }

  /* Timeline labels
   */

  &-label {
    &:hover {
      color: #999;
      text-decoration: none;
    }

    &.is-start-label {
      top: -18px;
      left: 0;
    }

    &.is-start-value {
      bottom: -20px;
      left: 0;
    }

    &.is-end-label {
      top: -18px;
      right: 0;
    }

    &.is-end-value {
      bottom: -20px;
      right: 0;
    }
  }

  /* Actions buttons
   */

  &-actions {
    position: absolute;
    width: auto;
    text-align: center;
    top: -17px;
    left: 50%;
    margin-left: -67px;

    &-wrapper {
      display: inline-block;
      border: 1px solid #ccc;
      background: #fff;
      border-radius: 4px;
      padding: 4px;

      .base-btn {
        transition: all 100ms ease-in-out;
        backface-visibility: hidden;
        margin: 0 4px 0 0;
        padding: 7px;
        min-width: 21px;

        &:last-child {
          margin: 0;
        }
      }
    }
  }
}

@media all and (min-resolution: 2dppx), all and (min-resolution: 144dpi) {
  .timeline {
    &.is-finished .timeline-progress .icon.icon-finished {
      background-image: url("~application_v2/icon-circle-completed-19x19@2x.png");
      background-size: 19px 19px;
    }

    &.is-end-not-set .timeline-scale::after,
    &.is-start-not-set .timeline-scale::before {
      background-image: url("~application_v2/icon-hand-pointer-12x16@2x.png");
      background-size: 12px 16px;
    }

    &.is-approved {
      .timeline {
        &-scale {
          &::after {
            background-image: url("~application_v2/icon-circle-approved-19x19@2x.png");
            background-size: 19px 19px;
          }
        }
      }
    }
  }
}
