.base-switcher {
  position: relative;
  width: 70px;
  font-size: 0;
  line-height: 0;
  user-select: none;
  cursor: pointer;

  &.is-inactive {
    cursor: not-allowed;

    .base-switcher-switch {
      cursor: not-allowed;
    }

    .base-switcher-inner {
      &::before,
      &::after {
        cursor: not-allowed;
      }

      &::before {
        background: rgb(112 200 128 / 70%);
      }

      &::after {
        background: rgb(226 71 79 / 70%);
      }
    }
  }

  &-checkbox {
    position: absolute;
    opacity: 0;
    left: -99999px;
  }

  &-label {
    display: block;
    overflow: hidden;
    border: 1px solid #c9333b;
    border-radius: 15px;
    transition: all 0.3s ease-in 0s;
    backface-visibility: hidden;
    font-size: 0;
    line-height: 0;
    padding: 0;
    margin: 0;
  }

  &-inner {
    display: block;
    height: 26px;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    backface-visibility: hidden;

    &::before,
    &::after {
      display: block;
      float: left;
      width: 50%;
      height: 26px;
      margin: 0;
      padding: 0;
      line-height: 26px;
      font-size: 14px;
      color: #fff;
      font-family: $gotham-medium;
      box-shadow: inset 1px 2px 2px 0 rgb(101 9 13 / 18%);
      cursor: pointer;
      box-sizing: border-box;
    }

    &::before {
      content: "ON";
      padding-left: 10px;
      background-color: #70c880;
      color: #fff;
    }

    &::after {
      content: "OFF";
      padding-right: 6px;
      background-color: #e2474f;
      color: #fff;
      text-align: right;
    }
  }

  &-switch {
    display: block;
    width: 15px;
    height: 15px;
    margin: 7px;
    cursor: pointer;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 36px;
    border-radius: 50%;
    box-shadow: 1.5px 3px 3px 0 rgb(24 107 39 / 50%);
    transition: all 0.3s ease-in 0s;
    backface-visibility: hidden;
  }
}

.base-switcher-checkbox:checked + .base-switcher-label {
  border-color: #429f53;

  .base-switcher-inner {
    margin-left: 0;
  }

  .base-switcher-switch {
    right: 0;
  }
}
