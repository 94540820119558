// Vertical tile (used to form a grid of few columns e.g. Pricing layout).
.tile {
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 15px;
  padding: 20px;
  position: relative;

  .btn-large {
    display: block;
    padding-bottom: 13px;
    padding-top: 13px;
    text-align: center;
    text-transform: capitalize;

    &:visited {
      color: #fff;
    }
  }

  // Name
  h1 {
    color: #2f2f2f;
    font-size: 32px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    vertical-align: middle;

    img {
      margin-top: -3px;
      vertical-align: middle;
    }
  }

  h2 {
    margin-bottom: 0;
  }

  h3 {
    font-size: 15px;
    font-weight: bold;
  }

  .features {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      font-size: inherit;
      margin: 0;
    }
  }

  &.tile-disabled {
    background-color: #f0f0f0;

    * {
      color: #aaa;
    }

    hr {
      border-bottom-color: #e4e4e4;
    }
  }
}

.tile-content {
  line-height: 20px;
  height: 190px;

  p {
    font-size: 15px;
    line-height: inherit;
  }
}

.divider {
  height: 1px;
  margin: 3px 0;
  overflow: hidden;
  background-color: #e5e5e5;
  border-bottom: 1px solid #fff;
  clear: both;
}
