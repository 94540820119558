// Tables
// ------
$self: ".base-table";

#{$self} {
  max-width: 100%;
  width: 100%;
  empty-cells: show;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 14px;
  table-layout: auto;

  &.is-break-all {
    word-break: break-all;
  }

  &.is-simple {
    td,
    th,
    caption {
      border: none;
      padding-left: 0;
    }
  }

  &.is-striped tbody tr:nth-child(odd) td {
    background: #f8f8f8;
  }

  &.is-sticky {
    table-layout: fixed;

    th {
      width: 100%;
    }

    th,
    td {
      position: relative;
    }
  }

  caption,
  th,
  td {
    text-align: left;
    vertical-align: top;

    &.is-right {
      text-align: right;
    }

    &.is-center {
      text-align: center;
    }

    &.is-middle-aligned {
      vertical-align: middle;
    }
  }

  thead {
    th,
    td {
      color: #333;
      font-family: $gotham-medium;
      position: relative;
      vertical-align: bottom;

      &.is-sortable {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        cursor: pointer;
        padding-right: 17px;

        &:hover {
          background-color: #fdfaf1;
        }

        &::after {
          position: absolute;
          bottom: 15px;
          right: 5px;
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
        }
      }

      &.is-active {
        background: #f7f7f7;

        &.is-lower-to-higher::after {
          border-top-color: #000;
        }

        &.is-higher-to-lower::after {
          border-bottom-color: #000;
        }
      }

      &.is-lower-to-higher {
        &::after {
          border-width: 6px 4px 0;
          border-color: #ccc transparent transparent;
        }
      }

      &.is-higher-to-lower {
        &::after {
          border-width: 0 4px 6px;
          border-color: transparent transparent #ccc;
        }
      }
    }
  }

  caption {
    text-transform: uppercase;
    padding: 0 18px;
    color: rgb(0 0 0 / 40%);
    font-size: 12px;
  }

  th,
  td {
    $border: 1px;
    $color: #e5e5e5;

    border-bottom: $border solid $color;
    box-sizing: border-box;
    line-height: 1.4;
    padding: 10px;
    text-align: left;

    &:first-child {
      &::before {
        display: none;
      }
    }

    &::before {
      background-color: $color;
      bottom: -1px;
      content: "";
      height: 1px;
      position: absolute;
      left: -3px;
      width: 3px;
    }

    &[class*="is-sticky-"] {
      background-color: #fff;
      position: absolute;
      top: auto;
      transform: translateY($border);
      z-index: 4;
    }

    &.is-sticky-right {
      right: 0;
    }
  }

  th {
    &[class*="is-sticky-"] {
      align-items: flex-end;
      display: flex;
    }
  }

  tr {
    &:hover {
      td {
        background-color: #fdfaf1;
      }
    }

    &.is-last {
      td {
        border-bottom: none;
      }
    }
  }

  tfoot {
    th,
    td {
      color: rgba(#000, 0.4);
    }
  }

  // Inner elements
  // --------------
  &-container {
    box-shadow: 0 2px 8px 0 rgba(#000, 0.1);
    margin: 30px 0;
    padding: 20px 10px;

    &.is-ptn {
      padding-bottom: 0;
      padding-top: 0;
    }
  }

  &-title {
    color: #000;
    font-family: $gotham-bold;
    font-size: 22px;
    line-height: 1.2;
    margin-bottom: 10px;
    margin-left: 10px;

    &-tip {
      color: #999;
      font-family: $gotham-book;
    }
  }

  &-sub-title {
    color: #333;
    font-family: $gotham-medium;

    &.is-firm {
      color: $firm;
    }
  }

  &-code {
    display: inline-block;
    font-family: $monaco;
    font-size: 12px;
    line-height: 1.3;
    color: #93819c;
    word-break: break-all;
    width: calc(100% - 30px);
  }

  &-text {
    display: inline-flex;
    font-size: 14px;
    line-height: 1.4;
    color: #333;
    font-family: $gotham-book;
    margin-top: 9px;

    &.is-progress {
      color: #999;
    }

    &.is-bold {
      font-family: $gotham-bold;
    }

    &.is-green {
      color: #429f53;
    }

    &.is-active {
      color: #429f53;
    }
  }

  &-description {
    display: inline-block;
    font-family: $gotham-book;
    color: #999;
    font-size: 12px;
    line-height: 1.4;

    &.is-block {
      display: block;
    }
  }

  &-info {
    display: flex;
    font-family: $gotham-medium;
    color: #999;
    font-size: 12px;
    line-height: 1;
    align-items: center;
    margin-bottom: 12px;
    position: relative;

    .sign-svg {
      margin-right: 5px;
    }

    a {
      color: #0070d9;

      &:hover {
        cursor: pointer;
        text-decoration: none;
        color: $link-hover;
      }
    }
  }

  &-item + &-item {
    margin-top: 10px;
  }

  &-wrapper {
    position: relative;
  }

  &-scroller {
    overflow-x: auto;

    &.is-noscroll {
      overflow: visible;
    }

    &.is-shadowed:not(.is-right-edge) &-shadow.is-left,
    &.is-shadowed:not(.is-left-edge) &-shadow.is-right {
      opacity: 1;
    }

    &-shadow {
      box-shadow: inset -10px 0 20px -10px rgba(#000, 0.1);
      height: 100%;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      transition: 0.3s;
      width: 30px;
      z-index: 3;

      &.is-right {
        box-shadow: inset 10px 0 20px -10px rgba(#000, 0.1);
      }
    }

    .tooltip {
      position: fixed;
    }
  }

  // Other module overrides
  // ----------------------
  .link {
    line-height: 1.4;
    word-break: break-all;
  }

  .is-middle {
    i {
      margin: 0 0 0 8px;
    }

    * {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

#{$self}.distribution {
  border: 1px solid #ccc;

  th,
  td {
    border: 1px solid #ccc;
  }

  td:hover {
    background-color: #f6efc4;

    &-sub-title.is-underline {
      border-bottom-color: #333;
    }

    &-sub-title.is-all {
      border-bottom-color: $firm;
    }
  }

  td#{&}-td-tooltip {
    position: relative;
  }

  &-sub-title {
    &.is-underline {
      border-bottom: 1px dashed #ccc;
      padding-bottom: 2px;
    }

    &.is-all {
      border-bottom: 1px dashed #ebb7a7;
      padding-bottom: 2px;
    }
  }

  &-description {
    color: #333;
  }

  &-percentage {
    background-color: #e7e7e799;
    border-radius: 16px;
    padding: 4px 8px;
  }

  &-tooltip {
    position: absolute !important;
    right: 6px;
    top: 6px;
  }
}
