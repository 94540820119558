.subnav {
  background: #181818;
  border-bottom: 1px solid rgba(#000, 0.15);
  font-size: 16px;
  height: 58px;
  margin-bottom: 20px;
  z-index: 100;
  box-sizing: border-box;

  &.is-sticky {
    left: 0;
    margin: 0;
    position: fixed;
    top: 0;
    width: 100%;
  }

  &.is-combined {
    margin-bottom: 0;
  }

  .subnav-actions-second-level {
    margin-left: 0;

    li,
    .dropdown-item,
    .link {
      .dropdown-link,
      a {
        color: #999;
        padding-left: 40px;

        &:hover {
          color: #333;
        }
      }
    }
  }

  .subnav-actions-title {
    box-sizing: border-box;
    cursor: default;
    display: inline-flex;
    font-family: $gotham-medium;
    font-size: 14px;
    padding: 10px 5px 10px 18px;
    vertical-align: middle;
  }

  &.is-skinny {
    height: 30px;

    .subnav-menu-holder {
      position: relative;
      width: 72px;
    }

    ul {
      margin: 0;
    }

    .subnav-menu {
      li {
        font-family: $gotham-medium;

        form {
          margin-bottom: 0;
        }

        a,
        .link {
          display: flex;
          align-items: center;
          height: 30px;
          font-size: 12px;
          padding: 0 10px;
        }
      }

      &.is-float-right {
        float: right;
      }
    }

    .subnav-actions {
      padding: 0;

      & > ul {
        font-size: 0;

        & > li {
          margin-left: 15px;
          position: relative;
          vertical-align: middle;
          font-size: 12px;
          line-height: 1;
          color: #9d9d9d;

          &:first-child {
            margin-left: 0;
          }

          &.is-cropped {
            padding-top: 8px;
            padding-bottom: 8px;

            span {
              display: block;
              max-width: 115px;
              padding-right: 10px;
              overflow: hidden;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }

  ul {
    list-style-type: none;
    margin: 0 0 0 -10px;
    padding: 0;

    &.is-reset {
      margin: 0;
    }

    @include clearfix;
  }

  &-fade {
    &::after {
      content: "";
      pointer-events: none;
      position: absolute;
      width: 40%;
      height: 100%;
      top: 0;
      right: 0;
      transition: opacity 0.25s;
      backface-visibility: hidden;

      @include horizontal-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
    }
  }

  &-divider {
    margin-left: 10px;
    padding-left: 10px;
    position: relative;

    &.is-inline {
      &::before {
        top: 4px;
        bottom: 4px;
      }
    }

    &::before {
      content: "";
      width: 1px;
      position: absolute;
      left: 0;
      top: 6px;
      bottom: 6px;
      background: #535353;
    }
  }

  &-indicator {
    display: inline-block;
    vertical-align: middle;
    color: white;
    font-size: 12px;
    font-family: $gotham-book;
  }

  &-title {
    float: left;
    font-size: 12px;
    padding: 5px 10px;
    margin-left: 10px;
    color: #ccc;
    max-width: 260px;
    overflow: hidden;
    white-space: nowrap;
    font-family: $gotham-book;

    &-text {
      display: inline-block;
      vertical-align: middle;
    }

    &:hover {
      overflow: auto;
      max-width: none;

      &::after {
        opacity: 0;
      }
    }
  }

  &-menu {
    float: left;

    li {
      float: left;
      font-family: $gotham-bold;
      font-size: 14px;

      a {
        color: #ccc;
        display: block;
        font-size: 16px;
        line-height: 1;
        text-decoration: none;
        padding: 20px;

        &:hover {
          color: #f60;

          svg {
            path {
              fill: #f60;
            }
          }
        }

        &.is-disabled {
          color: #8a8a8a;
          cursor: not-allowed;

          &:hover {
            color: #8a8a8a;
          }
        }

        &.is-active {
          color: #fff;
          background: #0d2969;
        }
      }
    }

    &-unread {
      background-color: #c00;
      border-radius: 40px;
      color: #fff;
      display: inline-block;
      font-size: 12px;
      margin-left: 4px;
      padding: 2px 8px;
      position: relative;
      top: -1px;
    }
  }

  &-actions {
    float: right;
    padding: 15px 0;

    & > ul {
      &.dropdown-menu {
        li {
          margin: 0;
          display: block;
        }
      }

      & > li {
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
        margin-right: 0;
      }
    }

    &-toggler {
      margin-top: 2px;
      border: 1px solid #aeaeae;
      border-radius: 3px;

      & > ul {
        margin: 0;
        font-size: 0;
        line-height: 0;

        & > li {
          display: inline-block;
          margin-left: 0;

          &.is-active {
            a {
              background: #dbd8d8;
              color: #000;

              &:hover {
                color: #000;
              }
            }
          }

          a {
            display: block;
            color: #aeaeae;
            text-decoration: none;
            font-family: $gotham-medium;
            font-size: 12px;
            line-height: 1;
            padding: 4px 6px;
            transition: color 0.3s;
            backface-visibility: hidden;

            &:hover {
              text-decoration: none;
              color: #fff;
            }
          }
        }
      }
    }

    /* Bootstrap buttons
     */

    .btn-group {
      margin-top: -4px;
    }

    .btn {
      font-family: $gotham-medium;
      font-size: 14px;
      padding: 7px 13px;
      color: #fff;
      text-shadow: none;
      vertical-align: middle;

      &:not([class*="btn-"]) {
        color: #929292;
        text-shadow: none;
        vertical-align: middle;
        border: 1px solid #444;

        @include vertical-gradient(#242424, #0f0f0f);

        &:hover {
          @include vertical-gradient(#3f3f3f, #161616);
        }

        &:active {
          @include vertical-gradient(#0c0c0c, #2b2b2b);
        }
      }

      &.btn-danger {
        color: #fff;
        border-color: #b80000;

        @include vertical-gradient(#f11d1d, #be0101);

        &:hover {
          @include vertical-gradient(#f74242, #d70e0e);
        }

        &:active {
          background: #c30304;
        }
      }

      &.is-firm {
        color: #fff;
        border: 1px solid #c02b00;

        @include vertical-gradient(#fd6420, #e03601);

        &:hover {
          @include vertical-gradient(#fe6f30, #eb3902);
        }

        &:active {
          background: #ea3f08;
        }
      }

      &.is-positive {
        color: #fff;
        border: 1px solid #388238;

        @include vertical-gradient(#2fa32f, #3e8e3e);
      }

      .icon.icon-plus {
        position: relative;
        top: -1px;
        background-image: url("~application_v2/icon-plus-white.png");
        background-position: 0 0;
        margin-right: 5px;
        vertical-align: middle;
      }
    }
  }

  &-anchor {
    position: relative;
    top: -66px;
  }
}

@media all and (min-resolution: 2dppx), all and (min-resolution: 144dpi) {
  .subnav-actions .icon.icon-plus {
    background-image: url("~application_v2/icon-plus-white@2x.png");
    background-size: 100% 100%;
  }
}
