$self: ".dashboard-tile";
$metric-font-size: 22px;

#{$self} {
  background-color: #fff;
  position: relative;
  box-shadow: 0 0 10px rgba(#000, 0.1);

  @include clearfix;

  ul {
    list-style-type: none;
    margin-left: 0;
  }

  &-head {
    background-color: #fafafa;
    border: 1px solid rgba(#000, 0.1);
    border-bottom: none;
    padding: 20px;

    @include mq-max-width(1160px) {
      padding: 15px;
    }

    &.is-middle {
      display: flex;
      align-items: center;
    }

    &-action {
      transition: 0.2s;

      &.is-right {
        margin-left: auto;
      }
    }

    &-handle {
      border-bottom: 2px solid;
      border-top: 2px solid;
      border-color: $link-color;
      cursor: move;
      display: inline-block;
      height: 6px;
      position: relative;
      vertical-align: middle;
      width: 20px;
      transition: 0.2s;

      &::before {
        border-bottom: 2px solid $link-color;
        content: "";
        height: 0;
        left: 0;
        margin-top: -1px;
        position: absolute;
        top: 50%;
        width: 100%;
        transition: 0.3s;
      }

      &:hover {
        border-color: $link-color;
        height: 10px;
      }
    }

    &-title {
      display: inline-block;
      color: $base;
      font-family: $gotham-bold;
      font-size: 28px;
      line-height: 1;
      margin-bottom: 0;
      transition: 0.2s;
      padding-right: 40px;

      &.is-wide {
        padding-right: 0;
      }

      @include mq-max-width(1160px) {
        font-size: 26px;
      }

      + div {
        margin-top: 10px;
      }

      &.is-full-width {
        justify-content: space-between;
      }

      a {
        color: $base;
        word-break: break-all;
        text-decoration: none;
        transition: color 0.3s;

        &:hover,
        &:focus {
          color: $firm;
        }
      }

      small {
        font-family: $gotham-book;
        font-size: 12px;
      }

      &-tooltip {
        display: inline-flex;
        align-self: center;
        margin-left: 5px;
      }
    }

    h2 {
      color: $base;
      font-family: $gotham-medium;
      font-size: 14px;
      line-height: 1.231;
    }
  }

  &-select {
    width: 310px;
    margin-bottom: 15px;
    transition: 0.2s;
    position: relative;
    z-index: 3;
  }

  &-edit-link {
    display: inline-block;
    width: 20px;
    height: 20px;
    color: $link-color;

    &-wrapper {
      position: absolute;
      top: 9px;
      right: 11px;
      z-index: 1;
    }

    .sign-svg {
      margin: 0;

      .svg-fill {
        transition: fill 0.3s;
      }
    }

    &:hover,
    &:focus {
      color: #ff4e00;
      text-decoration: none;
    }
  }

  &-tabs {
    &.is-campaign-performance {
      #{$self}-tabs-buttons {
        &::before,
        &::after {
          border: 1px dashed #ddd;
          border-radius: 0 0 6px 6px;
          border-top: none;
          bottom: -18px;
          color: #999;
          content: "Advocate metrics";
          display: block;
          font-size: 10px;
          left: 9%;
          line-height: 17px;
          position: absolute;
          text-align: center;
          width: 30%;
        }

        @include mq-max-width(1320px) {
          .btn {
            font-size: 13px;
          }
        }

        @include mq-max-width(1270px) {
          .btn {
            font-size: 11px;
          }
        }

        @include mq-max-width(1140px) {
          .btn {
            font-size: 10px;
          }
        }

        @include mq-max-width(700px) {
          width: auto;
        }

        &::after {
          content: "Friend metrics";
          left: auto;
          right: 10%;
          width: 35%;
        }

        &-content {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &-wrapper {
          margin-bottom: 30px;
        }
      }
    }

    &-buttons {
      max-width: 100%;
      position: relative;

      &-wrapper {
        margin-bottom: 20px;
      }

      @include mq-max-width(700px) {
        width: 100%;

        .btn {
          max-width: 33%;
        }
      }
    }

    &-button {
      &-content {
        display: inline-block;
        text-overflow: ellipsis;
        max-width: 174px;
        box-sizing: border-box;
        white-space: nowrap;
        overflow: hidden;
        vertical-align: middle;
      }
    }
  }

  &-metric {
    &-name {
      .tooltip {
        width: 200px;
      }
    }
  }

  &-metrics {
    &-table {
      display: flex;

      @include mq-max-width(1250px) {
        flex-wrap: wrap;
      }

      &-col {
        box-sizing: border-box;
        margin-left: 25px;
        padding-left: 25px;
        border-left: 1px solid #f2f2f2;
        width: 50%;

        &:first-child {
          padding-left: 0;
          margin-left: 10px;
          border-left: none;
        }

        @include mq-max-width(1250px) {
          width: 100%;
          margin-left: 11px;
          padding-left: 0;
          border-left: none;

          &:first-child {
            padding-left: 0;
            margin-left: 11px;
          }
        }

        li {
          padding: 0 7px;
          margin: 0 -7px 5px;

          &:hover {
            &,
            span {
              background-color: #ffe;
            }

            &.is-th {
              background-color: transparent;
            }
          }

          &.is-th {
            background-color: transparent;

            .dashboard-tile-metrics-table-th {
              margin-top: 5px;
            }
          }
        }
      }

      &-th,
      &-td {
        display: inline-block;
        text-align: right;
        vertical-align: baseline;
        width: 27%;
        box-sizing: border-box;

        &:first-child {
          text-align: left;
          width: 43%;
        }
      }

      &-th {
        color: #999;
        font-family: $gotham-medium;
        font-size: 12px;
        margin-top: 15px;

        &.is-advocate {
          color: $firm;
        }

        &.is-friend {
          color: #8d5ec6;
        }
      }

      &-td {
        font-family: $gotham-medium;
        font-size: 14px;
        position: relative;

        &:last-child {
          color: #999;
          font-family: $gotham-book;
          font-size: 12px;
        }

        .tooltip-wrap {
          position: relative;
          top: 1px;
          margin-left: 5px;
        }

        > span {
          background-color: #fff;
          position: relative;
          z-index: 1;
        }

        &::after {
          border-bottom: 1px dashed #cde3ec;
          bottom: 4px;
          content: "";
          height: 0;
          left: 0;
          position: absolute;
          width: 100%;
        }
      }
    }
  }

  &-chart {
    &,
    &-wrapper {
      display: flex;
      width: 100%;
      min-height: 260px;
      overflow: hidden;
      transition: min-height 0.25s;

      &.is-empty {
        min-height: 110px;
      }
    }

    &-empty {
      width: 100%;
      text-align: center;
      align-self: center;
      color: #999;
    }
  }

  &-stats {
    margin-top: 10px;
    transition: 0.2s;

    &.is-custom {
      li {
        li {
          width: 100%;
        }
      }

      &.is-2 {
        li {
          width: 50%;
        }
      }

      &.is-3 {
        li {
          width: 33%;
        }
      }
    }

    &-tooltip {
      margin-left: 5px;
    }

    ul {
      display: flex;
      margin-bottom: 0;
    }

    li {
      margin-right: 15px;
      vertical-align: top;

      &.is-right {
        margin-left: auto;
        margin-right: 0;
      }
    }

    small {
      display: flex;
      font-size: 14px;
      margin-bottom: 3px;

      .icon {
        position: relative;
        top: 1px;
      }
    }

    span {
      font-family: $gotham-medium;
      font-size: $metric-font-size;

      @include mq-max-width(1200px) {
        font-size: 16px;
      }

      @include mq-max-width(1080px) {
        font-size: $metric-font-size;
      }

      a {
        color: #333;
        text-decoration: none;
        transition: 0.3s;

        &.is-advocate {
          color: #0070d9;
        }

        &.is-friend {
          color: #ff4f00;
        }
      }
    }

    em {
      color: #999;
      display: block;
      font-family: $gotham-book;
      font-size: 12px;
      font-style: normal;

      &::after {
        content: " previously";
        font-size: 95%;
        opacity: 0.75;
      }
    }
  }

  &-content {
    background-color: #fff;
    border: 1px solid rgba(#000, 0.1);
    border-top: none;
    transition: 0.2s;
    padding: 20px 19px 19px;

    @include mq-max-width(1160px) {
      padding: 15px;
    }

    &-item {
      + #{$self}-content-item {
        margin-top: 20px;
      }
    }

    &-left-part {
      border-bottom: none;
      border-right: none;
      float: left;
      padding-right: 40px;
      width: 66%;
      box-sizing: border-box;
    }

    &-right-part {
      float: left;
      padding: 0 25px 20px 0;
      width: 34%;
      box-sizing: border-box;
    }

    .btn-group {
      margin-right: 15px;
    }
  }

  &-action {
    color: #0070d9;
    display: inline-block;
    font-family: $gotham-medium;
    font-size: 14px;
    position: relative;
    text-decoration: none;
    vertical-align: middle;
    transition: 0.3s;

    span {
      display: inline-block;
      vertical-align: middle;
    }

    &:hover {
      color: $firm;
      text-decoration: none;
    }
  }

  &-campaigns {
    &-list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;

      &.is-large {
        li {
          text-align: center;
          margin-right: 0;
        }

        span {
          display: block;
          line-height: 1;
        }

        small {
          display: block;
        }
      }

      li {
        &.is-scheduled {
          & > a {
            color: #eaa717;
          }
        }

        &.is-test {
          & > a {
            color: #9579a8;
          }
        }

        & > a {
          color: #ff4e00;
          text-decoration: none;
          transition: color 0.3s;
        }

        span {
          font-family: $gotham-bold;
          display: inline-block;
          font-size: 50px;
          margin-right: 7px;
          vertical-align: middle;
        }

        small {
          display: inline-block;
          font-family: $gotham-medium;
          font-size: 16px;
          line-height: 1.231;
          vertical-align: middle;
        }
      }
    }
  }

  &-referrals {
    &-text {
      display: block;
      font-size: 16px;
      margin-bottom: 3px;
    }

    &-value {
      display: inline-block;
      vertical-align: middle;
      color: #426e8e;
      font-size: 20px;
      transition: color 0.3s;
      font-family: $gotham-medium;
      margin-right: 9px;
    }

    &-icon {
      display: inline-block;
      vertical-align: middle;
      line-height: 0;
    }

    &-list {
      li {
        display: inline-block;
        margin-right: 40px;
        vertical-align: middle;

        a {
          color: #333;

          &:hover {
            text-decoration: none;
            color: #ff4e00;

            span {
              color: #ff4e00;
            }
          }
        }
      }
    }
  }
}
