$self: ".base-notification-label";

#{$self} {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  font-family: $gotham-bold;
  font-size: 12px;
  line-height: 1;
  padding: 3px;

  &.is-medium {
    padding: 10px;
    font-family: $gotham-medium;
    line-height: 1.4;
  }

  &.is-middle {
    padding: 3px 6px;
  }

  &.is-big {
    padding: 10px 6px;
  }

  &.is-right,
  &.is-top,
  &.is-left,
  &.is-bottom {
    &::before {
      content: "";
      top: 50%;
      left: -4px;
      margin-top: -4px;
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-color: transparent;
      border-width: 4px 4px 4px 0;
    }
  }

  &.is-left {
    font-size: 14px;
    width: 70%;
    text-align: left;

    &::before {
      border-width: 4px 0 4px 4px;
      margin-left: -4px;
      right: -4px;
      left: auto;
    }
  }

  &.is-bottom {
    &.is-sticky {
      bottom: 0;
    }

    &::before {
      border-width: 4px 4px 0;
      transform: rotateZ(180deg);
      left: 50%;
      margin-left: -4px;
      top: 0;
    }
  }

  &.is-top {
    &.is-sticky {
      top: 0;
    }

    &::before {
      border-width: 0 4px 4px;
      left: 50%;
      margin-left: -4px;
      bottom: 0;
    }
  }

  $warn-config: map-get($base-logical-states, warn);

  & {
    //default is 'warn'
    @include add-logical-state-styles($warn-config);
  }

  @each $dir in top right bottom left {
    &.is-#{$dir}::before {
      border-#{opposite-dir($dir)}-color: nth($warn-config, 1);
    }
  }

  // state modifiers
  @include add-all-available-logical-state-styles;

  @each $state-name, $config in $base-logical-states {
    @each $dir in top right bottom left {
      &.is-#{$state-name}.is-#{$dir}::before {
        border-#{opposite-dir($dir)}-color: nth($config, 1);
      }
    }
  }

  &.is-sticky {
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 999;

    &::before {
      display: none;
    }
  }

  a {
    color: #1273d6;

    &:hover {
      color: #ff4f00;
      text-decoration: none;
      cursor: pointer;
    }

    &:focus,
    &:visited {
      color: #1273d6;
      text-decoration: none;
    }
  }
}
