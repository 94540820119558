.loader {
  padding-bottom: 30px;
  position: relative;
  overflow: hidden;

  &-box {
    bottom: 6px;
    display: flex;
    justify-content: center;
    right: 0;
    left: 0;
    margin: 0 auto;
    position: absolute;
    z-index: 3;
    width: auto;

    span {
      color: #bababa;
      margin-right: 8px;
    }

    &-spinner {
      &-bounce,
      &::before,
      &::after {
        animation: bouncedelay 1.2s infinite ease-in-out;
        animation-fill-mode: both;
        background-color: #bababa;
        border-radius: 50%;
        content: "";
        display: inline-block;
        height: 8px;
        margin: 0 2px;
        width: 8px;
      }

      &::before {
        animation-delay: -0.32s;
      }

      &-bounce {
        animation-delay: -0.16s;
      }
    }
  }

  &::after {
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &::before {
    background:
      linear-gradient(
        to right,
        rgb(255 255 255 / 40%) 0%,
        rgb(255 255 255 / 100%) 50%,
        rgb(255 255 255 / 40%) 100%
      );
    box-shadow: 0 0 100px rgb(255 255 255 / 100%);
    content: "";
    height: 100%;
    padding-bottom: 30px;
    position: absolute;
    opacity: 0.6;
    z-index: 2;
    width: 200px;
    transform: skew(300deg);
    top: 0;
    animation: loader 1.2s linear infinite;
  }

  input[type="checkbox"] + label::before {
    background: #f6f6f6;
    border-color: #f6f6f6;
  }

  &.is-ab-test-variants {
    .test-variants-row {
      flex-direction: row;

      &-msg-box,
      &-action-box {
        & > * {
          background: #f6f6f6;
          border: none;
          box-shadow: none;
        }
      }

      &-percentage {
        margin: -2px 0 0 8px;
        width: 40px;
      }
    }
  }

  &.is-campaigns {
    .campaignfilter-campaign-status > i {
      background: #f6f6f6;
      border-color: #f6f6f6;
    }

    .campaignfilter-list > li ul {
      padding: 5px 0;
    }

    .is-edit {
      margin-bottom: 10px;

      &:first-child .campaignfilter-campaign-data a {
        width: 28%;
      }

      &:last-child .campaignfilter-campaign-data a {
        width: 31%;
      }
    }

    .campaignfilter-campaign-data a,
    .campaignfilter-campaign-id,
    .campaignfilter-campaign-offers span,
    .loader-static-block {
      background: #f6f6f6;
      display: block;
      height: 17px;
    }

    .loader-static-block {
      margin: 5px 0;
      width: 20%;
    }

    .campaignfilter-campaign-offers span {
      float: right;
      margin-top: 3px;
      width: 17px;
    }

    .campaignfilter-campaign-id {
      width: 11%;
    }

    .campaignfilter-campaign-data a {
      margin: 3px 0 5px;
      width: 46%;
    }
  }
}

@keyframes loader {
  0% {
    left: -20%;
  }

  100% {
    left: 100%;
  }
}

@keyframes bouncedelay {
  0%,
  80%,
  100% {
    opacity: 0.7;
    transform: scale(0.5);
  }

  40% {
    opacity: 1;
    transform: scale(1);
  }
}
