// Bootstrap modal overrides
// -------------------------

$self: ".modal";

#{$self} {
  border: none;
  border-radius: 10px;
  box-shadow: 5px 0 30px rgba(#000, 0.7);
  height: auto;
  left: 50%;
  margin: 0;
  transform: translate(-50%, -50%);
  top: 50%;

  &.is-preview {
    width: auto;
  }

  &-overlay {
    background-color: rgba(#000, 0.6);
    display: flex;
    height: 100%;
    left: 0;
    overflow: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;

    &.is-transparent {
      background-color: transparent;
    }

    #{$self} {
      left: 0;
      position: relative;
      transform: none;
      top: 0;
    }
  }

  &-holder {
    margin: auto;
    padding: 30px;
  }

  &-close {
    $size: 24px;

    border: 2px solid rgba(#000, 0.1);
    border-radius: 50%;
    cursor: pointer;
    height: $size;
    position: absolute;
    right: 10px;
    transform: rotate(45deg);
    transition: 0.2s;
    top: 10px;
    width: $size;

    &:hover {
      border-color: $link-color;

      &::before,
      &::after {
        background-color: $link-color;
      }
    }

    &::before,
    &::after {
      $size: 14px;

      background-color: #999;
      content: "";
      height: $size;
      left: 50%;
      margin: -7px 0 0 -1px;
      position: absolute;
      top: 50%;
      transition: 0.2s;
      width: 2px;
    }

    &::after {
      transform: rotate(90deg);
    }
  }
}
