.base-toggle {
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  position: relative;
  background: none;
  border: none;
  padding: 0;

  &:active {
    outline: none;
  }

  &.is-light {
    .ico-bar {
      background: #0070d9;
    }
  }

  .ico-bar {
    display: block;
    width: 100%;
    background: #d3d3d3;
    height: 2px;
    margin-top: 4px;

    &:first-child {
      margin-top: 0;
    }
  }
}
