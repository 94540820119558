// Diff gem vendored styling
// -------------------------
.diff {
  overflow: auto;

  ul {
    background: #fff;
    overflow: auto;
    font-size: 13px;
    list-style: none;
    margin: 0;
    padding: 0;
    display: table;
    width: 100%;
  }

  del, ins {
    display: block;
    text-decoration: none;
  }

  li {
    padding: 0;
    display: table-row;
    margin: 0;
    height: 1em;

    &.ins {
      background: #dfd;
      color: #080;
    }

    &.del {
      background: #fee;
      color: #b00;
    }

    &:hover {
      background: #ffc;
    }
  }

  del, ins, span {
    white-space: pre-wrap;
    font-family: courier;
  }

  del strong {
    font-weight: normal;
    background: #fcc;
  }

  ins strong {
    font-weight: normal;
    background: #9f9;
  }

  li {
    &.diff-comment {
      display: none;
    }

    &.diff-block-info {
      background: none repeat scroll 0 0 gray;
    }
  }
}

/* try 'whitespace:pre;' if you don't want lines to wrap */
