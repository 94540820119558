// Notice bar
$self: ".Notice";

#{$self} {
  font-size: 14px;
  font-family: $gotham-medium;
  line-height: 1.2;
  padding: 15px 20px;
  position: relative;
  box-sizing: border-box;

  &.is-with-margin {
    margin: 20px 0;
  }

  &.is-round {
    border-radius: 4px;
  }

  &.is-info {
    a {
      text-decoration: none;
      color: #0070d9;

      &:visited {
        color: #0070d9;
      }

      &:hover {
        color: darken(#0070d9, 15%);
      }
    }
  }

  &.is-tooltip {
    &::before {
      content: "";
      top: 50%;
      left: -4px;
      margin-top: -4px;
      position: absolute;
      width: 0;
      height: 0;
      border-color: transparent #f6f344 transparent transparent;
      border-style: solid;
      border-width: 4px 4px 4px 0;
    }

    &.is-bottom {
      &::before {
        border-width: 0 4px 4px;
        left: 50%;
        margin-left: -4px;
        top: 0;
      }

      &.is-right.is-color-fix {
        &::before {
          left: 20px;
          border-right-color: transparent;
          border-left-color: transparent;
          border-bottom-color: #f6f344;
        }
      }
    }
  }

  &.is-two-sections {
    width: 100%;
    display: flex;
    align-items: center;

    #{$self}-left-part {
      margin-right: 10px;
    }
  }

  @include add-all-available-logical-state-styles;

  @each $state-name, $config in $base-logical-states {
    @each $dir in top right bottom left {
      &.is-#{$state-name}.is-tooltip.is-#{$dir}::before {
        border-#{opposite-dir($dir)}-color: nth($config, 1);
      }
    }
  }
}
