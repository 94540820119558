// Flash notification bar
$self: ".Flash";

#{$self} {
  bottom: -100px;
  font-family: $gotham-book;
  font-size: 18px;
  left: 0;
  opacity: 0;
  padding: 10px 60px;
  position: fixed;
  text-align: center;
  right: 0;
  visibility: hidden;
  z-index: 99999;
  transition: 0.3s;
  backface-visibility: hidden;

  &.is-success {
    background-color: #dff0d8;
    color: #468847;
  }

  &.is-danger {
    background-color: #f2dede;
    color: #b94a48;
  }

  &.is-shown {
    bottom: 0;
    opacity: 1;
    visibility: visible;
  }

  &-close {
    $size: 36px;

    cursor: pointer;
    display: block;
    font-size: $size;
    line-height: $size;
    margin-top: -18px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    width: $size;
    transition: 0.12s;
    backface-visibility: hidden;

    &::before {
      content: "×";
      display: inherit;
    }

    &:hover {
      transform: scale(1.2, 1.2);
    }
  }
}
