.base-checkbox {
  &.is-inline {
    vertical-align: top;
  }

  &.is-empty {
    input[type="checkbox"] {
      ~ label {
        padding-left: 23px;
      }
    }
  }

  & input[type="checkbox"]:checked ~ label.is-indeterminate,
  & input[type="checkbox"]:not(:checked) ~ label.is-indeterminate,
  &.is-indeterminate input[type="checkbox"]:checked ~ label,
  &.is-indeterminate input[type="checkbox"]:not(:checked) ~ label {
    &::before {
      background: #f9f0cd;
      border-color: #c8bd8e;
      box-shadow: inset 0 2px 2px 0 rgb(110 92 19 / 18%);
    }

    &::after {
      opacity: 1;
      transform: none;
      height: 4px;
      background: #52440c;
      top: 50%;
      margin-top: -2px;
    }
  }

  &.is-dark input[type="checkbox"]:checked ~ label,
  &.is-dark input[type="checkbox"]:not(:checked) ~ label,
  &.is-dark input[type="checkbox"]:disabled:checked ~ label,
  &.is-dark input[type="checkbox"]:disabled:not(:checked) ~ label {
    margin-bottom: 0;
    color: #fff;
    font-family: $gotham-bold;

    &::before {
      background-color: rgb(255 255 255 / 30%);
      border: 0;
      border-radius: 2px;
    }
  }

  &.is-dark input[type="checkbox"] ~ label,
  &.is-dark input[type="checkbox"]:disabled:checked ~ label {
    &::after {
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
      background:
        url("~application_v2/icon-checked-white-24x24.png") 0 0
        no-repeat;
    }
  }

  input[type="checkbox"] {
    position: absolute;
    left: -9999px;

    ~ label {
      position: relative;
      padding-left: 27px;
      cursor: pointer;
      font-family: $gotham-book;
      font-size: 16px;
      color: #333;

      &.is-large {
        padding-left: 40px;
        font-size: 20px;
        line-height: 24px;
      }

      &.is-bold {
        font-family: $gotham-bold;
      }

      a {
        font-size: 14px;
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 18px;
        height: 18px;
        border: 1px solid #ccc;
        background: #fff;
        box-shadow: inset 0 1px 2px 0 rgb(0 0 0 / 10%);
      }

      &.is-large::before {
        width: 24px;
        height: 24px;
      }

      &::after {
        content: "";
        position: absolute;
        top: 4px;
        left: 3px;
        background:
          url("~application_v2/icon-checked-black-14x11.png") 0 0
          no-repeat;
        width: 14px;
        height: 11px;
        transition: all 0.2s, background 1ms;
      }
    }

    /* checked mark aspect changes */
    &:not(:checked) ~ label::after {
      opacity: 0;
      transform: scale(0);
    }

    &:hover {
      ~ label::before {
        border-color: #bbb;
      }
    }

    &:checked {
      ~ label::after {
        opacity: 1;
        transform: scale(1);
      }

      ~ label::before {
        background: #f9f0cd;
        border-color: #c8bd8e;
        box-shadow: inset 0 2px 2px 0 rgb(110 92 19 / 18%);
      }

      &:hover {
        ~ label::before {
          border-color: #b19c44;
        }
      }
    }

    // disabled
    &:disabled:not(:checked) ~ label::before,
    &:disabled:checked ~ label::before {
      border-color: #e6e6e6;
      background-color: #f8f8f8;
    }

    &:disabled:checked ~ label::after {
      color: #999;
      background-image: url("~application_v2/icon-checked-disabled-14x11.png");
    }

    &:disabled ~ label {
      color: #aaa;
    }

    &.validation-error ~ label::before {
      border-color: #ff4e4e !important;
    }
  }
}

@media only screen and (min-resolution: 2dppx),
  only screen and (min-resolution: 144dpi) {
  .base-checkbox {
    input[type="checkbox"] {
      ~ label {
        &::after {
          background-image: url("~application_v2/icon-checked-black-14x11@2x.png");
          background-size: 100% 100%;
        }
      }

      &:disabled:checked ~ label::after {
        background-image: url("~application_v2/icon-checked-disabled-14x11@2x.png");
        background-size: 100% 100%;
      }
    }
  }
}
