$self: ".info-sidebar";

@mixin gradient-to($dir) {
  background-image:
    linear-gradient(
      to $dir,
      rgba(#fff, 0) 0%,
      rgba(#fff, 1) 100%
    );
}

@mixin shadow($shadow-h: 20px, $pos: "top", $type: "inner") {
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    height: $shadow-h;
    z-index: 1;

    @if $pos == "bottom" {
      @if $type == "inner" {
        bottom: 0;

        @include gradient-to(bottom);
      } @else if $type == "outer" {
        top: 100%;

        @include gradient-to(top);
      }
    } @else if $pos == "top" {
      @if $type == "inner" {
        top: 0;

        @include gradient-to(top);
      } @else if $type == "outer" {
        bottom: 100%;

        @include gradient-to(bottom);
      }
    }
  }
}

#{$self} {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  &-bar {
    @include shadow(20px, "bottom", "inner");
  }

  &-bar-header {
    @include shadow(20px, "bottom", "outer");
  }

  &-bar-main {
    &-sub-title {
      font-size: 18px;
      line-height: 1;
      font-family: $gotham-bold;
      margin-bottom: 15px;
      margin-top: 30px;

      &.is-first {
        margin-top: 0;
      }
    }
  }
}
