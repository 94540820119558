// A/B Test stats table, with actions (with Datagrid support)
// ----------------------------------------------------------
$self: ".abtest-table";

@mixin style($background: #fff, $text: #ccc) {
  & td,
  &:hover td {
    background-color: $background;
    box-shadow: -3px 0 0 -1px $background;

    &:first-child {
      box-shadow: none;
    }
  }
}

#{$self} {
  margin-bottom: 0;

  tr {
    &:hover {
      td {
        background-color: #fff;
      }
    }
  }

  &-variant {
    &.is-winner {
      @include style(#f6fcea, #49a147);
    }

    &-color {
      height: 45px;
    }
  }

  &-winner {
    padding: 5px 0;

    &-label {
      color: #3ea050;
      font-family: $gotham-bold;
      line-height: 1;
    }

    &-tip {
      color: #999;
      font-size: 12px;
    }
  }

  &-metric {
    &.is-best {
      color: #3ea050;
      font-family: $gotham-bold;
    }

    &.is-worst {
      color: #ce0000;
    }
  }

  // Datagrid columns
  // ----------------
  th,
  td {
    &.datagrid-literal {
      width: 70px;
    }

    &.datagrid-value {
      width: 240px;

      span {
        word-break: break-all;
      }

      &.is-1of3,
      &.is-1of4,
      &.is-1of5 {
        width: 50%;
      }

      &.is-1of6,
      &.is-1of7 {
        width: 40%;
      }
    }

    &.datagrid-media {
      width: 100px;
    }

    &.datagrid-actions {
      width: 195px;
    }
  }

  // Overrides
  // ---------
  td {
    &.is-focused {
      z-index: 5;
    }

    &:not(.datagrid-actions):not(.datagrid-literal):not(.datagrid-media) {
      padding-bottom: 13px;
      padding-top: 13px;
    }

    &.datagrid-literal {
      padding-bottom: 0;

      .abtest-char {
        margin: auto;
      }
    }

    &.datagrid-value {
      .sign-svg.is-no-gap {
        margin-right: 0;
      }
    }

    &.datagrid-media {
      img {
        max-height: 50px;
        max-width: 100px;
      }
    }

    &.datagrid-actions {
      .base-btn-group {
        display: flex;
        justify-content: space-between;

        .base-btn.is-flex {
          flex-grow: 1;
        }
      }
    }
  }

  &.disabled {
    display: none;
  }
}

.table-loader {
  height: 100px;
  display: flex;
  align-items: center;
}

.table-loader-disabled {
  display: none !important;
}
