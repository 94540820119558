.Payment {
  &-payment-form {
    width: 400px;

    input.ng-invalid.ng-touched {
      border: 1px solid #fa787e;
    }

    .error-messages {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    .card-type {
      vertical-align: middle;
      cursor: default;
      padding: 4px;
      font-size: 1.5em;
      line-height: 1;
      background: transparent;
      border: 1px solid #999;
      border-radius: 4px;
      color: #999;
    }

    .card-icon {
      font-size: 18px;
      vertical-align: middle;
    }

    .default {
      color: #999;
      cursor: default;
      text-decoration: none;
    }

    .make-default {
      color: #999;
      cursor: pointer !important;
      text-decoration: none;
      border-bottom: 1px #999 dashed;

      &:hover {
        border-bottom: none;
      }
    }

    .and-or {
      height: 2px;
      background-color: #eee;
      text-align: center;
      margin: 20px 0;

      span {
        padding: 0 5px;
        background-color: white;
        font-weight: bold;
        position: relative;
        top: -0.6em;
      }
    }

    .payment-options {
      padding-left: 20px;
      text-align: center;
    }

    fieldset {
      border: 1px solid #ccc;
      border-radius: 5px;
      margin-bottom: 20px;

      label {
        margin-left: 10px;
      }

      legend {
        margin: 0 0 5px 10px;
        padding: 0 3px;
        font-size: 14px;
        line-height: 1;
        border-bottom: none;
        width: auto;
      }
    }
  }
}
