.base-btn-group {
  display: inline-flex;
  align-items: stretch;
  vertical-align: middle;
  border-left: 1px solid #e5e5e5;
  padding: 9px;
  position: relative;

  &.is-styled {
    .base-btn {
      border-radius: 0;
      border-left-width: 0;

      &:first-child,
      &.is-first {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-left-width: 1px;
      }

      &:last-child,
      &.is-last {
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
      }
    }
  }

  &.pan {
    padding: 0;
  }

  &:first-child {
    border-left: none;
    padding-left: 0;
    margin-left: 0;
  }

  &.is-last {
    border-right: 1px solid #e5e5e5;
  }

  &.is-nostyle {
    border: none;
    padding: 0;
  }

  &.is-full {
    padding: 0;
  }

  &.is-full-width {
    display: flex;
    justify-content: space-between;
    padding: 0;
    width: 100%;

    &.is-right {
      justify-content: flex-end;
    }

    .base-btn {
      &:not(.is-more):not(.is-stacked) {
        width: 100%;
      }
    }
  }

  &-container {
    display: flex;
    font-size: 0;
    position: relative;
  }

  .base-btn {
    &.is-stacked {
      margin: 0 !important;
    }
  }
}
