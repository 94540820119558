.description {
  margin-top: 70px;
  margin-bottom: 110px;

  @include mq-max-width(768px) {
    margin: 20px 0;
  }

  &-title {
    font-size: 36px;
    line-height: 1.2;
    color: #3d3d3d;
    font-family: $gotham-bold;
    font-weight: normal;
    text-align: center;
    margin-bottom: 70px;

    @include mq-max-width(767px) {
      margin-bottom: 35px;
    }

    @include mq-max-width(480px) {
      font-size: 28px;
      margin-bottom: 35px;

      br {
        display: none;
      }
    }
  }

  &-img {
    min-height: 65px;
    margin-bottom: 5px;
  }

  strong {
    font-family: $gotham-bold;
    font-weight: normal;
    font-size: 22px;
  }

  p {
    font-size: 16px;
    line-height: 1.6;
  }

  @include mq-max-width(1023px) {
    .row .col1of4 {
      width: 50%;
      margin-bottom: 20px;

      &:nth-child(odd) {
        clear: both;
      }
    }
  }

  @include mq-max-width(560px) {
    .row .col1of4 {
      width: 100%;
    }
  }
}
