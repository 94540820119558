.designed-layout {
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(#000, 0.1);
  box-sizing: border-box;
  margin: 0 auto 30px;
  padding: 20px;
  position: relative;
  transition: 0.2s;

  &.is-with-border {
    border: 1px solid #e5e5e5;
  }

  &.is-without-radius {
    border-radius: 0;
  }

  &.is-flex {
    display: flex;
  }

  &-head {
    padding: 30px 0 35px;
    text-align: center;

    h1 {
      color: #333;
      font-family: $gotham-bold;
      font-size: 46px;
      margin-bottom: 15px;
      text-transform: uppercase;
    }

    p {
      font-size: 24px;
    }
  }

  p {
    &.is-zero-margin {
      margin: 0;
    }

    &.is-middle-font-size {
      font-size: 18px;
    }
  }

  &-section {
    border-bottom: 1px solid #e5e5e5;
    padding: 25px 30px;

    &-split:not(:last-child) {
      margin-bottom: 18px;

      .chosen-container:not(:last-child) {
        margin-bottom: 18px;
      }
    }

    .subtitle {
      color: #333;
      font-family: $gotham-medium;
      font-size: 16px;
      margin-bottom: 10px;
    }

    &.is-border-none {
      border: none;
    }

    &.is-center {
      text-align: center;
    }
  }

  &-footer {
    border-radius: 0 0 12px 12px;
    display: flex;
    justify-content: space-between;
    padding: 20px 30px;

    &.is-center {
      justify-content: center;
    }

    &.is-gray {
      background: #fafafa;
    }

    button {
      margin-left: 20px;

      span {
        font-size: 16px;
      }
    }

    .link {
      font-size: 16px;
      margin-bottom: auto;
      margin-top: auto;
    }

    &-grid {
      display: flex;
    }
  }

  h4 {
    color: #333;
    font-family: $gotham-bold;
    font-size: 18px;
    margin-bottom: 10px;
  }
}
