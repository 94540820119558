// CSS reset
* {
  padding: 0;
  margin: 0;
  font: inherit;
  outline: none;
}

a img,
iframe {
  border: none;
}

ul {
  list-style-type: none;
}

th,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

cite,
em {
  font-style: normal;
}

// Prevent `sub` and `sup` affecting `line-height` in all browsers.
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

button,
input,
select,
textarea {
  vertical-align: middle;
}

button,
input {
  *overflow: visible;
  line-height: normal;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  appearance: button;
}

.hidden {
  display: none;
}
