.preloader {
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: solid 5px #eee;
  border-top-color: #55a1ff;
  animation: spin 1s infinite linear;
}

@include keyframes(spin) {
  100% {
    transform: rotate(360deg);
  }
}
