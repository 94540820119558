.tribute-container {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    margin-top: 18px;
    background: white;
    color: black;
    border: 1px solid #DDD;
    border-radius: 3px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    min-width: 120px;
    z-index: 11110 !important;
    max-height: 200px;
    overflow-y: auto;
}

.tribute-container .highlight {
    background: #3366FF;
    color: white;
}

.tribute-container ul {
    list-style: none;
    padding: 0;
    margin: auto;
}

.tribute-container ul li {
    display: block;
    padding: 5px 10px;
    border-bottom: 1px solid #DDD;
    cursor: pointer;

}
