.campaign-type {
  display: flex;
  align-items: center;
  font-family: $gotham-bold;
  font-size: 18px;
  justify-content: space-around;
  text-transform: uppercase;
  line-height: 1;
  color: #fff;
  width: 100%;
  box-sizing: border-box;
  border-radius: 6px;
  height: 44px;
  padding: 0 8px;
  background: #bbb;

  &.is-medium {
    width: 55px;
  }

  &.is-mini {
    font-family: $gotham-medium;
    font-size: 14px;
    line-height: 1;
    height: 26px;
    padding: 0 5px;

    .icon,
    .sign-svg {
      margin-right: 4px;
    }

    .icon-type-tiered {
      width: 14px;
      height: 14px;
    }

    .icon-type-dashboard {
      width: 16px;
      height: 14px;
    }

    .icon-type-invite {
      width: 16px;
      height: 16px;
    }

    .icon-type-leaderboard {
      width: 16px;
      height: 14px;
    }

    .icon-type-widget {
      width: 14px;
      height: 14px;
    }

    .icon-type-gleam {
      width: 16px;
      height: 12px;
    }
  }

  &.is-SA {
    background: #ffac0b;
  }

  &.is-PP {
    background: #9a73ce;
  }

  &.is-FW {
    background: #ff684a;
  }

  &.is-RG {
    background: #3ac7fc;
  }

  &.is-PE {
    background: #70c880;
  }

  .icon {
    background: none;
    margin-top: 0;
    margin-right: 10px;
  }

  .icon-type-tiered {
    width: 28px;
    height: 28px;
  }

  .icon-type-dashboard {
    width: 32px;
    height: 28px;
  }

  .icon-type-invite {
    width: 32px;
    height: 32px;
  }

  .icon-type-leaderboard {
    width: 32px;
    height: 28px;
  }

  .icon-type-widget {
    width: 28px;
    height: 28px;
  }

  .icon-type-gleam {
    width: 32px;
    height: 24px;
  }
}
