.header {
  .logo {
    margin: 0;

    a {
      float: left;
      width: 112px;
      margin: 14px 0;
      height: 25px;
      line-height: 25px;

      .sign-svg-logo {
        margin: 0;
        vertical-align: top;
      }

      .is-development & {
        filter: grayscale(100%);
      }

      @include print {
        margin: 0;
      }
    }
  }

  .navigation {
    float: right;
    padding: 0;
    margin: 0;
    font-family: $gotham-book;
    line-height: 57px;

    @include mq-max-width($width-3of7) {
      float: none;
      margin-bottom: 10px;
    }

    > li {
      display: inline-block;
      margin: 0 15px;

      & > a {
        display: block;
        font-family: $gotham-bold;
        font-size: 14px;
        font-weight: normal;
        color: #666;
        padding: 15px 0;
        line-height: 23px;
        text-decoration: none;
        transition: 0.3s;

        &:hover {
          color: #f60;
        }
      }
    }
  }
}
