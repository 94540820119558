.base-text-label {
  display: inline-block;
  font-size: 14px;
  line-height: 1.2;
  vertical-align: middle;
  text-align: center;

  &.is-short {
    margin-bottom: 5px;
  }

  mark {
    background: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    color: #999;
    display: block;
    font-family: $gotham-medium;
    padding: 5px;
  }

  &-helper {
    font: normal 12px $gotham-book;
    color: #999;
    display: block;
    line-height: 1;
    padding-left: 7px;

    &.is-center {
      padding-left: 0;
      text-align: center;
    }
  }

  &.is-text-left {
    text-align: left;
  }

  &.is-text {
    mark {
      display: block;
      margin-top: 5px;
    }
  }

  &.is-success {
    mark {
      color: #429f53;
      border-color: #ecf5ed;
      background: #ecf5ed;
    }
  }

  &.is-error {
    mark {
      border-color: #ffe2cc;
      color: #ff5400;
    }
  }

  &.is-warning,
  &.is-warning span {
    color: #da9f12;

    &.is-short mark {
      padding: 5px 1px 5px 6px;
    }

    mark {
      border-color: #f8ecd0;
      color: #da9f12;
    }
  }
}
