// Interpolation variables list
// ----------------------------
.ivars-list {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    margin-bottom: 10px;
  }

  pre {
    word-wrap: break-word;
    white-space: pre-wrap;
    border: 0;
    background: inherit;
    padding: 0;
    font-family: inherit;
    font-size: 100%;
  }
}

.ivars-title {
  border-bottom: 1px solid #f2f1f1;
  margin-bottom: 9px;
  padding-bottom: 9px;
}

.ivar-value {
  color: #a77540;
}

.ivar-options {
  font-style: italic;
}
