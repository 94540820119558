.base-modal {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(#000, 0.7);
  overflow: auto;

  &.is-flex {
    justify-content: center;
    align-items: center;
  }

  .open &,
  &.is-shown {
    display: block;

    &.is-flex {
      display: flex;
    }
  }

  &.is-absolute {
    position: absolute;
  }

  &.is-lighten {
    background-color: rgba(#000, 0.6);
  }

  // This modifier makes it function without any JS at all
  // :target does all the magics. It means that a current URL anchor is in 'focus'
  //
  // Example:
  // .base-modal.is-flex.is-css#example{'area-hidden': 'true'} <-- notice ID and 'area-hidden' attrs
  //   %a.base-modal-hitarea{'area-hidden': 'true', href: '#'} <-- this is a clickable overlay
  &.is-css {
    display: flex;
    opacity: 0;
    pointer-events: none;
    transition: 0.2s;

    &:target {
      opacity: 1;
      pointer-events: all;

      .base-modal-container {
        transform: scale(1, 1);
      }
    }

    .base-modal {
      &-container {
        transform: scale(0.95, 0.75);
        transition: 0.2s;
        z-index: 2;
      }
    }
  }

  &-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    position: relative;

    .base-modal-close {
      top: 10px;
      right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 0;
      text-decoration: none;
    }
  }

  &-close {
    box-sizing: border-box;
    opacity: 0.7;
    transition: 0.3s;
    backface-visibility: hidden;
    display: block;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 25px;
    line-height: 25px;
    text-align: center;
    color: #8c8c8c;
    border: 2px solid #474747;

    &.is-lighten {
      color: #999;
      border-color: #e5e5e5;
    }

    &:hover {
      opacity: 1;
    }
  }

  &-wrapper {
    width: 800px;
    background: #fff;
    box-shadow: 0 5px 30px 0 rgb(0 0 0 / 70%);
    border-radius: 15px;
    max-width: none;
    box-sizing: border-box;
    position: relative;

    &.is-limited {
      max-height: 480px;
      overflow: auto;
    }

    &.is-small {
      width: 200px;
    }

    &.is-middle {
      width: 400px;
    }

    &.is-large {
      width: 640px;
    }
  }

  &-column {
    margin-top: 150px;
    margin-left: 140px;
    max-width: 350px;

    &:first-child {
      margin-left: 0;
    }

    &-title {
      font-size: 14px;
      font-family: $gotham-medium;
      color: #bbb;
      margin-bottom: 40px;
      text-transform: uppercase;
    }
  }

  &-list {
    li {
      margin-top: 25px;

      &:first-child {
        margin-top: 0;
      }
    }

    a {
      font-family: $gotham-medium;
      font-size: 20px;
      line-height: 1;
      color: #fff;

      &:hover {
        text-decoration: none;
        color: #2a98ff;
      }
    }
  }

  &-hitarea {
    display: block;
    cursor: default;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
  }
}
