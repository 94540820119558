// A/B test variant character (aka A, B)
// ------------------------------------
$self: ".abtest-char";

#{$self} {
  $color: #2dc1ff;
  $size: 24px;

  align-items: center;
  background-color: $color;
  border-radius: 50%;
  color: #fff;
  cursor: default;
  display: flex;
  font-family: $gotham-medium;
  font-size: 1.02em;
  justify-content: center;
  height: $size;
  line-height: 1.02em;
  margin-right: 10px;
  text-align: center;
  text-indent: 0.05em;
  vertical-align: middle;
  width: $size;

  &.is-winner {
    background-color: #6dc97d;
  }

  &.is-loser {
    background-color: #ccc;
  }

  &-ratio {
    color: #999;
    font-size: 12px;
    padding: 5px 0;
    text-align: center;
  }

  &.is-inline {
    display: inline-flex;
    margin: -1px 0;
  }
}
