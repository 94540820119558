.App {
  .view {
    width: 46.7%;
    height: 165px;
    margin-left: 6%;
    margin-bottom: 40px;
    vertical-align: top;
    background: #fff;
    border-radius: 10px;
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    box-shadow: 1px 2px 5px 0 rgb(0 0 0 / 15%);

    &.is-selected {
      .view {
        &-wrap {
          display: block;
        }

        &-inner {
          display: block;
          box-sizing: border-box;
          padding: 15px;
        }
      }
    }

    &.is-disabled {
      background-color: #f9f9f9;
      box-shadow: none;

      &::before {
        border-radius: 4px 4px 0 0;
        color: #fff;
        content: "Disabled";
        background-color: #d24141;
        font-size: 12px;
        left: 13px;
        padding: 0 5px;
        position: absolute;
        top: -18px;
      }

      .view {
        &-title {
          &-link {
            color: #888;

            &::before {
              border-top-color: #888;
            }
          }

          svg {
            fill: #888;
          }
        }
      }
    }

    &.is-choice {
      text-align: center;
      border: 1px dashed #dadada;
      box-shadow: none;
    }

    &:nth-child(odd) {
      margin-left: 0;
    }

    &-wrap {
      position: relative;
      display: table;
      width: 100%;
      height: 100%;
    }

    &-inner {
      display: table-cell;
      vertical-align: middle;
    }

    &-popup {
      text-align: left;
      display: none;
      position: absolute;
      background: #fff;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      border-radius: 10px;
      padding: 17px 17px 5px;
      box-sizing: border-box;
      box-shadow: 2.5px 4px 25px 0 rgba(#000, 0.4);

      &-close {
        position: absolute;
        top: 45px;
        right: 28px;
        width: 17px;
        height: 17px;
        line-height: 17px;
        vertical-align: middle;
        display: inline-block;
        background: url("~application_v2/ico-cross.svg") no-repeat center center;
        background-size: contain;
        z-index: 100;
        cursor: pointer;
      }
    }

    &-title {
      color: #292e33;
      cursor: pointer;
      font: normal 14px / 1.2 $gotham-medium;
      margin-bottom: 5px;
      max-width: 100%;
      padding-right: 30px;
      position: relative;
      white-space: nowrap;
      display: inline-block;
      box-sizing: border-box;

      &:hover {
        .view-title-link {
          text-decoration: underline;
        }
      }

      svg {
        display: inline-block;
        vertical-align: middle;
        margin-top: -2px;
      }

      &-link {
        max-width: 95%;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
        white-space: nowrap;
        display: inline-block;

        &::before {
          border-style: solid;
          border-width: 5px 4px 0;
          border-color: #292e33 transparent transparent;
          content: "";
          height: 0;
          position: absolute;
          right: 16px;
          top: 7px;
          width: 0;
        }
      }
    }

    &-delete {
      border: 2px solid rgba(#000, 0.1);
      border-radius: 50%;
      content: "";
      display: block;
      position: absolute;
      right: 3px;
      top: 3px;
      width: 21px;
      height: 21px;
      line-height: 9px;
      background: url("~application_v2/ico-cross.svg") no-repeat center center;
      background-size: 11px;

      &:hover {
        border-color: rgba(#000, 0.2);
      }

      &:active {
        border-color: rgba(#000, 0.1);
        background-color: rgba(#000, 0.1);
      }
    }

    &-text {
      height: 67px;
      margin-bottom: 6px;
    }

    p {
      font-family: $gotham-book;
      font-weight: normal;
      font-size: 12px;
      line-height: 1.3;
      color: #999;
      margin-bottom: 3px;

      &.is-mb {
        margin-bottom: 10px;
      }

      &.is-long {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &-list {
      list-style-type: none;
      margin-left: -10px;

      @include clearfix;

      li {
        float: left;
        padding-left: 10px;
        width: 33.33%;
        box-sizing: border-box;

        .base-btn {
          margin-bottom: 0;
        }
      }
    }

    .base-btn {
      margin-bottom: 9px;
    }
  }
}
