body {
  .clearboth {
    clear: both;
  }

  .inline {
    display: inline-block;
  }

  .hidden {
    display: none;
  }
}

a {
  color: #426e8e;
}

h6 {
  font-weight: inherit;
  text-transform: uppercase;
}

.example,
.ex {
  display: block;
  color: #999;
  line-height: 18px;
  margin-bottom: 4px;
  margin-top: -5px;

  &.is-inline {
    display: inline-block;
    margin-top: 0;
    vertical-align: middle;
  }

  &.is-m0 {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Loading spinner */
#loadingDiv,
#noticeDiv {
  position: fixed;
  left: 50%;
  top: 30%;
  width: 170px;
  z-index: 999999;

  &.is-fixed {
    width: auto;
    top: 50%;
    position: fixed;

    #loadingDivInner,
    #noticeDivInner {
      top: -50%;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  #loadingDivInner,
  #noticeDivInner {
    position: relative;
    left: -50%;
    background-color: rgb(255 255 255 / 80%);
    padding: 10px;
    border: 1px solid rgb(0 0 0 / 20%);
    border-radius: 15px;
    font-size: 20px;
    box-shadow: 1px 1px 5px 5px rgb(0 0 0 / 6%);

    img {
      margin-left: 4px;
      float: left;
    }

    .loadingMessage,
    .noticeMessage {
      float: left;
      padding: 3px;
      margin-left: 10px;
    }
  }

  #noticeDivInner {
    font-size: 14px;
    background-color: rgb(216 237 206 / 70%);
    color: #3d7834;
  }
}

.taright {
  text-align: right;
}

.is-static {
  position: static;
}

.link-with-icon {
  transition: 0.3s;
  vertical-align: baseline;
  color: #0070d9;
  font-weight: 400;
  font-size: 14px;
  font-family: "gotham-htf-medium", Arial, sans-serif;

  path {
    fill: #0070d9;
  }

  &:hover {
    text-decoration: none;
    color: #ff4e00;

    path {
      fill: #ff4e00;
    }
  }

  &:not(:last-child) {
    margin-right: 16px;
    padding-right: 16px;
    position: relative;

    &::after {
      background: #ddd;
      bottom: 3px;
      content: "";
      position: absolute;
      right: 0;
      top: 3px;
      width: 1px;
    }
  }
}
