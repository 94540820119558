.preview {
  &-list {
    &-presets {
      position: relative;
      margin-left: 40px;
      padding-top: 8px;
      margin-bottom: 20px;
      border-bottom: 1px solid #e5e5e5;

      &.is-last {
        border-bottom: none;
      }

      &-type {
        position: absolute;
        left: -39px;
        top: 0;
      }

      &-line {
        position: absolute;
        top: 1px;
        bottom: 0;
        left: -24px;
        width: 2px;
        margin-bottom: -25px;
      }

      .preview-list-preset {
        display: inline-block;
        vertical-align: top;
        margin-right: 20px;
        margin-bottom: 24px;
        width: 230px;
      }
    }
  }
}
