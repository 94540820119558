// Links and their modifiers: plain link, link with icon
// -----------------------------------------------------
$self: ".link";

@mixin color($color, $opacity) {
  & {
    color: $color;

    #{$self}-icon {
      border-color: rgba($color, $opacity);
    }

    .sign-svg .sign-svg {
      &-stroke {
        stroke: $color;
      }

      &-fill,
      i {
        fill: $color;
      }

      &-canvas-stroke {
        stroke: $color;
        opacity: $opacity;
      }

      &-canvas-fill,
      &-border {
        fill: $color;
        opacity: $opacity;
      }
    }

    .icon {
      border-color: $color;

      &::before,
      &::after {
        background-color: $color;
      }
    }
  }
}

@mixin style($initial, $hover: null) {
  & {
    @include color($initial, 0.3);
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;

    @if $hover {
      @include color($hover, 1);
    }
  }
}

#{$self} {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  font-family: $gotham-medium;
  font-size: 14px;
  transition: 0.3s;
  vertical-align: baseline;

  @include style($link-color, $firm);

  &.is-medium {
    font-size: 16px;
  }

  &.is-negative {
    @include style(#e00000, #ce0000);
  }

  &.is-inherited {
    font-size: inherit;
  }

  &.is-icon {
    margin-bottom: -12px;
    margin-top: -14px;
    vertical-align: middle;
  }

  &.is-text {
    display: inline;

    & + #{$self}.is-icon {
      margin-right: -31px;
    }
  }

  &-icon {
    border: 2px solid;
    border-radius: 50%;
    display: flex;
    height: 20px;
    position: relative;
    margin-right: 7px;
    transition: 0.3s;
    vertical-align: middle;
    width: 20px;

    .sign-svg,
    i {
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
    }
  }

  &.is-disabled {
    @include style(rgba(#000, 0.2));

    & {
      cursor: not-allowed;
      pointer-events: none;
    }

    &.is-pointable {
      pointer-events: all;
    }

    .icon.icon-mini-preview {
      background-image: url("~application_v2/layouts/routes/icon-mini-preview-disabled.png");
      border-color: rgba(#000, 0.1);
    }
  }

  &.is-right {
    flex-direction: row-reverse;
    padding-left: 10px;

    .icon,
    i {
      margin-left: 7px;
      margin-right: 0;
    }
  }

  .icon,
  i {
    margin: auto 7px auto 0;
  }

  span {
    margin: auto;
  }
}
