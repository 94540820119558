$self: ".Locale-entries";

#{$self} {
  position: relative;

  .base-input {
    border-radius: 0;
  }

  .base-textarea {
    font-size: 14px;
    padding: 9px 12px;

    &[disabled] {
      color: #333;
    }
  }

  .form-textfield {
    padding: 11px;
    border: 1px solid #ccc;
    color: $base;
    font-family: $gotham-book;
    font-size: 16px;
    border-radius: 0;
    margin: 0;
  }

  .form-validation {
    color: #e9322d;
  }

  .tooltip {
    font-family: $gotham-book;
  }

  .icon {
    position: relative;
    vertical-align: middle;

    &-settings {
      opacity: 1;
      width: 20px;
      height: 18px;
      background: url("~application_v2/icon-settings.png") 0 0 no-repeat;

      @include retina {
        background-image: url("~application_v2/icon-settings@2x.png");
        background-size: 20px 18px;
      }
    }

    &-analytics {
      width: 20px;
      height: 16px;
      background: url("~application_v2/icon-analytics.png") 0 0 no-repeat;

      @include retina {
        background-image: url("~application_v2/icon-analytics@2x.png");
        background-size: 20px 16px;
      }
    }

    &-broken {
      width: 18px;
      height: 18px;
      background: url("~application_v2/icon-broken-18x18.png") 0 0 no-repeat;

      @include retina {
        background-image: url("~application_v2/icon-broken-18x18@2x.png");
        background-size: 18px 18px;
      }
    }

    &-select {
      width: 20px;
      height: 15px;
      background: url("~application_v2/icon-select-20x15.png") 0 0 no-repeat;

      @include retina {
        background-image: url("~application_v2/icon-select-20x15@2x.png");
        background-size: 20px 15px;
      }
    }

    &-preview {
      width: 24px;
      height: 16px;
      background: url("~application_v2/icon-preview-24x16.png") 0 0 no-repeat;
      top: -3px;

      @include retina {
        background-image: url("~application_v2/icon-preview-24x16@2x.png");
        background-size: 24px 16px;
      }
    }
  }

  .editor-presets-status {
    position: absolute;
  }

  .App-container-inner {
    &.is-scrollable {
      &::after {
        content: "";
        display: block;
        height: 1px;
        position: absolute;
        right: -405px;
        width: 380px;
      }
    }
  }

  // ×
  &-button-close {
    color: #999;
    cursor: pointer;
    font-size: 26px;
    position: absolute;
    width: 27px;
    height: 27px;
    line-height: 27px;
    right: 0;
    top: 0;
    text-align: center;
    border-radius: 50%;
    border: 2px solid #e5e5e5;
  }

  @include keyframes(blinking) {
    from {
      background-color: tranparent;
    }

    50% {
      background-color: #fbf6e4;
    }

    to {
      background-color: transparent;
    }
  }

  /* @LocaleEntriesDashboard
   */

  &-filters {
    @include clearfix;

    div.is-link {
      font-family: $gotham-medium;
      font-size: 14px;
      line-height: 30px;

      .icon-variables {
        position: relative;
        top: -2px;
        vertical-align: middle;
        margin-right: 7px;
      }
    }

    .span3 {
      display: inline-block;
      width: auto;
      float: none;
      margin-left: 10px;
      vertical-align: middle;

      &:last-child {
        margin-left: 20px;
      }
    }

    .chosen-container-single {
      // I'm extremely sorry about that, but I have no choice, because of "Chosen"
      width: auto !important;

      .chosen-single {
        padding-bottom: 10px;
        padding-top: 10px;
        height: 40px;
        font-family: $gotham-medium;

        div {
          top: 2px;
          right: 15px;
          width: 14px;

          b {
            background-position: 100% -54px;
          }
        }
      }

      &.chosen-with-drop {
        .chosen-single {
          div {
            b {
              background-position: 100% -54px;
            }
          }
        }

        .chosen-drop {
          width: auto;

          .chosen-results {
            li {
              max-width: 500px;
              min-width: 200px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }

  &-preview-iframe {
    position: relative;
    margin-top: 2px;
    overflow: auto;

    body.is-resizing & {
      iframe {
        pointer-events: none;
      }
    }

    .iframe-container {
      &.is-loading {
        iframe {
          visibility: hidden;
          position: fixed;
          top: auto;
          left: auto;
        }
      }

      &.is-loaded {
        iframe {
          visibility: visible;
          position: relative;
        }
      }
    }
  }

  .preloader {
    width: 48px;
    height: 48px;
    border-width: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -24px;
    margin-left: -24px;
  }

  /* @LocalizationsSidebar
   */

  &-sidebar {
    box-sizing: border-box;
    pointer-events: all;
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 100;
    background: #fafafa;
    box-shadow: 0 0 20px 1px rgba(#000, 0.1);
    transition: 0.5s;

    &.is-collapsed {
      transform: translate(100%, 0);
    }

    &.is-cut &-localizations-wrapper {
      overflow: hidden;
    }

    &.is-collapsed &-toggle {
      &::after {
        background-image: url("~application_v2/icon-arrow-left-right.png");
        background-position: 0 0;

        @include retina {
          background-image: url("~application_v2/icon-arrow-left-right@2x.png");
        }
      }
    }

    /* @LocalizationsSidebar
     * Sidebar / Localizations
     */

    &-localizations {
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      background: #fafafa;
      z-index: 100;

      &-wrapper {
        position: absolute;
        left: 0;
        right: 0;
        top: 173px;
        bottom: 0;
        transition: opacity 0.5s;

        .editor-panels.is-disabled & {
          opacity: 0.4;
          pointer-events: none;
        }
      }

      &-footer {
        box-sizing: border-box;
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 15px;
        background: #fff;
        border-top: 1px solid #fafafa;
      }
    }

    /* @LocalizationsSidebar
     * Sidebar / Variables
     * @LiquidInterpolationsList Holder
     */

    &-variables {
      position: absolute;
      width: 380px;
      height: 100%;
      top: 0;
      right: 0;
      background: #fafafa;
      box-shadow: inset 7px 0 20px -7px rgb(0 0 0 / 20%);
      z-index: 99;

      &-wrapper {
        box-sizing: border-box;
        position: absolute;
        overflow: auto;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        padding: 25px 40px;
      }

      #{$self}-button-close {
        top: 10px;
        right: 20px;
        z-index: 100;
      }
    }

    /* LocalizationsSidebar
     * Search form
     */

    &-header {
      box-sizing: border-box;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 15px 15px 0;
      background: #fff;
      border-bottom: 1px solid #e5e5e5;

      .base-input {
        margin-bottom: 0;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 75px;
      }
    }

    &-search {
      &-form {
        &-divider {
          display: inline-block;
          position: relative;
          top: -1px;
          background: #e5e5e5;
          height: 27px;
          width: 1px;
          vertical-align: middle;
          margin: 0 5px;
        }

        &-btn {
          display: inline-block;
          border: 0;
          outline: 0;

          &.is-reset {
            background: url("~application_v2/icon-cross-17x17.png") 0 0
              no-repeat;
            width: 17px;
            padding-top: 17px;
            height: 0;
            overflow: hidden;

            @include retina {
              background-image: url("~application_v2/icon-cross-17x17@2x.png");
              background-size: 17px 17px;
            }
          }

          &.is-submit {
            background: url("~application_v2/icon-search-19x19.png") 0 0
              no-repeat;
            width: 19px;
            padding-top: 19px;
            height: 0;
            overflow: hidden;

            @include retina {
              background-image: url("~application_v2/icon-search-19x19@2x.png");
              background-size: 19px 19px;
            }
          }
        }
      }
    }

    &-spinner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(#fff, 0.8);
      z-index: 1000;

      &-icon {
        background: #fff url("~widgets/editor/preloader.png") center center
          no-repeat;
        border-radius: 50%;
        height: 44px;
        left: 50%;
        margin: -22px 0 0 -22px;
        position: absolute;
        top: 50%;
        width: 44px;
        z-index: 1;
        animation: rotating 2s linear infinite;
      }
    }
  }

  /* Popup
   */

  &-popup {
    display: inline-block;
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    text-align: left;
    margin: 20px auto;
    position: relative;
    width: 560px;
    z-index: 3;
    vertical-align: middle;
    overflow: hidden;

    &-holder {
      height: 100%;
      position: relative;
      text-align: center;
      z-index: 999;
    }

    &-overlay {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1020;
      background-color: rgba(#000, 0.85);
      overflow: auto;
    }

    &-helper {
      display: inline-block;
      height: 100%;
      width: 0;
      vertical-align: middle;
    }

    &-title {
      color: $base;
      font-family: $gotham-bold;
      font-size: 30px;
      margin-bottom: 20px;
    }

    #{$self}-button-close {
      right: 16px;
      top: 16px;
    }

    &-footer {
      background: #f3faf3;
      margin: 0 -20px -20px;
      padding: 20px;
    }
  }

  /* @LocaleAssetsUploader
   */

  &-assets-uploader {
    $size: 204px;

    max-width: 990px;
    width: calc(100% - 80px);

    &.is-small {
      max-width: 800px;
      padding: 0;

      #{$self}-popup-title {
        margin-bottom: 0;

        &.is-no-border {
          border-bottom: none !important;
        }
      }
    }

    &-header {
      padding: 30px 30px 1px;

      p {
        font-size: 16px;
        margin-top: 20px;
      }
    }

    &-static {
      padding: 0 30px 20px;

      p {
        font-size: 16px;
        margin-bottom: 10px;
      }
    }

    &-footer {
      align-items: center;
      background: #f3faf3;
      display: flex;
      justify-content: flex-end;
      padding: 20px 30px;

      a {
        color: #0070d9;
        font-size: 16px;
        margin-right: 20px;

        &:hover {
          color: #ff4f00;
        }
      }
    }

    #{$self}-popup-title {
      border-bottom: 1px solid #e5e5e5;
      padding-bottom: 18px;

      .icon {
        &.icon-upload {
          margin-right: 5px;
          top: -1px;
        }
      }

      .preloader {
        display: inline-block;
        position: relative;
        width: 32px;
        height: 32px;
        border-width: 6px;
        top: 8px;
        margin-left: 10px;

        &.hidden {
          display: none;
        }
      }
    }

    &-button {
      display: inline-block;

      .base-btn {
        padding: 9px 8px;
        font-size: 14px;
        margin-left: 10px;
      }
    }

    &-wrapper {
      position: relative;
      height: 540px;
    }

    &-content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: auto;
    }

    &-edit-value {
      $border-radius: 6px;

      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      overflow: auto;
      background: #fff;
      z-index: 100;
      padding: 0 10;

      &-preview {
        box-sizing: border-box;
        float: left;
        width: 60%;
        height: 100%;
        padding-right: 30px;
        background: rgb(243 243 243 / 85%);
        position: relative;
        margin: 0 auto;
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;

        &.is-wide {
          width: 100%;
          border-radius: $border-radius;
        }

        &-asset-name {
          text-align: center;
          font-family: $gotham-book;
          font-size: 12px;
          color: #999;
          max-width: $size;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        img {
          position: absolute;
          margin: auto;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          max-height: 100%;
        }
      }

      &-field {
        box-sizing: border-box;
        float: right;
        width: 40%;
        height: 100%;
        padding: 30px;
        background: #fbf6e4;
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        position: relative;

        label {
          font-family: $gotham-bold;
          font-size: 18px;
          color: $base;
          margin-bottom: 10px;
        }
      }
    }

    &-list {
      @include clearfix;

      .icon-select {
        top: -2px;
      }

      &-item {
        position: relative;
        margin-bottom: 25px;
        padding: 4px 0;

        &.is-selected &-image {
          border-color: #00aeff;
        }

        &-image {
          position: relative;
          width: $size;
          height: $size;
          margin: 0 auto;
          margin-bottom: 10px;
          text-align: center;
          background: rgb(243 243 243 / 85%);
          border: 2px solid rgb(243 243 243 / 85%);

          &:hover {
            box-shadow: 0 0 8px rgb(82 168 236 / 60%);
          }

          &:hover &-tip {
            opacity: 1;
          }

          &.is-highlight {
            box-shadow: 0 0 25px rgb(251 255 0);
          }

          &-tip {
            background-color: rgba(#000, 0.4);
            box-sizing: border-box;
            color: #fff;
            cursor: pointer;
            display: block;
            font-family: $gotham-medium;
            font-size: 18px;
            height: 102px;
            left: 0;
            line-height: 102px;
            opacity: 0;
            position: relative;
            text-align: center;
            transition: 0.3s;
            z-index: 1;

            &:hover {
              background-color: rgba(#00aeff, 0.7);
            }

            &.is-1of3 {
              height: 68px;
              line-height: 68px;
            }

            .sign-svg {
              &-pencil {
                transform: translateY(-2px);
              }
            }
          }

          img {
            position: absolute;
            margin: auto;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            max-height: $size;
            z-index: 0;
          }
        }

        &-info {
          text-align: center;
          font-family: $gotham-book;
          font-size: 12px;
          color: #999;
          max-width: $size;
          margin: 0 auto;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          .sign-svg {
            width: 12px;
            height: 12px;
            fill: #999;
          }

          &-icon-row {
            display: inline-flex;
            align-items: center;
          }
        }
      }
    }
  }

  /* @LocalizationZenMode
   */

  &-zennable {
    position: relative;

    &-expander {
      display: none;
      position: absolute;
      left: -5px;
      top: -5px;
      width: 16px;
      height: 16px;
      overflow: hidden;
      text-indent: -9999px;
      background: url("~application_v2/icon-zenmode-expander.png") 0 0 no-repeat;

      @include retina {
        background-image: url("~application_v2/icon-zenmode-expander@2x.png");
        background-size: 41px 20px;
      }

      &:hover {
        width: 20px;
        height: 20px;
        left: -7px;
        top: -7px;
        background-position: -21px 0;
      }
    }

    &-collapser {
      display: none;
      position: absolute;
      top: 25px;
      right: 40px;

      &-container {
        display: none;
        padding-top: 20px;
      }

      &.base-btn {
        padding: 11px 15px;

        .icon-collapse {
          top: -2px;
          margin-right: 5px;
        }
      }
    }

    &-header {
      display: none;
      margin-bottom: 20px;
    }

    &-description {
      display: none;
      margin: 10px 0 0 -8px;
    }

    /* @LocalizationZenMode
     */

    &-sidebar {
      display: none;
      position: absolute;
      right: 0;
      top: 0;
      width: 445px;
      height: 100%;
      background: #fff;

      &-wrapper {
        box-sizing: border-box;
        position: absolute;
        overflow: auto;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        padding: 25px 40px;
      }
    }

    input {
      display: none;

      &:checked ~ #{$self}-zennable-backdrop {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: #fafafa;
        z-index: 200;
        overflow: auto;

        #{$self}-zennable-expander {
          display: none;
        }

        #{$self}-zennable-collapser {
          display: block;
        }

        #{$self}-zennable-collapser-container {
          display: block;
        }

        #{$self}-zennable-header {
          display: block;

          #{$self}-localizations-form-preview-asset-data {
            display: none;
          }
        }

        #{$self}-zennable-description {
          display: block;
        }

        #{$self}-zennable-sidebar {
          display: block;
        }

        #{$self}-zennable-content {
          box-sizing: border-box;
          width: 100%;
          padding: 25px 40px 100px;
          position: relative;
          height: 100%;
        }

        #{$self}-localizations-form-preview-holder {
          &.is-asset {
            display: block;
            float: right;
            pointer-events: none;
            width: 49%;

            img {
              max-height: 500px;
              max-width: 100%;
            }
          }
        }

        textarea {
          display: block;
          width: 100%;
          resize: none;
          outline: none;
          height: 100% !important;
          min-height: 100%;

          &.is-asset {
            float: left;
            width: 49%;
          }
        }
      }
    }
  }

  &-localizations {
    &-filter {
      cursor: pointer;
      display: inline-block;
      margin: 0 15px;
      padding: 15px 0;

      &:first-child {
        margin-left: 0;
      }

      &.is-inactive {
        cursor: not-allowed;
        opacity: 0.5;
      }

      &:not(.is-inactive) {
        &:hover {
          span {
            color: $link-hover;
          }
        }
      }

      &.is-active {
        border-bottom: 2px solid #fe6621;

        span {
          color: #000;
        }
      }

      .sign-svg {
        margin-right: 5px;
      }

      span {
        display: inline-block;
        vertical-align: middle;
        font-family: $gotham-medium;
        color: $link-color;
      }
    }

    &-toggle {
      display: inline-block;
      cursor: pointer;
      padding: 15px 20px;
      font-size: 16px;
      font-family: $gotham-medium;
      line-height: 24px;

      span {
        display: inline-block;
        vertical-align: middle;
      }

      &-status {
        display: inline-block;
        vertical-align: middle;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 7px 6px 0;
        border-color: $base transparent transparent transparent;
        margin-right: 5px;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &-inactive {
      &.is-dark {
        background: #f0f0f0;
      }

      &.is-collapsed {
        #{$self}-localizations-inactive-title,
        #{$self}-localizations-inactive-list {
          display: none;
        }

        #{$self}-localizations-toggle-status {
          border-width: 6px 0 6px 8px;
          border-color: transparent transparent transparent $base;
          margin: -3px 9px 0 0;
        }

        #{$self}-localizations-toggle {
          display: block;
        }
      }

      &-title {
        position: relative;
        text-align: center;
        margin-top: 10px;
        font-size: 26px;
        font-weight: bold;
      }
    }

    &-list {
      padding-bottom: 600px;

      @include clearfix;

      &-zerocase {
        padding: 40px 0;
        color: #999;
        font-family: $gotham-book;
        font-size: 16px;
        text-align: center;
      }
    }

    /* @LocalizationItem
     */

    &-item {
      border-bottom: 1px solid rgba(#000, 0.1);
      padding: 15px 20px 5px;
      margin-bottom: 0;

      &.is-highlighted {
        animation: blinking 2s linear 3;
      }

      &:hover {
        background: #fbf6e4;

        #{$self}-zennable-expander {
          display: block;
        }
      }
    }

    &-human-name {
      color: $base;
      font-family: $gotham-medium;
      font-size: 14px;
      line-height: 1.231;
    }

    &-action {
      @include vertical-gradient(#fdfdfd, #f1f1f1);

      border: 1px solid #ccc;
      border-radius: 4px;
      cursor: pointer;
      padding: 9px 0 10px;
      width: 38px;
      text-align: center;
      position: relative;

      &:hover {
        @include vertical-gradient(darken(#fdfdfd, 7%), darken(#f1f1f1, 7%));
      }

      + .tooltip {
        padding: 0 5px;
        margin-left: -3px;
      }

      .sign-svg {
        margin: 0;
      }

      .icon {
        &-picture {
          width: 18px;
          height: 18px;
          background: url("~application_v2/icon-picture-18x18.png") 0 0
            no-repeat;

          @include retina {
            background-image: url("~application_v2/icon-picture-18x18@2x.png");
            background-size: 18px 18px;
          }
        }

        &-trash {
          background: url("~application_v2/icon-trash-action.png") 0 0 no-repeat;
          background-size: 16px 18px;
          height: 20px;
          margin: -2px 0 -1px 1px;
          width: 18px;

          @include retina {
            background-image: url("~application_v2/icon-trash-action-2x.png");
          }
        }

        &-preview-eye {
          position: relative;
          top: -1px;
          width: 20px;
          height: 14px;
          background: url("~application_v2/icon-preview-eye.png") 0 0 no-repeat;

          @include retina {
            background-image: url("~application_v2/icon-preview-eye@2x.png");
            background-size: 45px 14px;
          }
        }
      }

      &.is-disabled {
        cursor: default;
        background: #f8f8f8;
        border-color: #e6e6e6;
        color: #c4c4c4;

        .icon-preview-eye {
          opacity: 0.3;
        }
      }

      &.is-active {
        background: #f9f0cd;
        border: 1px solid #b19c44;
        box-shadow:
          inset 0 1px 4px rgb(0 0 0 / 20%),
          0 1px 0 #fff;

        .icon-preview-eye {
          background-position: -25px 0;
        }
      }
    }

    &-form {
      margin: 5px 0;

      &-row {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin: 10px 0;

        &-content {
          position: relative;

          &.is-disabled {
            width: calc(100% - 84px);

            &::before {
              content: "";
              height: 100%;
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              z-index: 2;
            }
          }
        }

        &.is-wide &-content {
          display: block;
          width: 100%;
        }

        &.is-enum {
          #{$self}-localizations-form-controls {
            bottom: 1px;
            right: 40px;
          }
        }

        &.is-wide.is-string {
          .chosen-container-single .chosen-single {
            span {
              max-width: 250px;
            }
          }
        }

        &-field {
          position: relative;
        }

        &-actions {
          display: flex;
          padding-left: 10px;
          vertical-align: middle;

          &.is-wide {
            text-align: justify;
            width: 81px;

            #{$self} {
              &-localizations-action {
                border-radius: 0;
                margin-right: -1px;

                &:only-child {
                  border-radius: 4px;
                }

                &:first-child {
                  border-radius: 4px 0 0 4px;
                }

                &:last-child {
                  border-radius: 0 4px 4px 0;
                }
              }
            }
          }
        }

        /* jQuery Chosen overrides
         */

        .chosen-container-single {
          // I'm extremely sorry about that, but I have no choice, because of "Chosen"
          width: 100% !important;

          .chosen-single {
            font-family: $gotham-medium;
            height: 40px;
            padding: 10px 12px 9px;

            span {
              max-width: 175px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            div {
              top: 1px;
              right: 12px;
              width: 14px;
            }
          }

          &.chosen-with-drop {
            .chosen-drop {
              .chosen-results {
                li {
                  min-width: 215px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
            }
          }
        }

        textarea {
          resize: none;
          padding-right: 40px;
          margin-bottom: 0;
          height: 40px;
          min-height: 40px;
        }
      }

      &-preview {
        &-holder {
          font-family: $gotham-medium;
          font-size: 14px;
          color: #666;
          cursor: pointer;

          &.is-disabled {
            cursor: default;

            &:hover {
              img {
                border-color: transparent;
              }
            }
          }

          .tooltip {
            position: fixed;

            &.left {
              margin-left: -10px;
            }
          }

          &:hover {
            img {
              border-color: #00aeff;
            }
          }

          img {
            border-radius: 4px;
            max-height: 64px;
            max-width: 270px;
            min-height: 24px;
            min-width: 24px;
            box-shadow: inset 0 0 0 1px rgba(#fff, 0.5);
            background: rgb(243 243 243 / 85%);
            border: 2px solid rgb(243 243 243 / 85%);
          }
        }

        &-asset-data {
          color: #ababab;
          cursor: default;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          font-size: 11px;
          white-space: pre-wrap;

          &-col {
            align-items: inherit;
            display: inline-flex;
            margin-right: 10px;
          }
        }

        &-zerocase {
          color: #ce0000;

          .icon-broken {
            background-color: #ce0000;
            background-position: center center;
            border-radius: 4px;
            padding: 10px;
            margin-right: 10px;
          }
        }
      }

      /* Localization Input Controls
       * Add variant / Delete Variant
       */

      &-controls {
        position: absolute;
        right: 7px;
        bottom: 0;
        text-align: right;
        font-family: $gotham-medium;
        font-size: 14px;

        .icon-trash {
          top: -1px;
          margin-right: 7px;
          background: url("~application_v2/icon-trash.png") 0 0 no-repeat;
          width: 18px;
          height: 20px;

          @include retina {
            background-image: url("~application_v2/icon-trash@2x.png");
            background-size: 37px 18px;
          }
        }

        &-trash {
          display: inline-block;
          box-sizing: border-box;
          padding: 4px 7px 2px;
          overflow: hidden;
          width: 24px;
          white-space: nowrap;
          border-radius: 4px;
          transition: 0.3s;

          &:hover {
            width: 100%;
            background: #fee;
            color: #ce0000;
            text-decoration: none;

            .icon-trash {
              background-position: -21px 0;
            }
          }
        }
      }
    }

    /* @LocalizationItemAB
     */

    &-ab {
      border-bottom: 1px solid rgb(0 0 0 / 10%);
      padding: 20px 15px;

      &:hover {
        background: #fbf6e4;

        #{$self}-zennable-expander {
          display: block;
        }
      }

      &-name {
        font-size: 22px;
        line-height: 24px;

        span:first-child {
          font-family: $gotham-bold;
        }
      }

      &-status {
        border: 1px solid transparent;
        border-radius: 3px;
        font-family: $gotham-medium;
        font-size: 14px;
        margin-right: 4px;
        padding: 4px 5px;

        &-helper {
          font-size: 12px;
          position: relative;
          top: 1px;
        }

        &-box {
          color: #999;
          font-family: $gotham-book;
          font-size: 14px;

          & > div {
            display: inline-flex;
          }

          .is-gray {
            border-color: #e5e5e5;
            color: #999;
          }

          .is-green {
            color: #429f53;
          }

          .is-bold {
            font-family: $gotham-bold;
          }
        }
      }

      &.is-highlighted &-wrapper {
        animation: blinking 1s linear 3;
      }

      &-wrapper {
        box-shadow:
          inset 0 1px 1px rgb(0 0 0 / 7.5%),
          0 0 8px rgb(82 168 236 / 60%);
        background: #fff;
      }

      &-header {
        border-bottom: 1px solid #e5e5e5;
        color: $base;
        display: flex;
        flex-direction: column;
        font-family: $gotham-medium;
        font-size: 24px;
        margin: 0 10px;
        padding: 12px 5px;
        position: relative;

        .base-text-label {
          display: inline-flex;
        }

        &-sub {
          font-family: $gotham-medium;
          font-size: 14px;
          color: #999;
        }

        .icon-question-large {
          vertical-align: middle;
          position: relative;
          top: -1px;
          margin-left: 7px;
        }

        .tooltip {
          font-family: $gotham-book;
        }
      }

      #{$self}-button-close {
        top: 14px;
      }

      /* @LocalizationItemAB
       * A/B test is running
       * Localizations list
       */

      &-list {
        margin: 0;
        padding: 0;
        list-style-type: none;
        counter-reset: listStyle;

        &-item {
          display: inline-block;
          position: relative;
          width: 100%;
          padding: 5px 0;
          border-bottom: 1px solid #e5e5e5;

          &:last-of-type {
            border-bottom: 0;
          }

          &::before {
            border-radius: 50%;
            background-color: #2dc1ff;
            color: #fff;
            content: counter(listStyle, lower-alpha);
            counter-increment: listStyle;
            display: block;
            font-family: $gotham-medium;
            font-size: 12px;
            height: 20px;
            left: 0;
            position: absolute;
            text-align: center;
            text-transform: uppercase;
            top: 15px;
            width: 20px;
            line-height: 1.6;
            margin-right: 5px;
          }
        }

        &-value {
          float: left;
          width: 100%;
          max-width: 230px;
          padding: 10px 0;
          color: $base;
          font-family: $gotham-book;
          font-size: 16px;
          padding-left: 30px;
          line-height: 1.3;
        }

        &-actions {
          float: right;
        }
      }

      &-content {
        padding: 20px 15px 15px;

        #{$self}-localizations-form-preview-asset-data {
          margin-top: -7px;
          padding-left: 26px;
        }

        #{$self}-localizations-ab-actions ul {
          width: auto;
        }
      }

      &-footer {
        box-sizing: border-box;
        background: #f3faf3;
        padding: 15px;
        font-family: $gotham-medium;
        font-size: 16px;

        @include clearfix;

        &.is-delete {
          background-color: #fee;
          color: #ce0000;

          p {
            font-family: $gotham-medium;
            font-size: 14px;
            margin-bottom: 20px;
          }
        }

        a {
          display: inline-block;
          vertical-align: middle;
        }
      }

      /* @LocalizationItemAB
       * A/B test is running
       * Progress bar
       */

      &-progress {
        &-bar {
          display: inline-block;
          height: 8px;
          position: relative;
          background: #f1f1f1;
          border-radius: 25px;
          box-shadow: inset 1px 2px 2px 0 rgb(0 0 0 / 10%);
          width: 50px;
          margin-left: 7px;
        }

        &-value {
          margin-left: 7px;
          display: inline-block;
          color: #999;
        }
      }

      /* @LocalizationItemAB
       * Add variant / Settings / Report
       */

      &-actions {
        align-items: center;
        display: flex;
        font-family: $gotham-medium;
        font-size: 14px;
        justify-content: space-between;

        .icon {
          &-analytics {
            top: -1px;
          }
        }

        &-link {
          display: inline-block;
          line-height: 24px;
        }

        ul {
          align-items: center;
          display: flex;
          list-style-type: none;
          justify-content: space-between;
          margin: 0;
          width: 100%;
        }

        li.align-right {
          margin-left: auto;
        }
      }
    }
  }
}
