// Tooltip with campaigns list
// ---------------------------
$self: ".campaignstip";

#{$self} {
  background: #fff;
  border-radius: 10px;
  bottom: 50px;
  box-shadow: 0 5px 35px rgba(#000, 0.3);
  left: -77px;
  margin: 0;
  opacity: 0;
  padding: 20px;
  position: absolute;
  transform: scale(0.97, 0.97);
  transition: 0.5s;
  visibility: hidden;
  width: 640px;
  z-index: 101;

  &:target {
    bottom: 30px;
    opacity: 1;
    transform: scale(1, 1);
    visibility: visible;

    #{$self}-ear {
      bottom: -13px;
    }
  }

  &-ear {
    border-top: 15px solid #fff;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    bottom: 0;
    left: 192px;
    position: absolute;
    transition: 0.5s;
    width: 0;
  }

  &-data {
    height: 41px;
    left: 30px;
    position: absolute;
    top: 94px;
    z-index: 3;
    width: 22px;

    &::before {
      margin-left: -5px;
    }

    &::after {
      height: 20px;
      line-height: 20px;
    }
  }

  &-wrapper {
    margin-top: 5px;
    position: relative;
  }

  &-trigger {
    align-items: center;
    color: #999;
    display: flex;
    font-family: $gotham-medium;
    font-size: 12px;

    .link {
      font-size: inherit;
      line-height: 1;
    }
  }

  h3 {
    border-bottom: 1px solid #eee;
    color: #333;
    font: 700 30px $gotham-book;
    padding: 0 10px 15px;
  }

  .campaignfilter {
    &-campaign-label {
      width: 48px;
    }

    &-list {
      margin-bottom: 0;
      max-height: 310px;
      overflow: auto;
      padding: 5px 10px 0;

      > li {
        margin-top: 5px;
      }
    }
  }
}
