@import "stylesheets/application_v2/config";
@import "stylesheets/themes/shared/reset";
@import "stylesheets/themes/shared/utils";
@import "stylesheets/themes/shared/animate";
@import "stylesheets/themes/shared/fonts";

/* -----------------------------
  Basic styles for
----------------------------- */

$self: ".Previews";

#{$self} {
  /* -----------------------------
    Variables
  ----------------------------- */
  $link-c: #0f6cd7;
  $subheader-c: #fafafa;

  $mobile-breakpoint: 400px;
  $mobile-device-h: 580px;
  $tablet-breakpoint: 667px;
  $tablet-device-h: 943px;
  $desktop-device-h: 768px;

  $desktop-content-top-gap: 36px;

  /* -----------------------------
    MIXINS
  ----------------------------- */
  @mixin proper-link-styling {
    a {
      color: $link-c;
    }
  }

  @mixin add-device-shadow($type) {
    @if $type == mobile {
      box-shadow: 0 5px 16px rgb(0 0 0 / 16%);
    }

    @if $type == tablet {
      box-shadow: 0 5px 16px rgb(0 0 0 / 16%);
    }

    @if $type == desktop {
      box-shadow: 3px 4px 16px rgb(0 0 0 / 16%);
    }

    @include print {
      border: 1px solid #e5e5e5;
    }
  }

  /* -----------------------------
    Inheritance
  ----------------------------- */
  &,
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  .social-share-twitter,
  .social-share-facebook {
    margin: 0 !important;

    &,
    *,
    *::before,
    *::after {
      box-sizing: content-box;
    }
  }

  /* -----------------------------
    Top area
  ----------------------------- */
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px 24px 16px 32px;
    border-bottom: #e5e5e5 solid 1px;
    background-color: white;

    @include print {
      padding-bottom: 0;
    }
  }

  &-title {
    color: $base;
    font: 28px $gotham-bold;
    line-height: 30px;
    margin-bottom: 0;
  }

  &-header-tools {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include hGap(10px);
  }

  &-subheader {
    @include vGap(20px);

    & {
      padding: 20px 30px;
      background-color: $subheader-c;
      border-bottom: 1px solid #e0e0e0;
    }

    &.is-empty,
    &:empty {
      border-bottom: none;

      @include padding-y(0);
    }

    @include print {
      @include vGap(0);
    }
  }

  &-public-data {
    @include margin-y(-4px); //compensate td paddings

    font-size: 14px;
    width: 500px;

    td {
      @include padding-x(0);
    }
  }

  &-public-data-label {
    font-family: $gotham-bold;
    position: relative;
    overflow: hidden;

    // dotted border
    &::before {
      @include p-ctr(y);

      left: 0;
      right: -4px;
      margin-top: 4px;
      border-bottom: 1.4px dotted rgb(0 0 0 / 50%);
    }
  }

  &-public-data-label-text {
    position: relative;
    z-index: 1;
    //`limit` dotted border
    background-color: #fff;

    #{$self}-subheader & {
      background-color: $subheader-c;
    }
  }

  /* -----------------------------
    Ab tests incentives
  ----------------------------- */

  &-ab-test-list {
    margin-bottom: -20px;
  }

  &-ab-test {
    position: relative;
    box-shadow: 0 2px 8px rgb(0 0 0 / 10%);
    background-color: #fff;
    padding: 20px;
    margin-left: 20px;
    margin-bottom: 20px;

    @include vGap(10px);

    &:last-child {
      .btn-group {
        display: flex;

        .btn {
          width: calc(100% / 3);
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }

  &-ab-test-icon {
    position: absolute;
    right: 20px;
    top: 21px;
    width: 18px;
    stroke: #ff4f00;
  }

  &-ab-test-title {
    font: 18px/1.2 $gotham-bold;
    padding-right: 35px;
  }

  &-ab-test-label {
    display: inline-block;
    border-radius: 4px;
    background-color: rgba(#000, 0.15);
    color: #93819c;
    font-family: "PT Mono", monospace;
    font-size: 12px;
    padding: 3px 7px;
  }

  &-ab-test-text {
    font: 14px $gotham-light;
  }

  /* -----------------------------
    View Setups
  ----------------------------- */

  &-view-setups-list {
    padding-top: 0;

    @include print {
      padding: 0;
    }
  }

  &-view-setups-list-item {
    position: relative;

    @include print {
      padding: 0;
    }

    // bottom border
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 55px;
      right: 10px;
      height: 1px;
      background-color: rgba(#000, 0.1);

      @include print {
        display: none;
      }
    }

    /* stylelint-disable */
    &:last-of-type {
      /* stylelint-enable */
      padding-bottom: 0;

      &::after {
        display: none;
      }

      #{$self}-view-setup-preset {
        page-break-after: auto;
      }
    }
  }

  &-view-setup-name {
    font: 16px $gotham-bold;

    @include proper-link-styling;
  }

  &-view-setup-line {
    position: absolute;
    width: 1px;
    left: 25px;
    top: 0;
    bottom: 0;
  }

  &-view-setup-preset {
    page-break-before: avoid;
    page-break-inside: avoid;
    page-break-after: always;
  }

  &-view-setup-preset-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 24px 24px 32px;
    border-bottom: #e5e5e5 solid 1px;
    background-color: white;

    &:not(:first-child) {
      border-top: #e5e5e5 solid 1px;
    }
  }

  &-view-setup-preset-data-row {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    font: 14px $gotham-medium;
    margin-bottom: 5px;

    /* stylelint-disable */
    &:last-of-type {
      /* stylelint-enable */
      margin-bottom: 0;
    }

    &.is-title {
      color: #999;
    }
  }

  &-view-setup-preset-data-label {
    margin-right: 5px;
  }

  &-view-setup-preset-data-piece {
    @include proper-link-styling;

    &.is-title {
      font-family: $gotham-medium;
      overflow-wrap: break-word;
    }
  }

  &-view-setup-preset-links {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include hGap(6px);

    .sign-svg {
      margin-right: 0;
    }
  }

  // all
  &-view-setup-preset-wrapper {
    margin: 0;
    padding: 16px;
    display: flex;
    justify-content: center;
  }

  &-view-setup-preset-device-body {
    position: relative;
    background-repeat: no-repeat;
  }

  /* -----------------------------
    Iframe stuff
  ----------------------------- */

  @mixin add-email-pseudo-content() {
    #{$self}-view-setup-preset-device-content {
      $x-gap: 10px;

      &.is-top {
        display: block;
        position: relative;
        padding: $x-gap $x-gap 20px;

        &::before {
          content: "";
          height: 32px;
          background-image: url("~application_v2/campaign_previews/email-content-top.png");
        }

        &::after {
          @include pseudo();

          border-bottom: 1px solid #f6f6f6;
          left: $x-gap;
          right: $x-gap;
          bottom: $x-gap;
        }
      }

      &.is-bottom {
        display: none;
      }
    }
  }

  @mixin hide-pseudo-content() {
    #{$self}-view-setup-preset-device-content {
      display: none;
    }
  }

  @mixin show-pseudo-content() {
    #{$self}-view-setup-preset-device-content {
      display: block;
    }
  }

  /*
    Iframe style mixins

    appearance & view setup selectors should be equally weighted
 */

  &-view-setup {
    overflow: auto;
    background-color: #fff;

    .preview-list-presets-type {
      position: static;
      margin-right: 8px;
      left: 0;

      &.is-stroked {
        fill: #f60;
        stroke: #fff;
      }
    }

    &.is-email {
      @include add-email-pseudo-content();
    }

    &.is-web {
      &.is-selfhosted {
        @include hide-pseudo-content();
      }
    }

    &.is-scroll {
      overflow: auto;
    }
  }

  @mixin style-from-platform($platform) {
    @if $platform == "desktop" {
      &.desktop.is-outside-cover-iframe {
        @content;
      }

      @include mq-min-width($mobile-breakpoint) {
        &.is-inside-cover-iframe {
          @content;
        }
      }
    }

    @if $platform == "tablet" {
      &.tablet.is-outside-cover-iframe {
        @content;
      }

      @include mq-max-width($tablet-breakpoint) {
        &.is-inside-cover-iframe {
          @content;
        }
      }
    }

    @if $platform == "mobile" {
      &.mobile.is-outside-cover-iframe {
        @content;
      }

      @include mq-max-width($mobile-breakpoint) {
        &.is-inside-cover-iframe {
          @content;
        }
      }
    }
  }

  &-view-setup-preset-device-display {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: column;
  }

  &-view-setup-preset-device-content-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: column;
    flex: 1 0 0;
    background-color: white;
  }

  &-view-setup-preset-device-content {
    position: relative;

    &.is-bottom {
      padding-top: 0 !important; // remove double gap between is-top & is-bottom
    }

    &::before {
      @include pseudo();

      position: static;
      height: inherit;
      max-height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  &-view-setup-preset-cover-iframe {
    height: auto;
    width: 100%;
    flex: 1 0 0;
  }

  .social-share-facebook {
    @include add-device-shadow(desktop);
  }

  /* -----------------------------
    global platform
  ----------------------------- */
  &-global-platform.desktop {
    [data-preset="advocate_share_page_channel_sms"],
    [data-preset="advocate_share_page_channel_whatsapp"] {
      display: none;
    }
  }

  /* -----------------------------
    Iframe Mobile platform
  ----------------------------- */
  @include style-from-platform(mobile) {
    #{$self}-view-setup-preset-device-body {
      display: inline-block;
      background-image: url("~application_v2/campaign_previews/shape-mobile.png");
      background-color: transparent;
      background-repeat: no-repeat;
      border-radius: 60px;
      padding: 64px 20px 140px 17px;
      background-size: 100% 100%;
      width: 420px;
      aspect-ratio: 9 / 19.5;
      position: relative;

      @include print {
        &::before {
          content: url("~application_v2/campaign_previews/shape-mobile.png");
          position: absolute;
          z-index: -1;

          @include mask;
        }
      }
    }

    #{$self}-view-setup-preset-device-display {
      position: absolute;
      left: 17px;
      right: 20px;
      top: 64px;
      bottom: 140px;
      border-radius: 2px;
    }

    #{$self}-view-setup {
      &.is-web {
        #{$self}-view-setup-preset-device-content {
          padding: 8px;

          &.is-top {
            &::before {
              height: 40px;
              background-image: url("~application_v2/campaign_previews/inline-mobile-content-top.png");
            }
          }

          &.is-bottom {
            &::before {
              min-height: 58px;
              height: 100%;
              background-image: url("~application_v2/campaign_previews/inline-mobile-content-bottom.png");
            }
          }
        }
      }
    }
  }

  /* -----------------------------
    Iframe Tablet platform
  ----------------------------- */
  @include style-from-platform(tablet) {
    #{$self}-view-setup-preset-device-body {
      display: inline-block;
      background-image: url("~application_v2/campaign_previews/shape-tablet.png");
      background-color: transparent;
      padding: 127px 71px 87px 74px;
      background-size: contain;

      @include print {
        &::before {
          content: url("~application_v2/campaign_previews/shape-tablet.png");
          position: absolute;
          z-index: -1;

          @include mask;
        }
      }
    }

    #{$self}-view-setup-preset-device-display {
      width: 768px;
      height: $tablet-device-h;
      border-radius: 2px;
      border: 1px solid #e6e6e6;
    }
  }

  /* -----------------------------
    Iframe Desktop
  ----------------------------- */
  @include style-from-platform(desktop) {
    #{$self}-view-setup-preset-device-body {
      background-image: url("~application_v2/campaign_previews/inline-device-desktop.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding-top: $desktop-content-top-gap;
      background-color: #fff;
      overflow: hidden;
      position: relative;

      @include print {
        // background to fit 'brower page head' into full width
        &::before {
          bottom: auto;
          height: 30px;
          z-index: -2;
          background-color: #e5e5e5;

          @include pseudo;
          @include mask;
          @include add-radius(top, 6px);
        }
        // mimic browser page
        &::after {
          content: url("~application_v2/campaign_previews/inline-device-desktop.png");
          z-index: -1;
          position: absolute;

          @include mask;
        }
      }
    }

    #{$self}-view-setup-preset-device-content-wrapper {
      height: 100%;
    }

    #{$self}-view-setup-preset-device-display {
      position: absolute;
      top: 36px;
      left: 1px;
      right: 1px;
      bottom: 1px;
    }

    #{$self}-view-setup {
      &.is-web {
        #{$self}-view-setup-preset-device-content {
          padding: 20px;

          &.is-top {
            &::before {
              height: 30px;
              background-image: url("~application_v2/campaign_previews/inline-content-top.png");
            }
          }

          &.is-bottom {
            &::before {
              min-height: 58px;
              background-image: url("~application_v2/campaign_previews/inline-content-bottom.png");
            }
          }
        }

        #{$self}-view-setup-preset-device-body {
          width: 100%;
          aspect-ratio: 16 / 9;
          box-shadow: 0 0 8px 1px #bdbdbd;
          border-radius: 4px;
          background-color: transparent;
        }
      }

      &.is-email {
        #{$self}-view-setup-preset-device-body {
          display: inline-block;
          padding: 25px 30px;
          background-image: none;

          @include print {
            &::before,
            &::after {
              display: none;
            }
          }
        }

        #{$self}-view-setup-preset-device-display {
          height: $desktop-device-h;
          position: relative;
          width: 560px;
        }

        #{$self}-view-setup-preset-device-content {
          &.is-top {
            padding: 0 0 30px;

            @include print {
              &::before {
                @include mask;

                bottom: 30px;
              }
            }

            &::after {
              left: 0;
              right: 0;
              bottom: 15px;
            }
          }
        }
      }
    }
  }

  /* -----------------------------
    Top Wrapper modifiers
  ----------------------------- */
  &.is-simple-layout {
    &,
    .Previews-view-setup,
    iframe {
      @include size(100%);
    }

    &.is-inside-editor {
      &.is-outside-cover-iframe {
        overflow: hidden;
      }
    }

    &.is-tablet-mode {
      &,
      .Previews-view-setup,
      iframe {
        width: 768px;
      }
    }

    &.is-mobile-mode {
      &,
      .Previews-view-setup,
      iframe {
        width: 375px;
      }
    }

    #{$self}-view-setup-preset-cover-iframe {
      display: block;
      height: 100%;
      flex: initial;
    }
  }

  &.is-inside-cover-iframe {
    background-color: #fff;
  }
}
