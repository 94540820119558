.tabs-menu {
  margin: 0;

  li {
    display: inline-block;
    margin-right: 40px;

    .badge-count {
      font-family: $gotham-book;
    }

    > a {
      display: block;
      font-family: $gotham-bold;
      font-size: 16px;
      font-weight: normal;
      color: #888;
      padding: 10px 0;
      line-height: 23px;
      text-decoration: none;
      transition: 0.25s;

      &:hover {
        color: #ff1900;
      }
    }

    &.active {
      > a {
        background: transparent;
        color: #333;
        border-bottom: 2px solid #ff1900;
      }
    }
  }
}
