$self: ".base-dropdown";

#{$self} {
  position: absolute;
  top: 100%;
  z-index: 999999;
  display: none;
  min-width: 160px;
  margin: 0;
  list-style: none;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 5px 25px 0 rgb(0 0 0 / 40%);

  @include mq-max-width(960px) {
    width: 480px;
    flex-wrap: wrap;
  }

  &.is-large {
    @include mq-max-width(1200px) {
      width: 480px;
      flex-wrap: wrap;
    }
  }

  &.is-left {
    left: 0;
  }

  &.is-right {
    right: 0;
  }

  .open & {
    display: flex;
  }

  &-col {
    box-sizing: border-box;
    min-width: 240px;
    padding: 7px 10px;
    background: #fff;

    &.is-dark {
      background: #fafafa;
    }

    #{$self}-sub-item-img {
      opacity: 0.6;
    }
  }

  &-header {
    color: #333;
    font-family: $gotham-medium;
    font-size: 16px;
    line-height: 1;
    padding: 15px 10px 10px 15px;
    border-bottom: 1px solid #e4e4e4;

    &.is-with-label {
      padding: 2px 0 9px 12px;
      min-height: 42px;
      box-sizing: border-box;
    }
  }

  &-sub {
    margin: 0;
    list-style: none;

    &.is-full-height {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &::before {
        display: none;
      }

      &::after {
        display: none;
      }
    }

    &-item {
      position: relative;

      &.is-active,
      &:hover {
        a {
          background: #fbf6e4;
          text-decoration: none;
        }
      }

      a {
        display: flex;
        background: transparent;
        font-family: $gotham-medium;
        color: #333;
        font-size: 14px;
        line-height: 1;
        padding: 12px 10px;
        align-items: center;

        &.is-link-color {
          color: #0070d9;

          .base-dropdown-sub-item-img {
            opacity: 0.9;
          }

          svg > g {
            stroke: #0070d9;
          }
        }

        .sign-svg-appStore {
          path {
            fill: #000;
          }
        }
      }

      &-img {
        width: 25px;
        margin-right: 7px;
      }

      &-divider {
        height: 1px;
        background: #e4e4e4;
      }
    }
  }
}
