.base-radio {
  &-container {
    display: inline-block;
    position: relative;

    .base-radio {
      label {
        margin-bottom: 0;
      }

      + .base-radio {
        margin-top: 5px;
      }
    }
  }

  input[type="radio"] {
    position: absolute;
    left: -9999px;

    ~ label {
      position: relative;
      padding-left: 27px;
      cursor: pointer;
      font-family: $gotham-book;
      font-size: 16px;
      color: #333;

      a {
        font-size: 14px;
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: #fff;
        border: 1px solid #cdcdcd;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background-color: #333;
        border: 1px solid #2679d0;
        box-shadow: 0 0 0 4px #fff inset;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        transition: all 0.2s;
        backface-visibility: hidden;
      }
    }

    /* checked mark aspect changes */
    &:not(:checked) ~ label::after {
      opacity: 0;
    }

    &:checked ~ label::after {
      opacity: 1;
    }

    // disabled
    &:disabled:not(:checked) ~ label::before,
    &:disabled:checked ~ label::before {
      box-shadow: none;
      border-color: #e6e6e6;
      background-color: #f8f8f8;
    }

    &:disabled:checked ~ label::after {
      background-color: #c4c4c4;
      box-shadow: 0 0 0 4px #f8f8f8 inset;
      border-color: #e6e6e6;
    }

    &:disabled ~ label {
      color: #aaa;
    }

    /* accessibility */
    &:checked:focus ~ label::before,
    &:not(:checked):focus ~ label::before {
      border: 1px dotted #2e7ccc;
    }

    /* hover style just for information */
    &:not(:disabled) ~ label:hover::before {
      border: 1px solid #2e7ccc !important;
    }

    &.validation-error ~ label::before {
      border-color: #ff4e4e !important;
    }
  }
}
