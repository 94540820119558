.footer {
  box-sizing: border-box;
  position: relative;
  padding: 46px 0 0;
  margin: 40px 0 0;
  background: #201c1b;
  font-family: $gotham-book;
  font-size: 16px;
  color: #bbb3b0;
  height: 120px;

  @include mq-max-width($width-3of7) {
    padding: 20px 0;
    height: auto;
  }

  body.is-wide-report & {
    @media (min-width: 1600px) {
      min-width: 1300px;
      padding-right: 20px;
      padding-left: 20px;
    }
  }

  &-copy {
    display: inline-block;
    vertical-align: middle;
  }

  &-links {
    display: inline-block;
    vertical-align: middle;
    margin-left: 80px;

    @include mq-max-width($width-3of7) {
      display: block;
      margin-left: 0;
    }

    a {
      color: #cfc8c5;
      font-family: $gotham-medium;
      margin-right: 18px;

      @include mq-max-width($width-3of7) {
        display: block;
      }
    }
  }
}
