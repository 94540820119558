@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

.async-count-paginate {
  height: 35px;
  margin: 20px 0;
}

.grid-spinner {
  display: inline-block;
  animation: fa-spin 2s infinite linear;
}
