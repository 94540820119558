// Commit data that's used inside Editor, i.e. the code change information
// -----------------------------------------------------------------------
$self: ".commit";

#{$self} {
  &-date {
    display: inline-block;
    font-family: $gotham-bold;
    font-size: 16px;
    font-weight: normal;
    margin-right: 5px;
  }

  &-day {
    color: #999;
    display: inline-block;
    font-family: $gotham-bold;
    font-size: 16px;
    font-weight: normal;
  }

  &-by {
    color: #666;
    font-family: $gotham-book;
    font-weight: normal;
  }
}
