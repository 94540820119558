// Should be used to modify the default spacing between objects (not between nodes of the same object)
// Directions:  a, t, r, b, l, h, v = all, top, right, bottom, left, horizontal, vertical
// Sizes: n, x, s, m, l, xl, xxl
// $types: p, m                = padding, margin
@use "sass:math";

$sizes: (n: 0, x: 4px, s: 8px, m: 16px, l: 24px, xl: 32px, xxl: 40px, xxxl: 48px);
$types: (p: padding, m: margin);
$grid-params: (column: 80px, total: 12);
$all-dir-symbol: a;

.h {
  @each $s in map-keys($sizes) {
    $size: map-get($sizes, $s);

    // horisontal gap controller
    &-hgap-#{$s} {
      @include hGap($size);
    }

    // vertical gap controller
    &-vgap-#{$s} {
      @include vGap($size);
    }
  }

  &-hgap-child-wrap {
    & > * {
      margin-left: 0 !important;
    }
  }

  &-vgap-child-wrap {
    & > * {
      margin-bottom: 0 !important;
    }
  }

  @for $i from 1 through map-get($grid-params, total) {
    //absolute (px)
    &-wabs-#{$i} {
      width: map-get($grid-params, column) * $i;
    }

    //relative (%)
    &-wrel-#{$i} {
      width: math.div(100%, map-get($grid-params, total)) * $i;
    }
  }
}

// Spaces
@each $t in map-keys($types) {
  $prop: map-get($types, $t);

  @each $s in map-keys($sizes) {
    $size: map-get($sizes, $s);

    @each $dir in top right bottom left {
      $one-dir-symbol: str-slice($dir, 0, 1);
      $bi-dir-symbol: if($dir == "top" or $dir == "bottom", v, h);

      $one-dir-selector: #{$t}#{$one-dir-symbol}#{$s};
      $bi-dir-selector: #{$t}#{$bi-dir-symbol}#{$s};
      $all-dir-selector: #{$t}#{$all-dir-symbol}#{$s};
      $full-selector: unquote("." + $one-dir-selector + ", ." + $bi-dir-selector + ", ." + $all-dir-selector);
      $full-negative-selector: unquote(".-" + $one-dir-selector + ", .-" + $bi-dir-selector + ", .-" + $all-dir-selector);

      #{$full-selector} {
        #{$prop}-#{$dir}: $size;
      }

      @if $t == "m" and $size != 0 {
        #{$full-negative-selector} {
          #{$prop}-#{$dir}: -$size;
        }
      }
    }
  }
}

// Add spaces to each closest column
// inside particular row.
.row,
.form-row {
  @each $s in map-keys($sizes) {
    $size: map-get($sizes, $s);

    &.is-#{$s} {
      margin-left: -$size;

      > [class^='col'] {
        padding-left: $size;
      }
    }
  }
}
