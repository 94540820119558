$self: ".Campaign-management";

#{$self} {
  &-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &-title {
      display: inline-block;
      vertical-align: middle;
      font-family: $gotham-bold;
      font-size: 26px;
      line-height: 1.2;
      color: #333;
      margin: 0;
    }

    &-icon {
      display: inline-block;
      margin-right: 10px;
      vertical-align: middle;
    }

    &-indicator {
      display: inline-block;
      margin-right: 8px;
      vertical-align: middle;

      .sign-svg {
        margin-right: 0;
      }
    }
  }

  &-status {
    margin-top: -2px;
    font-family: $gotham-bold;
    font-size: 14px;
    line-height: 1;

    @include keyframes(blinker) {
      from {
        opacity: 1;
      }

      50% {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    &.is-inline-flex {
      display: inline-flex;
      align-items: center;
    }

    &.is-test,
    &.is-scheduled {
      color: #eaa717;
    }

    &.is-live {
      color: #61c361;

      #{$self}-status-icon {
        box-sizing: border-box;
        width: 12px;
        height: 12px;
        border-radius: 6px;
        background: #61c361;
        animation: blinker 3s linear infinite;
      }
    }

    &.is-scheduled {
      #{$self}-status-icon {
        height: 12px;
        width: 4px;
        background: #eaa717;
        position: relative;

        &::after {
          position: absolute;
          width: 4px;
          bottom: 0;
          top: 0;
          left: -6px;
          background: #eaa717;
          content: "";
        }
      }
    }

    &.is-disabled {
      color: #df4343;

      #{$self}-status-icon {
        width: 10px;
        height: 10px;
        background: #df4343;
      }
    }

    &.is-test {
      #{$self}-status-icon {
        box-sizing: border-box;
        width: 12px;
        height: 12px;
        border: 1px solid #eaa717;
        border-radius: 6px;
      }
    }

    &-text {
      display: inline-block;
      vertical-align: middle;
    }

    &-icon {
      display: inline-block;
      vertical-align: middle;
      margin-right: 4px;
    }
  }

  &-content-holder {
    box-shadow: 0 0 10px rgba(#000, 0.1);

    @include print {
      margin: 0;
    }
  }

  &-filters {
    &-wrapper {
      position: relative;
      background: #f2f9fa;
      border-bottom: 1px solid #d4e9ec;
      padding: 25px;
    }

    &-form {
      .base-btn {
        position: absolute;
        bottom: 25px;
        right: 25px;
        border-color: #d2d7d8;
        font-family: $gotham-medium;
        font-size: 14px;
        padding: 10px 13px;

        .icon {
          &.icon-x {
            vertical-align: middle;
            font-size: 30px;
            line-height: 13px;
            margin-right: 8px;
          }
        }
      }

      .icon {
        &.icon-question {
          width: 20px;
          height: 20px;
          background:
            url("~application_v2/icon-circle-question-20x20.png") 0 0
            no-repeat;
          padding: 0 1px;
          margin-left: 1px;
          vertical-align: top;
        }
      }

      .row {
        .span3 {
          width: auto;
        }

        .field {
          label {
            display: inline-block;
            font-family: $gotham-medium;
            font-size: 14px;
            color: #666;
            margin-bottom: 8px;
          }

          .chosen-container-single {
            width: auto !important;

            .chosen-single {
              float: left;
              font-family: $gotham-medium;

              div {
                right: 15px;
                width: 14px;

                b {
                  background-position: 100% -54px;
                }
              }

              &:not(.chosen-default) {
                @include vertical-gradient(#fefbf0, #f9f1cf);

                border: 1px solid #c8bd8e;
              }
            }

            &.chosen-with-drop {
              .chosen-single {
                div {
                  b {
                    background-position: 100% -54px;
                  }
                }
              }

              .chosen-drop {
                width: auto;

                .chosen-results {
                  li {
                    max-width: 500px;
                    min-width: 200px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &-list {
    &-wrapper {
      background: #fff;
      padding: 25px;

      @include print {
        padding: 0;
      }
    }

    &-header {
      &-title {
        font-family: $gotham-bold;
        font-size: 24px;
        color: #333;
      }

      &-count {
        font-family: $gotham-book;
        color: #999;
      }
    }
  }

  .subnav {
    margin-top: 25px;
  }
}

@media all and (min-resolution: 2dppx), all and (min-resolution: 144dpi) {
  .Campaign-management-filters-form .icon.icon-question {
    background-image: url("~application_v2/icon-circle-question-20x20@2x.png");
    background-size: 20px 20px;
  }
}
