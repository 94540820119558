// Campaign onboarding wizard
$self: ".wizard";
$color: #0070d9;
$outline: #99c6f0;
$sidebar: 260px;
$extra: 310px;

#{$self} {
  background-color: $color;
  height: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: relative;
  top: 50px;
  z-index: 99;
  transition: 0.5s;
  backface-visibility: hidden;
  transform: scale(0.98);

  @include clearfix;

  &.is-active {
    box-shadow: 0 0 50px rgba(#000, 0.2);
    height: 100%;
    opacity: 1;
    overflow: visible;
    pointer-events: all;
    top: 0;
    transform: scale(1);
  }

  &-hide-button {
    position: absolute;
    left: 8px;
    top: 8px;

    &.base-btn {
      padding: 4px 11px;
      margin-left: 30px;
      color: $outline;

      &:hover {
        color: #fff;
      }
    }
  }

  &-container {
    bottom: 0;
    font-size: 16px;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 98;

    &.is-hidden {
      display: none;
    }

    &.is-loading {
      #{$self}-gleam {
        background: #eee url("~ajax-loader.gif") center center no-repeat;

        ul {
          opacity: 0;
        }
      }
    }
  }

  // Sticky progressbar
  // ------------------
  &-gleam {
    background-color: $color;
    bottom: 0;
    box-shadow: 0 0 10px rgba(#00417e, 0.65);
    left: 0;
    position: absolute;
    text-align: center;
    width: 100%;
    transition: background-color 0.5s;
    backface-visibility: hidden;

    > ul {
      counter-reset: wizard-gleam;
      display: inline-block;
      list-style-type: none;
      margin: 0;
      vertical-align: middle;

      @include clearfix;

      > li {
        float: left;
        position: relative;

        &:last-child {
          &::after {
            display: none;
          }

          span {
            margin-right: 0;
            padding-right: 0;
          }
        }

        // Delimiter line
        &::after {
          background-color: $outline;
          content: "";
          height: 4px;
          margin-top: -2px;
          position: absolute;
          right: 0;
          top: 50%;
          width: 27px;
        }

        &.is-active {
          span {
            color: #fff;

            &::before {
              background-color: #fff;
              border-color: #fff;
              color: $color;
            }
          }
        }
      }

      span {
        display: block;
        color: $outline;
        cursor: pointer;
        font-family: $gotham-medium;
        margin-right: 7px;
        padding: 15px 28px 15px 40px;
        position: relative;
        z-index: 1;

        &:hover {
          color: #fff;
          text-decoration: none;

          &::before {
            border-color: #fff;
          }
        }

        // Bullet
        &::before {
          border: 4px solid $outline;
          border-radius: 50%;
          color: #fff;
          content: counter(wizard-gleam);
          counter-increment: wizard-gleam;
          display: block;
          font: 16px / 22px $gotham-bold;
          height: 23px;
          left: 0;
          margin-top: -16px;
          position: absolute;
          text-align: center;
          top: 50%;
          width: 23px;
          transition: 0.3s;
          backface-visibility: hidden;
        }
      }
    }
  }

  // Bullet list inside sidebar
  // --------------------------
  &-list {
    @extend .wizard-gleam;

    background-color: transparent;
    box-shadow: none;
    position: static;
    text-align: left;

    > ul {
      display: block;

      > li {
        float: none;

        &::after {
          display: none;
        }
      }

      span {
        display: inline-block;
      }
    }
  }

  // Grid
  // ----
  &-sidebar {
    box-sizing: border-box;
    float: left;
    padding: 30px 40px 40px;
    width: $sidebar;

    &-hide-divider {
      position: absolute;
      height: 1px;
      background: #0975da;
      left: 10px;
      right: 10px;
      top: -13px;
    }

    &-hide-container {
      min-height: 35px;
      width: 260px;
      position: absolute;
      bottom: 22px;
      left: 0;
    }

    h1 {
      color: #fff;
      font-family: $gotham-bold;
      font-size: 22px;
      line-height: 1.231;
      margin-bottom: 20px;
      text-transform: uppercase;
    }
  }

  &-content,
  &-extra {
    background-color: #fff;
    box-sizing: border-box;
    overflow: auto;

    h1,
    h2 {
      color: $base;
      font-family: $gotham-bold;
      font-size: 32px;
      margin-bottom: 10px;

      a {
        &:hover {
          color: $firm;
        }
      }
    }

    h2 {
      font-size: 24px;
      margin-bottom: 0;

      a {
        font-size: 14px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
      }
    }

    p {
      font-size: 16px;
    }

    a {
      font-family: $gotham-medium;
      color: $link-color;

      &:hover,
      &:focus {
        color: $firm;
        text-decoration: none;
      }
    }
  }

  &-content {
    max-height: 500px;
    margin-left: $sidebar;
    margin-right: $extra;
    min-height: 350px;
    padding: 30px 40px;
  }

  // Todos listing
  // -------------
  &-todo {
    list-style: none;
    margin-bottom: 20px;
    margin-left: 0;

    &.is-last {
      margin-bottom: 0;
    }

    li {
      position: relative;

      &:hover,
      &.is-hover {
        &,
        &::before {
          background-color: #fafafa;
        }
      }

      &::before {
        content: "";
        display: block;
        height: 100%;
        left: -10px;
        position: absolute;
        right: -40px;
      }
    }

    &-name {
      border-bottom: 1px solid #ddd;
      padding: 10px 110px 10px 50px;
      position: relative;
      z-index: 2;
    }

    &-status {
      display: block;
      position: absolute;
      margin-top: -10px;
      left: 0;
      top: 50%;
      z-index: 2;

      &.is-positive {
        height: 16px;
        left: -1px;
        stroke: #3c913c;
        stroke-width: 4px;
      }

      &.is-negative {
        height: 20px;
        stroke: #df4343;
        stroke-width: 4px;
      }

      &.is-warning {
        left: 4px;
        margin-top: -11px;
      }
    }

    &-action {
      font-family: $gotham-medium;
      height: 20px;
      margin-top: -10px;
      position: absolute;
      right: 0;
      top: 50%;
      z-index: 2;

      &.is-inactive {
        color: #ccc;
      }
    }
  }

  // Extra sidebar
  // -------------
  &-extra {
    background-color: #fafafa;
    height: 100%;
    padding: 30px;
    right: 0;
    position: absolute;
    top: 0;
    width: $extra;

    ol {
      margin-left: 0;
    }
  }

  // Expand button
  // -------------
  &-expander {
    border: 1px solid rgba(#fff, 0.3);
    border-radius: 4px;
    box-sizing: border-box;
    cursor: pointer;
    height: 32px;
    padding: 5px;
    position: absolute;
    right: 10px;
    text-align: center;
    top: 10px;
    width: 32px;
    transition: 0.3s;
    backface-visibility: hidden;

    &:hover {
      border-color: #fff;
    }

    &.is-active {
      background-color: #f9f0cd;
      border-color: #c8bd8e;
      box-shadow: inset 0 2px 2px rgba(#6e5c13, 0.18);

      svg {
        fill: #958959;
        transition: 0.3s;
        backface-visibility: hidden;
      }
    }

    svg {
      fill: #fff;
      height: 16px;
    }
  }

  // Overlay
  // -------
  &-overlay {
    background-color: rgba($link-color, 0.7);
    border-radius: 4px;
    box-shadow: inset 0 0 10px rgba(#000, 0.2), 0 0 0 30000px rgba(#000, 0.7);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 97;
    transition: 0.3s;
    backface-visibility: hidden;
    transform: scale(1.2, 1.2);

    &.is-inactive {
      background-color: transparent;
      transform: scale(1 1);
    }

    &.is-hidden {
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
    }
  }

  // Tooltip
  // -------
  &-tooltip {
    background-color: #008aff;
    border-radius: 4px;
    color: #fff;
    font-family: $gotham-book;
    font-size: 16px;
    left: 100%;
    letter-spacing: 0;
    line-height: 1.3;
    margin: 0 0 0 10px;
    padding: 15px;
    position: absolute;
    text-align: left;
    text-transform: none;
    top: 0;
    width: 240px;
    z-index: 99999;
    transition: 0.3s;
    backface-visibility: hidden;

    &::before {
      border-style: solid;
      border-width: 10px 10px 10px 0;
      border-color: transparent #008aff transparent transparent;
      content: "";
      height: 0;
      left: -10px;
      position: absolute;
      top: 13px;
      width: 0;
    }

    &-action {
      border: 1px solid rgba(#fff, 0.3);
      border-radius: 3px;
      cursor: pointer;
      display: inline-block;
      font-family: $gotham-medium;
      font-size: 14px;
      margin-top: 7px;
      padding: 5px 10px;
      transition: 0.3s;
      backface-visibility: hidden;

      &:hover {
        border-color: #fff;
      }
    }
  }
}
