// Range input slider
// ------------------
$self: ".base-range";
$slider-width-number: 120;
$slider-width: #{$slider-width-number}px;
$slider-height: 8px;
$background-slider: #c7c7c7;
$background-filled-slider: #70c880;
$thumb-width: 20px;
$thumb-height: 20px;
$thumb-radius: 40px;
$thumb-background: #fdfdfd;
$thumb-shadow: inset 0 0 0 1px rgba(#000, 0.15),
  inset 0 2px 2px rgba(#000, 0.12);
$thumb-border: 1px solid #ccc;
$shadow-size: -6px;
$fit-thumb-in-slider: -6px;

@function makelongshadow($color, $size) {
  $val: -5px 0 0 $size $color, -5px -1px 0 -7px rgba(#000, 1);

  @for $i from 6 through $slider-width-number {
    $val: #{$val}, -#{$i}px 0 0 $size #{$color},
      -#{$i}px -1px 0 -7px rgba(#000, 1);
  }

  @return $val;
}

#{$self} {
  align-items: center;
  appearance: none;
  background: none;
  cursor: pointer;
  display: flex;
  height: 100%;
  min-height: $thumb-height;
  overflow: hidden;
  position: relative;
  width: $slider-width;

  &.is-disabled {
    filter: grayscale(100%);
    opacity: 0.3;
    pointer-events: none;
  }

  &::after {
    border-radius: $thumb-radius;
    box-shadow: -4px 0 0 2px #fff;
    content: "";
    display: block;
    height: $slider-height;
    left: 0;
    position: absolute;
    width: $slider-height;
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    background: $background-slider;
    border-radius: $thumb-radius;
    box-shadow: $thumb-shadow;
    content: "";
    height: $slider-height;
    pointer-events: none;
  }

  &::-webkit-slider-thumb {
    appearance: none;
    background: $thumb-background;
    border: $thumb-border;
    border-radius: $thumb-radius;
    box-shadow: makelongshadow($background-filled-slider, $shadow-size);
    height: $thumb-height;
    margin-top: $fit-thumb-in-slider;
    width: $thumb-width;
  }

  // Firefox
  // -------
  &::-moz-focus-outer {
    border: 0;
  }

  &::-moz-range-track {
    background: $thumb-background;
    border-radius: $thumb-radius;
    box-shadow: $thumb-shadow;
    height: $slider-height;
    width: $slider-width;
  }

  &::-moz-range-thumb {
    background: $thumb-background;
    border: $thumb-border;
    border-radius: $thumb-radius;
    height: $thumb-height - 2;
    position: relative;
    width: $thumb-width - 2;
  }

  &::-moz-range-progress {
    background: $background-filled-slider;
    border: 0;
    border-radius: $thumb-radius;
    box-shadow: $thumb-shadow;
    height: $slider-height;
    margin-top: 0;
  }

  // IE
  // --
  &::-ms-track {
    background: transparent;
    border: 0;
    border-color: transparent;
    border-radius: $thumb-radius;
    border-width: 0;
    box-shadow: $thumb-shadow;
    color: transparent;
    height: $slider-height;
    margin-top: 10px;
    width: $slider-width;
  }

  &::-ms-thumb {
    background: $thumb-background;
    border: $thumb-border;
    border-radius: $thumb-radius;
    height: $thumb-height;
    width: $thumb-width;
  }

  &::-ms-fill-lower {
    background: $background-filled-slider;
    border-radius: 0;
    box-shadow: $thumb-shadow;
  }

  &::-ms-fill-upper {
    background: $background-slider;
    border-radius: 0;
    box-shadow: $thumb-shadow;
  }

  &::-ms-tooltip {
    display: none;
  }
}
