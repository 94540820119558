@import "stylesheets/application_v2/config";

.modal {
  &-existing-row {
    border-top: 1px solid #e5e5e5;
    display: flex;
    font-family: $gotham-medium;
    justify-content: space-between;
    margin: 0 20px;
    padding: 20px 10px;

    &:first-child {
      border: none;
    }

    &s {
      &.is-table {
        margin-top: -20px;
      }

      &.is-body {
        background:
          linear-gradient(#fff 30%, rgba(#fff, 0)),
          linear-gradient(rgba(#fff, 0), #fff 70%) 0 100%,
          radial-gradient(
            farthest-side at 50% 0,
            rgba(#000, 0.2),
            rgba(#000, 0)
          ),
          radial-gradient(
            farthest-side at 50% 100%,
            rgba(#000, 0.2),
            rgba(#000, 0)
          )
          0 100%;
        background-repeat: no-repeat;
        background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
        background-attachment: local, local, scroll, scroll;
        max-height: 245px;
        overflow: auto;
      }
    }

    &.is-header {
      align-items: flex-end;
      border-bottom: 1px solid #cecece;
      border-top: none;
      color: #999;
      justify-content: flex-start;
      padding: 0 10px 15px;

      & > div {
        margin-right: 36px;
      }
    }

    &-center-box {
      width: 95px;
    }

    &-name {
      position: relative;
      top: 1px;
    }

    p {
      line-height: 14px;
      margin: 3px 0 5px;
    }

    &-date {
      font-size: 14px;

      &-ago {
        color: #999;
        font-size: 12px;
      }
    }

    .base-btn {
      align-self: baseline;
    }

    &-left-box {
      display: flex;
      flex-direction: column;
      width: 270px;

      a {
        color: $link-color;
        font-size: 16px;
        line-height: 23px;
        text-decoration: none;

        &:hover {
          color: #ff4f00;
        }
      }

      p {
        color: #a495ab;
        font: 12px monospace;
        margin-bottom: 0;
        word-break: break-all;

        &:first-child {
          margin-bottom: -5px;
          position: relative;
          top: -6px;
        }

        &:last-child {
          margin-top: 4px;
          line-height: 16px;
        }
      }
    }
  }
}
