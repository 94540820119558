.sharing-process {
  text-align: center;
  font-family: $gotham-book;
  font-weight: normal;
  color: #292e33;
  font-size: 14px;
  line-height: 1;
  background:
    url("~application_v2/sharing_process/sharing-process.png")
    no-repeat top center;
  width: 302px;
  height: 120px;
  box-sizing: border-box;

  @include clearfix;

  &-left {
    float: left;
    width: 112px;
    padding-top: 110px;
  }

  &-middle {
    float: left;
    width: 91px;
    padding-top: 54px;
  }

  &-right {
    padding-top: 110px;
    float: right;
    width: 99px;
  }
}

@media all and (min-resolution: 2dppx), all and (min-resolution: 144dpi) {
  .sharing-process {
    background:
      url("~application_v2/sharing_process/sharing-process-2x.png")
      no-repeat top center;
    background-size: 302px 99px;
  }
}
