// Animate Mixin Plugin
// http://thecssguru.freeiz.com/animate/

@mixin animate(
  $name: fadeIn,
  $duration: 1s,
  $delay: 0.2s,
  $function: ease,
  $mode: both
) {
  animation: $name $duration $delay $function $mode;
}

//KeyFrame Defaults (pre-made don't edit)
@keyframes tada {
  0% {
    transform: scale(1);
  }

  10% {
    transform: scale(0.9) rotate(-3deg);
  }

  20% {
    transform: scale(0.9) rotate(-3deg);
  }

  30% {
    transform: scale(1.1) rotate(3deg);
  }

  40% {
    transform: scale(1.1) rotate(-3deg);
  }

  50% {
    transform: scale(1.1) rotate(3deg);
  }

  60% {
    transform: scale(1.1) rotate(-3deg);
  }

  70% {
    transform: scale(1.1) rotate(3deg);
  }

  80% {
    transform: scale(1.1) rotate(-3deg);
  }

  90% {
    transform: scale(1.1) rotate(3deg);
  }

  100% {
    transform: scale(1) rotate(0);
  }
}

@keyframes tada {
  0% {
    transform: scale(1);
  }

  10% {
    transform: scale(0.9) rotate(-3deg);
  }

  20% {
    transform: scale(0.9) rotate(-3deg);
  }

  30% {
    transform: scale(1.1) rotate(3deg);
  }

  40% {
    transform: scale(1.1) rotate(-3deg);
  }

  50% {
    transform: scale(1.1) rotate(3deg);
  }

  60% {
    transform: scale(1.1) rotate(-3deg);
  }

  70% {
    transform: scale(1.1) rotate(3deg);
  }

  80% {
    transform: scale(1.1) rotate(-3deg);
  }

  90% {
    transform: scale(1.1) rotate(3deg);
  }

  100% {
    transform: scale(1) rotate(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
