/*
* Shared
*/
.highcharts {
  height: 270px;
  position: relative;

  &:hover {
    z-index: 500;
  }

  &-container {
    overflow: visible !important;

    svg {
      overflow: visible;
    }

    .highcharts-tooltip[transform="translate(0,-9999)"] {
      display: none;
    }
  }
}

.enum_filter,
.checkbox_filter {
  input[type="checkbox"] {
    float: left;
    margin: 4px 5px 2px 0;
  }
}

/*
* Index
*/
.reports {
  min-height: 280px;

  table {
    // font-size: 17px;
    // td {padding: 10px 0px 10px 0px;}
    .name {
      width: 200px;
    }
  }

  .reports-index {
    .first {
      width: 25%;
    }

    .second {
      width: 75%;
    }

    .center {
      text-align: center;
    }

    td {
      vertical-align: middle;
      height: 18px;

      &:first-child {
        padding: 0;

        .report-link {
          span {
            padding-left: 8px;
            padding-right: 8px;
          }
        }
      }
    }

    .report-link {
      font-weight: bold;
      display: table;
      width: 100%;
      height: 100%;

      span {
        display: table-cell;
        height: 100%;
        width: 100px;
        vertical-align: middle;
      }
    }

    p {
      margin: 0;
    }
  }
}

/*
* All
*/
.report {
  font-size: 1.1em;
  color: #333;

  h2 {
    color: #f75d09;
    font-size: 2em;
    margin: 0.5em 0;
  }

  h3 {
    color: lighten(#f75d09, 20%);
    font-size: 1.3em;
    margin: 0 0 0.5em;

    &.is-account-name {
      line-height: 1.4;
      margin-bottom: -5px;
    }
  }

  dl {
    dt {
      color: #aeadad;
      font-weight: normal;
      font-size: 0.85em;
      padding-bottom: 0.3em;
      text-align: left;
    }

    dd {
      padding-bottom: 0.3em;

      &::after {
        clear: both;
        content: " ";
        display: block;
      }

      em {
        font-size: 0.85em;
        color: lighten(#333, 20%);
      }
    }
  }

  table {
    tr {
      td {
        font-size: 0.9em;

        &.datagrid-coupon,
        &.datagrid-description {
          word-break: break-all;

          .no-coupons + .tooltip {
            word-break: break-word;
          }
        }

        em {
          color: lighten(#333, 20%);
        }

        hr {
          margin: 0.2em 0;
          padding: 0;
        }
      }
    }
  }

  /* Activity */
  .feed {
    font-size: 0.9em;
  }

  .flex-grid {
    .foldable-content {
      display: flex;
      justify-content: space-between;
    }

    .fieldset-wrapper {
      display: flex;
      width: calc(50% - 8px);
      flex-wrap: wrap;
      align-items: flex-start;
      height: fit-content;
    }

    .filter-fieldset {
      width: 100%;
    }
  }

  .filter-fieldset {
    border: 1px solid #ccc;
    width: 480px;
    border-radius: 5px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 10px;
    box-sizing: border-box;

    label {
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;
    }

    legend {
      margin: 0 0 5px 10px;
      padding: 0 3px;
      font-size: 14px;
      line-height: 1;
      border-bottom: none;
      width: auto;
      font-family: "gotham-htf-bold", arial, sans-serif;
    }
  }

  .fraud-detection {
    &-title {
      color: #fa9e6c;
      cursor: pointer;
      font-size: 1.3em;
      border-bottom: 1px dashed #fa9e6c;

      &:hover {
        color: #fa9e6c;
        border-bottom: none;
        text-decoration: none;
      }
    }

    &-container {
      width: 450px;

      &-list {
        margin-top: none;
        background-color: #fff;

        dt {
          width: 320px;
          color: #000;
        }

        .disabled {
          color: #aeadad;
        }
      }
    }
  }

  .filter-row-split {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    align-items: end;

    [class*="span"] {
      margin-left: 0;
    }

    &.is-flex-end {
      align-items: flex-end;
    }
  }

  .no-reports-title {
    color: #333;
    font-family: $gotham-medium;
    font-size: 16px;
  }
}

/*
* Email Performance
*/
.email-performance-report {
  .highcharts {
    height: 400px;
  }
}

/*
* Campaign Performance
*/
.campaigns-performance-report {
  .table {
    thead {
      th {
        text-align: center;
        vertical-align: top;
      }
    }
  }
}

/*
* Over Time
*/
.over-time-report {
  select {
    margin-right: 10px;
  }

  .inline {
    display: inline-block;
    vertical-align: baseline;
  }

  .highcharts {
    height: 500px;
  }

  .campaign-activity-filters {
    .collapsed {
      display: none;
    }

    &.disabled {
      .collapsed {
        display: block;
      }

      .expanded {
        display: none;
      }
    }
  }
}

/*
* Weekly stats
*/
.weekly-stats-report {
  h1,
  h2 {
    color: #16161d;
    font-weight: bold;
  }

  table.weekly_stats {
    width: 960px;

    tr:first-child {
      td {
        div {
          position: relative;
          font-size: 24px;
          line-height: 24px;
          padding: 5px;
          width: 450px;
          left: -225px;
          margin: 0 50%;
          background: #d9ead2;
          text-align: center;

          span {
            &.number {
              display: block;
              font-size: 40px;
              line-height: 40px;
            }

            &.small {
              font-size: 20px;
            }
          }
        }
      }

      &.two {
        td {
          width: 480px;

          div {
            width: 400px;
            left: -200px;
          }
        }
      }

      &.three {
        td {
          width: 320px;

          div {
            width: 240px;
            left: -120px;
          }
        }
      }
    }
  }

  table#other_weekly_stats {
    width: 880px;
    border: 1px solid silver;
    margin-left: 50px;

    tr {
      th,
      td {
        text-align: right;
        font-weight: normal;
        padding: 3px 10px;
      }

      &:nth-child(odd) td {
        background: whitesmoke;
      }

      th {
        line-height: 30px;
        font-size: 14px;
        background: #e6e6e6;
      }

      td {
        font-size: 24px;
        width: 115px;

        &:first-child {
          font-size: 20px;
          width: 420px;
          padding: 0 0 0 10px;
          text-align: left;
        }
      }
    }
  }
}

.group-of-metrics {
  margin-left: 10px;

  label {
    margin-left: 10px;
  }
}

/*
* Split TestS Report
*/
.split-tests-table {
  .datagrid-actions {
    width: 70px;
  }

  .datagrid-progress {
    width: 120px;
  }

  .datagrid-last-impression-at {
    width: 120px;

    a {
      display: block;
    }
  }

  .datagrid-status {
    width: 55px;
  }
}

/*
* Metrics Aggregation
*/
.metrics-aggregation-table {
  .datagrid-actions {
    width: 90px;
  }

  .metrics-segmentation-menu {
    display: inline-block;

    .btn-small {
      margin: -3px 0;
    }

    .dropdown-submenu {
      > a {
        &::after {
          border-left-color: transperent;
          border-right-color: #ccc;
          border-width: 5px 5px 5px 0;
          margin-right: auto;
          margin-left: -10px;
        }
      }

      .dropdown-menu {
        right: 100%;
        left: auto;
        border-radius: 3px;
      }
    }
  }
}

/*
* Request Accesses
*/
.request-accesses-table {
  margin-bottom: 0;

  .datagrid-created-at {
    width: 12%;
  }

  .datagrid-humanized-requested-period {
    width: 10%;
  }

  .datagrid-granted-period {
    width: 15%;
  }
}

.accesses-table {
  .datagrid-pii,
  .datagrid-expires-at,
  .datagrid-status {
    width: 15%;
  }

  .datagrid-owner-actions,
  .datagrid-manager-actions {
    width: 22%;
  }
}

.text-wrap {
  &.is-break-word {
    word-break: break-word;
  }
}

.dropdown-menu.is-right-clear {
  margin-top: 6px;
  right: 0;
  left: unset;
}

.inline-flex.a-margin-left {
  display: inline-flex;

  a {
    margin-left: 4px;
  }
}
